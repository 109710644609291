.c-tyb-container {
  /* font-family: "Helvetica Neue"; */
  width: 806px;
  max-width: 100%;
  margin: 0 auto;
}
.tyb-header {
  display: flex;
  justify-content: center;
  font-size: 27px;
  font-weight: bold;
  padding-bottom: 42px;
}
.tyb-social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 96px;
}
.tyb-button {
  height: 106px;
  width: 173px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.typ-facebook {
  background-color: #3e5a9a;
}
.typ-twitter {
  background-color: #36b7ea;
}
.typ-whatsapp {
  background-color: #74bf59;
}
.typ-linkedin {
  background-color: #0f78b4;
}
.tyb-social-txt {
  padding-left: 14px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.1px;
}
.typ-memory-box {
  width: 806px;
  border: 2px solid #d7d7d7;
  background-color: #ffffff;
  box-shadow: 0 0 13px 1px rgba(53, 56, 54, 0.07);
  margin-bottom: 90px;
  padding: 30px;
}
.mb-txt {
  /* font-family: Graphik; */
  font-size: 20.83px;
  line-height: 33.33px;
  margin-left: 34px;
}
.mb-header1 {
  font-size: 18px;
  font-weight: 300;
  line-height: 33.33px;
  text-align: center;
}
.mb-header2 {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 30px;
}

@media only screen and (max-width: 560px) {
  .c-tyb-container {
  padding: 0 18px;
  display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tyb-header {
    font-size: 11px;
    padding-bottom: 18px;
  }
  .tyb-social-links {
    margin-bottom: 16px;
    width: 200px;
    flex-wrap: wrap;
  }
  .tyb-button {
    height: 54px;
    width: 88px;
    margin-bottom: 10px;
  }
  .tyb-icon{
    height: 16px;
    width: 16px;
  }
  .tyb-social-txt {
    padding-left: 6px;
    font-size: 9px;
    line-height: 11px;
  }
  .typ-memory-box {
    width: 100%;
    margin-bottom: 46px;
    padding: 18px;
  }
  .mb-header1 {
    font-size: 10px;
    line-height: 14px;
  }
  .mb-header2 {
    font-size: 10px;
    line-height: 14px;
    padding-bottom: 0;
  }
  .typ-mobile-img{
    padding-top: 18px;
    padding-bottom: 12px;
  }
  .mb-txt {
    /* font-family: Graphik; */
    font-size: 10px;
    line-height: 18px;
    margin-left: 0;
    text-align: center;
  }
}
