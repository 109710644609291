.mobile-menu-container{
  /* position: absolute;
  top: 0; */
  width: 288px;
  max-width: 100%;
  height: 100%;
  background-color: #222222;
  /* box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);
  z-index: 9999999; */
  color: #ffffff;
}

.mobile-menu-close{
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.mobile-menu-logo-div{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255,255,255,0.16);
}

.mobile-menu-logo{
  width: 168px;
}

.mobile-menu-links{
  padding-left: 16px;
  font-size: 16px;
  color: rgba(255,255,255,0.64);
}

.mobile-menu-links li{
  padding-top: 24px;
}

.mobile-menu-links li a{
  color: rgba(255,255,255,0.64);
}