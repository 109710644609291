.temp-shuls-page{
  min-height: calc(100vh - 390px);
  margin: 0 8px;
}

.temp-shuls-header{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 25px;
  color: #484848;
  font-size: 24px;
  font-weight: 500;
}

.temp-shuls-container{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1234px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.temp-shul-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 210px;
  max-width: 380px;
  min-height: 210px;
  min-width: 380px;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: 16px;
  margin: 15px;
}

.temp-shul-name{
  text-transform: capitalize;
  color: #11B6D7;
  font-size: 18px;
  font-weight: 500;
  max-width: 235px;
  display: flex;
  flex-wrap: wrap;
}

.temp-shul-nusach{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 104px;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 14px;
  text-transform: capitalize;
}

.minyan-torah-message{
  color: rgba(0,0,0,0.32);
}

.temp-shul-info{
  /* margin-top: 20px; */
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}

.temp-shul-dates{
  color: #FCAC3A;
  font-size: 16px;
  font-weight: 500;
}

.no-temp-minyanim-message{
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin: 25px;
  text-align: center;
}

@media only screen and (max-width: 560px){
  .temp-shuls-header{
    padding: 24px 0;
    font-size: 20px;
  }
  
  .temp-shuls-container{
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  .temp-shul-card{
    max-height: auto;
    max-width: 100%;
    min-height: 154px;
    min-width: 100%;
    padding: 8px;
    margin: 0 0 16px 0;
  }
  
  .temp-shul-name{
    font-size: 16px;
    font-weight: 500;
    /* max-width: 235px; */
  }
  
  .temp-shul-nusach{
    height: 18px;
    width: 72px;
    font-size: 10px;
  }
  
  .temp-shul-info{
    font-size: 12px;
    line-height: 20px;
  }
  
  .temp-shul-dates{
    font-size: 12px;
  }

}