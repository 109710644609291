.map-window-shul-name{
  color: #11B6D7;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 8px;
}

.map-window-shul-address{
  color: #484848;
  font-size: 12px;
  text-transform: capitalize;
}