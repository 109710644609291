.contact-form-page{
  color: #4B4C4D;
  margin-top: 88px;
}

.contact-form{
  width: 445px;
}

.contact-form-title{
  color: #11B6D7;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.contact-form-input, .contact-form-textarea{
  width: 445px;
  border: 0.72px solid #595A5A;
  background-color: #F2F2F2;
  font-size: 18px;
  text-align: center;
  color: #4B4C4D;
}

.contact-form-input{
  height: 48px;
}

.contact-form-textarea{
  height: 327px;
  padding: 20px;
}

.contact-form-input:focus, .contact-form-textarea:focus {
  outline: none; 
  border: 1px solid #11B6D7;
}

.contact-form-submit{
  height: 43px;
  width: 160px;
  background-color: #11B6D7;
  text-align: center;
  margin-top: 34px;
  font-size: 18px;
  font-weight: bold;
  color: #FCFCFC;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  border: none;
}

.contact-form-submit:focus {outline: none;}

.form-success-messgae{
  margin-top: 83px;
  line-height: 1.5em;
  margin-bottom: 65px;
  font-size: 24px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .contact-form-page{
    margin-top: 50px;
  }
  .contact-form{
    width: 221px;
  }
  .contact-form-title{
    font-size: 25px;
  }
  .contact-form-input, .contact-form-textarea{
    width: 221px;
    font-size: 15px;
  }
  .contact-form-input{
    height: 32px;
  }
  .contact-form-textarea{
    height: 128px;
    padding: 20px;
  }
  .contact-form-submit{
    width: 126px;
    margin-top: 34px;
  }
  .form-success-messgae{
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 15px;
  }
}