.contact-on-multiple-shuls{
    margin-bottom: 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 14px;
    color: #EF6A6E;
}

.contact-on-multiple-shuls-warning-icon{
    font-size: 18px;
    display: flex;
    align-items: center; 
    margin-right: 6px;  
}

.phone-input-div, .email-input-div{
    margin-top: 20px;    
}

#phone-number-input{
    width: 235px;
    margin-right: 15px;
}

#phone-ext-input {
    width: 65px;
    margin-right: 15px;
}

#email-input{
    width: 315px;
    margin-right: 15px;
}

.delete-phone-email-icon{
  color: #DCDCDC; 
  cursor: pointer;
  padding-left: 13px;
}

.delete-phone-email-icon:hover{
  color: #23D7F2
}

.phone-email-add{
    color: #23D7F2;
    padding-left: 148px;
    padding-top: 15px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.08px;
    position: relative;
}

.plus-icon{
    position: absolute;
    bottom: 1.5px;
    left: 135px;
    font-size: 14px;
}

.contact-buttons-div{
    display: flex;
    height: 40px;
    justify-content: flex-end;  
}

.contact-button{
    margin-left: 15px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
    .contact-on-multiple-shuls{
        font-size: 11px;
    } 

    .plus-icon{
        position: absolute;
        bottom: 1.5px;
        left: 0;
        font-size: 14px;
    }
    .phone-email-add{
        padding-left: 12px;
        font-size: 12px;
    }
    .phone-input-div, .email-input-div{
        margin-top: 10px;    
    }
    #phone-number-input{
        width: 100%;
        margin-right: 8px;
    }
    #phone-ext-input {
        width: 55px;
        margin-right: 15px;
    }
    #email-input{
        width: 100%;
        margin-right: 15px;
    }

    .contact-buttons-div{
       margin-bottom: 40px;
    }
    
    
}