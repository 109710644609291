.ssp-container-wrapper {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 24px;
  font-family: "Satoshi", sans-serif;
  font-feature-settings: "ss03", "liga" off;
}
.ssp-container {
  background-image: url(/images/ShulSpaceDesktopBG.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Satoshi", sans-serif;
  font-feature-settings: "ss03", "liga" off;
  width: 100%;
  border-radius: 5px;
  position: relative;
  padding: 0 16px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.ssp-image-container {
  line-height: 0;
}
.ssp-image {
  height: 109px;
  margin-bottom: 15px;
}
.ssp-txt {
  padding: 0px 80px 0px 46px;
}
.ssp-txt-top-row {
  color: #222c64;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.ssp-shulspace-logo-row {
  display: flex;
  align-items: center;
}
.ssp-shulspace-logo {
  margin: 0 4px 0 9px;
}
.ssp-shulspace-word {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  margin-right: 12px;
}
.ssp-discover-txt {
  color: #222c64;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 6px;
}

@media only screen and (min-width: 1201px) {
  .ssp-container-wrapper {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 930px) and (max-width: 1200px) {
  .ssp-container-wrapper {
    padding: 0 16px;
    margin-bottom: 24px;
  }
  .ssp-container {
    margin-top: 24px;
    background-image: url(/images/ShulSpaceTabletBG.png);
    min-height: 156px;
    align-items: flex-end;
    height: 156px;
    border-radius: 5px;
    justify-content: flex-start;
  }
  .ssp-image {
    height: 166px;
    margin-bottom: 0 !important;
    margin-right: 16px;
  }
  .ssp-txt {
    padding: 0px 0px 7px 0;
  }
}

@media only screen and (max-width: 929px) {
  .ssp-container-wrapper {
    padding: 0;
  }
  .ssp-container {
    margin-top: 8px;
    flex-direction: column;
    padding: 0;
    height: unset;
    background-image: none;
  }
  .ssp-image-container {
    width: 100;
    line-height: 0;
    display: flex;
    justify-content: center;
  }
  .ssp-image {
    width: 100%;
    max-width: 450px;
    height: unset;
    margin-bottom: 0;
  }
  .ssp-txt-top-row {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 8px;
  }
  .ssp-txt {
    margin-top: -2px;
    background-image: url(/images/ShulSpaceMobileBG.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 215px;
    padding: 0 26px 0 19px;
    border-radius: 18px;
    background-color: #ffffff;
  }
  .ssp-partners-with {
    padding-bottom: 8px;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
  }
  .ssp-shulspace-logo {
    margin: 0 4px 0 0;
  }
  .ssp-shulspace-word {
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;

    margin-right: 4px;
  }
  .ssp-shulspace-logo-row {
    font-size: 21px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .ssp-discover-txt {
    padding-left: 8px;
  }
}
