/***** DASHBOARD STYLES *****/
.dashboard-outer-wraper{
  margin-left: 80px;
  padding: 40px;
}
.dashboard-content {
  width: calc(100% - 352px);
}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .dashboard-content {width: calc(100% - 224px);}
}

/*** Searchbox Styles ***/
.dashboard-search-types-dropdown{
  height: 40px;
  width: 130px;
  border: 1px solid rgba(28,60,83,0.16);
  border-radius: 4px 0 0 4px;
  background-color: #F5F5F5;
  color: #2A3233;
  font-size: 14.31px;
  letter-spacing: 0.25px;
  border-right: none;
}

.dashboard-search-types-dropdown:focus{
  outline: none;
  border: 1px solid #23D7F2;
}
.dashboard-searchbox{
  height: 40px;
  width: 370px; 
  max-width: 100%;
  border-radius: 0 4px 4px 0;
  border: 1px solid rgba(28,60,83,0.16);
  padding-right: 16px;
}

.small-search-icon{
  color: #23D7F2;
}

/*** Table Styles ***/
.dashboard-table {margin-top: 48px;}
.dashboard-table .table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr .5fr;
  padding: 16px 0;
  border-bottom: solid 1px rgba(42,50,51,0.12);
  grid-column-gap: 16px;
}
.dashboard-table .table-row a {
  margin-right: 24px;
  text-transform: uppercase;
  color: #23D7F2;
  text-decoration: none;
  font-weight: 500;
  transition: opacity 300ms linear;
}
.dashboard-table .table-row a:hover {
  opacity: .5;
}
@media only screen and (max-width: 560px){
  .dashboard-table .table-row {grid-template-columns: 1fr 96px;}
  .dashboard-table .table-row > *:nth-child(2) {display: none;} 
  .dashboard-table .table-row > *:nth-child(1) {order: 1;}
  .dashboard-table .table-row > *:nth-child(3) {order: 3;}
  .dashboard-table .table-row > *:nth-child(4) {order: 2;}
  .dashboard-table .table-row > .bold-text {margin-bottom: 8px;}
}

/*** Pagination Styles ***/
.pagination {display: flex; align-items: center; justify-content: flex-end; margin-top: 32px;}
.pagination li {
    margin: 4px;
    padding: 4px;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 300ms linear, color 300ms linear;
}
.pagination li.active {background: #23D7F2; color: #fff;}
.pagination-arrow i {vertical-align: middle; font-size: 16px;}
.pagination-arrow:first-child {margin-right: 8px; margin-left: 0;}
.pagination-arrow:last-child {margin-left: 8px; margin-right: 0;}

/*** Empty State Styles ***/
.dashboard-empty-state {
  margin-top: 100px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dashboard-empty-state i {color: #e9e9e9; font-size: 80px; margin-bottom: 16px;}
.dashboard-empty-state p {color: rgba(0, 0, 0, .38); font-size: 16px;}

/*** Table Loader Styles ***/
.dashboard-table-loader {height: calc(100vh - 280px); display: flex; justify-content: center; align-items: center;}
.dashboard-table-loader svg {height: 80px;}

/*** Pending Approval Sidebar Styles ***/
.pending-approval-panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  overflow: auto;
  width: 352px;
  border: 1px solid #E9E9E9;
}
.pending-approval-header, .find-a-shul-header {
  background-color: #FAFAFA;
  text-transform: uppercase;
  height: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 8px;
}
.pending-approval-list-item {
  padding: 16px 24px;
  border-bottom: 1px solid #e9e9e9;
}
.pending-approval-actions i:hover {
  transition: opacity 300ms linear;
  cursor: pointer;
  opacity: .5;
}
.pending-approval-open-icon{
  color: #2A3233;
}
.adm-buttons {
  width: 350px;
  display: flex;
  justify-content: space-between;
}
/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){
 
}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .pending-approval-panel {width: 224px;}
}
@media only screen and (max-width: 560px){
  .dashboard-outer-wraper{
    margin-left: 0;
    padding: 0;
  }

  .content header {
    display: flex;
    height: 30px;
    margin: 16px;
    justify-content: space-between;
  }
  .dashboard-content .flex-justify-content {
    display: block;
  }
  .dashboard-content {
    width: 100%;
  }
  .dashboard-left-header {
    width: 160px;
    display: flex;
    justify-content: space-between;
  }
  .dashboard-search-types-dropdown{
    height: 30px;
    width: 65px;
    font-size: 10px;
    letter-spacing: 0.25px;
    margin-left: 16px;
  }
  
  .dashboard-searchbox{
    height: 30px;
    width: 250px;
    padding-right: 10px;
  }
  .dashboard-empty-state {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .pending-approval-panel {
    position: relative;
    min-width: 100%;
  }
  
  .dashboard-table {
    margin: 16px;
  }
  table .table-row {
    grid-template-columns: 2.5fr 1fr 2.5fr .5fr .5fr;
    padding: 8px 0;
    grid-column-gap: 2px;
  }
}