.location-warning-dialog{
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
}

.location-warning-icon{
  font-size: 60px;
  margin-top: 30px;
  margin-bottom: 24px;
  color: #EF6A6E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-warning-title{
  text-align: center;
  margin-bottom: 40px; 
}
.location-warning-title h4{
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
}

.location-warning-shul-names{
  font-size: 15px;
  margin-bottom: 12px;
  line-height: 1.5;
  text-transform: capitalize;
}




@media only screen and (max-width: 560px){
  .location-warning-dialog{
    width: 260px;
    margin-bottom: 26px;
  }
}
