.client-add-shul-page{
    color: #4B4C4D;
}

.client-add-shul-container{
    width: 565px;
}

.client-add-shul-title{
    position: absolute;
    left: -260px;
    font-size: 36px;
    font-weight: bold;
}

.client-input-color{
    color: #898989;
}

.client-shul-info {
	border: 1px solid #898989;
    border-radius: 10px;
    padding: 20px 30px;
    margin-bottom: 32px;
    margin-top: 32px;
}

.client-shul-title{
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 25px;
    text-transform: uppercase;
}

.client-shul-label{
    width: 125px;
    font-size: 18px;
}

.client-add-shul-address-input{
    flex: 1 !important;
    color: #898989 !important;
    font-size: 18px !important;
    width: 100% !important;
}

.custom-checkbox-square{
    position: absolute;
    /* top: 1px; */
    right: 153px;
    height: 12px;
    width: 12px;
    border: 1.5px solid #535353;
    background-color: #E1E1E1;
    cursor: pointer;
  }

  .temp-radio-square{
    position: absolute;
    left: 20px;
    height: 12px;
    width: 12px;
    border: 1.5px solid #535353;
    background-color: #E1E1E1;
    cursor: pointer;
  }

.temp-minyan-checkbox-label{
    position: relative;
}

.temp-minyan-radio-label{
    padding-left: 40px;
    position: relative;
    margin-bottom: 13px;
}

.temp-minyan-checkbox-label input[type=checkbox],
.temp-minyan-radio-label input[type=radio]{
    position: absolute;
    opacity: 0;
}

.temp-minyan-checkbox-label input[type=checkbox]:checked ~ .custom-checkbox-square,
.temp-minyan-radio-label input[type=radio]:checked ~ .temp-radio-square{
    background-color: #11B6D7;
}

.temp-minyan-text{
    line-height: 20px;
}


.client-contact-info{
	border: 1px solid #898989;
    border-radius: 10px;
    padding: 20px 30px;
    margin-bottom:16px;
}

.client-add-shul-submit{
    height: 45px;
    width: 274px;
    background-color: #11B6D7;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 32px;
    cursor: pointer;
    border: none;
}

.client-add-shul-submit:focus {
  outline: none;
}

.client-add-shul-success{
    margin-top: 150px;
}
.client-add-shul-home{
  margin-top: 36px;
  margin-bottom: 136px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){
   
  }
  @media only screen and (min-width: 561px) and (max-width: 1024px){
   
  }
  @media only screen and (max-width: 560px){
    .client-shul-info {
        margin-bottom: 12px;
        margin-top: 0;
    }
    .client-add-shul-title{
        display: flex;
        justify-content: center;
        align-items: center;
        position: inherit;
        left: 0;
        top: 0;
        font-size: 20px;
        padding: 20px;
    }
    .client-shul-label{
        width: 96px;
        
    }
    .mobile-client-input{
        height: 20px !important;
        font-size: 10px !important;
        padding: 0 10px !important;
        margin: 0 !important;
    }
    .client-add-shul-address-input{
        min-height: 20px !important;
        max-height: 20px !important;
        font-size: 10px !important;
        padding: 0 10px !important;
        margin: 0 !important;
    }
    .temp-minyan-checkbox-label{
        padding-right: 0;
    }
    .custom-checkbox-square{
        position: absolute;
        top: -1px;
        right: 107px;
        height: 9px;
        width: 9px;
        border: 1.5px solid #707070;
      }
      .temp-minyan-text{
        padding-right: 0; 
        line-height: 20px;
    }
    .client-add-shul-submit{
        height: 29px;
        width: 169px;
        font-size: 12px;
        margin-bottom: 16px;
    }
    .client-add-shul-success{
        margin-top: 60px;
    }
    .client-add-shul-home{
      margin-top: 36px;
      margin-bottom: 60px;
    }
  
  }

  
  