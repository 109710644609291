.home-container{
  background-image: url(/images/homePageBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 314px);
  font-size: 16px;
  color: #121013; 
  padding: 0 8px;
}

.home-headers{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 120px 0 120px;
}

.home-header-logo{
  width: 130px;
}

.home-add-minyan{
  height: 40px;
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #11B6D7;
  border-radius: 24px;
  color: #11B6D7;
  font-size: 14px;
  font-weight: 500;
}

.home-title{
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 500;
  margin-top: 84px;
}

.home-next-minyan{
  height: 48px;
  width: 250px;
  border-radius: 28px;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 64px;
  cursor: pointer;
}

.home-search-minyanim{
  color: #FFFFFF;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
  letter-spacing: 1px;
}

.home-search-bar{
  margin-bottom: 158px;
}

.home-mode-toggle{
  height: 56px;
  width: 205px;
  /* border: 1px solid rgba(0,0,0,0.04);
  border-right: none; */
  background-color:  #FFFFFF;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.home-mode-toggle-line{
  height: 40px;
  border-right: solid 1px #E4E4E4;
  margin: 6px;
}

.home-mode-toggle-label{
  color: rgba(0,0,0,0.6);
  font-size: 12px;
}

.home-mode-toggle-label-active{
  color: #11B6D7;
}

.home-input-box{
  height: 56px;
  width: 463px;
  background-color:  #FFFFFF;
}

.home-input-div{
  height: 56px;
  width: 463px;
  background-color:  #FFFFFF;
  position: relative;
}

.use-location-icon{
  position: absolute;
  right: 11px;
  top: 19px;
  color: rgba(0,0,0,0.6);
  font-size: 20px;
}

.home-input{
  border: none transparent;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  /* position: relative; */
}

.home-search-button{
  height: 56px;
  width: 96px;
  border-radius: 0 3px 3px 0;
  background-color: #11B6D7;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

.shul-auto-complete-container{
  position: absolute;
  background-color: #FFFFFF;
  z-index: 20;
  width: 764px;
  left: -205px;
  /* margin-top: .65px; */
  padding-left: 0px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
  border-top: .8px solid  #E4E4E4;
}

.shul-auto-complete-row{
  text-transform: capitalize;
  /* color:  #A3A3A3; */
  cursor: pointer;
  padding: 6px 5px;
  line-height: 1.3em;
}

.shul-auto-complete-row:hover{
  color: #ffffff;
  text-decoration: none;
  background-color: #11B6D7;
}

.geo-dialog{
  display: flex;
  justify-content: center;
  color: #484848;
  width: 600px;
}

.geo-error-div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
}

.geo-message{
  color: #484848;
  font-size: 14px;
  letter-spacing: 0.11px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3em;

}

.geo-warning-header{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 24px;
}

.geo-dialog-ok-button{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  border-radius: 24px;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 14.31px;
  letter-spacing: 1.25px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  margin: 32px;
}




/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .home-search-title{
    font-size: 42px;
  }
}
@media only screen and (max-width: 560px){
  .home-container{
    background-image: url(/images/homePageMobileBackground.jpg);
  }

  .home-headers{
    justify-content: center;
    padding: 16px 0 0 0;
  }

  .mobile-home-menu-icon{
    position: absolute;
    font-size: 28px;
    color: #ffffff;
    left: 16px;
    top: 16px;
  }

  .home-title{
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    margin-top: 58px;
  }
  
  .home-next-minyan{
    height: 40px;
    width: 200px;
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 40px;
  }
  
  .home-search-minyanim{
    letter-spacing: 0.75px;
    font-size: 12px;
    margin-bottom: 24px;
  }

  .mobile-home-mode-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .home-mode-toggle-label{
    color: rgba(255,255,255,0.6);
    font-weight: 500;
  }

  .home-mode-toggle-label-active{
    color: #11B6D7;
  }

  .home-search-bar{
    width: 100%;
    max-width: 100%;
    margin-bottom: 98px;
  }

  .home-input-box{
    height: 40px;
    width: 100%;
    max-width: 100%;
    /* padding-left: 5px; */
    border-radius: 3px;
  }
  
  .home-input-div{
    height: 40px;
    width: 100%;
    max-width: 100%;
    border-radius: 3px;
  }

  .home-input{
    padding-left: 5px;
  }

  .home-mobile-search{
    position: absolute;
    z-index: 1000;
    height: 40px;
    width: 40px;
    border-radius: 0 3px 3px 0;
    right: 0;
    /* border: 1px solid rgba(0,0,0,0.04); */
    background-color: #11B6D7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-mobile-search-icon{
    color: #ffffff;
  }

  .use-location-icon {
    right: 46px;
    top: 11px;
  }

  .shul-auto-complete-container{
    width: 100%;
    left: 0;
    padding-left: 0px;
  }
  
  .shul-auto-complete-row{
    font-size: 8px;
    padding: 7px 5px;
  }

  .geo-dialog{
    display: flex;
    justify-content: center;
    color: #484848;
    width: 100%;
    text-align: center;
  }
  
  .geo-error-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    text-align: center;
    padding: 10px;
  }
  
  .geo-message{
    font-size: 12px;
    text-align: center;
  }
  
  .geo-warning-header{
    font-size: 15px;
    text-align: center;
  }
  
  .geo-dialog-ok-button{
    height: 35px;
    width: 120px;
  }
}