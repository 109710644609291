/***** LAYOUT STYLES *****/
/*** SIDE BAR STYLES ***/
aside {
  min-height: 100vh;
  height: 100vh;
  width: 80px;
  background-color: #2A3233;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: #fff;
  padding: 32px 16px;
  text-align: center;
  width: 250px;
  box-shadow: 6px 0 12px 0 rgba(0,0,0,0.24);
  z-index: 10;
  transition: width 300ms linear, box-shadow 300ms linear;
}
/* Closed Sidebar Styles */
aside.closed {width: 80px; box-shadow: none;}

/* Sidebar - Nav Styles */
nav {display: inline-block;}
nav ul li a {
  margin-top: 48px;
  display: flex;
  align-items: center;
  transition: color 300ms linear;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
}
nav ul li:hover {color: #23D7F2;}
nav ul li:hover a {color: #23D7F2;}
nav ul li p {margin-left: 24px; white-space: nowrap;}
/* Closed Sidebar Nav Styles */
aside.closed nav ul li a{justify-content: center;}
aside.closed nav ul li p {display: none;}
/* Active Nav Link Styles */
nav ul li a.active {color: #23D7F2 !important}

.aside-logo-div{
  display: flex;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  letter-spacing: 0.65px;
  cursor: pointer;
}

/*** CONTENT STYLES ***/
/* .content {margin-left: 80px; padding: 40px;} */

/*** HEADER STYLES ***/
header.header-fixed {
  width: calc(100% - 80px);
  height: 80px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  background: #fff;
}
header h1 {font-size: 24px;}
.header-fixed-page {margin-top: 40px;}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .content {
    margin: 0px;
    padding: 0px;
  }
  header.header-fixed {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 24px;
    box-shadow: 0 0 12px -7px rgba(0,0,0,0.12), 0 0 12px -2px rgba(0,0,0,0.12);
  }
  .header-fixed-page {
    margin-top: 0px;
  }
}