.home-page-places-container{
  width: 100%;
  position: relative;
}

.search-page-places-container{
  width: 100%;
  height: 100%;
  position: relative;
}


.search-page-location-input{
  text-transform: capitalize;
}

.google-places-input{
  max-width: 100%;
}

.autocomplete-container{
  width: 100%;
  max-width: 100%;
  /* position: absolute; */
}

.home-input{
  border-radius: 3px;
  width: 100%;
  max-width: 100%;
}

.home-page-places-autocomplete-container{
  position: absolute !important;
  top: 56px;
  background-color: #FFFFFF !important;
  z-index: 20 !important;
  width: 764px !important;
  max-width: 764px !important;
  left: -205px !important;
  margin-top: 1px !important;
  padding-left: 0px !important;
  line-height: 1.8em !important;
}

.front-layout-places-autocomplete-container{
  position: absolute !important;
  top: 49px;
  background-color: #FFFFFF !important;
  z-index: 20 !important;
  width: 588px !important;
  max-width: 662px !important;
  left: -205px !important;
  margin-top: 1px !important;
  padding-left: 0px !important;
  line-height: 1.8em !important;
}

.home-ac-active:hover, .home-ac-active:active{
  color: #ffffff !important;
  text-decoration: none !important;
  background-color: #11B6D7 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  background-image: none !important;
}

.search-autocomplete-container{
 width: 100%;
 position: absolute;
 top: 50px;
 z-index: 999999;
}

@media only screen and (max-width: 560px){

  .home-page-places-autocomplete-container{
    width: 100% !important;
    top: 40px !important;
    left: 0 !important;
    margin-top: 1px !important;
    padding-left: 0px !important;
    line-height: 1.3em !important;
  }

  .autocomplete-container{
    font-size: 10px;
  }

  .search-autocomplete-container{
    width: 100%;
    position: absolute;
    top: 42px;
    z-index: 999999;
   }
}