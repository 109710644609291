.sponsor-close{
  color: #2A3233;
}

.sponsor-mode-toggle{
  font-size: 70px;
  cursor: pointer;
  color: gray;
}

.sponsor-mode-toggle-label{
  font-size: 16px;
}

.sponsor-mode-toggle-label-active{
  color: #23D7F2;
}

.sponsors-table-header{
  min-height: 53px;
  display: grid;
  grid-column-gap: 10px;
  border-bottom: 1px solid rgba(42,50,51,0.12);
  border-top: 1px solid rgba(42,50,51,0.12);
}

.daily-header, .daily-row{
  grid-template-columns: .3fr .2fr .8fr 60px;
}

.weekly-header, .weekly-row{
  grid-template-columns: .3fr .2fr .2fr .8fr 60px;
}

.sponsors-table-row{
  min-height: 53px;
  display: grid;
  grid-column-gap: 10px;
  border-bottom: 1px solid rgba(42,50,51,0.12);
}

.sponsor-icons{
  font-size: 18px;
  color: #2A3233;
}

.back-to-add-sponsor{
  width: 200px !important;
}

