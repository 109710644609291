/***** ADD SHUL STYLES *****/
/* a {color: #fff;} */
.add-edit-shul-outer-wraper {
  margin-left: 80px;
  padding: 40px;
}

.add-shul {
  padding-top: 24px;
}
.material-icons a {
  color: #000;
}
/*** Shul Info Styles ***/
.edit-shul-name-header {
  text-transform: capitalize;
}
.temp-minyan-box {
  height: 16px;
  width: 64.39px;
  color: #2a3233;
  font-size: 10px;
  background-color: #eeeeee;
  margin: 0 10px;
  flex-shrink: 0;
}
.shul-info-card .flex-align-center {
  margin-bottom: 16px;
}
.shul-info-card > .flex-align-center > *:first-child {
  width: 112px;
}
.shul-info-card .shul-update-message {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 352px;
  max-width: 100%;
}
.shul-info-card .success-message {
  background-color: rgba(23, 205, 144, 0.12);
  color: #17cd90;
}
.shul-info-card .error-message {
  background-color: red;
  color: white;
}
.custom-input {
  width: 352px;
  max-width: 100%;
}

.temp-shul-dates-div {
  flex-wrap: wrap;
}

.edit-shul-date-picker {
  min-width: 132px !important;
  max-width: 132px !important;
  display: flex;
}

.edit-shul-start-date-div {
  padding-right: 25px;
}

.edit-shul-start-date {
  width: 112px !important;
}

.edit-shul-end-date {
  padding-right: 5px;
}

.has-minyan-checkbox {
  color: #2a3233;
  font-size: 16px;
  padding-right: 4px;
}

.delete-shul-div {
  color: #ef6a6e;
  width: 104px;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.96px;
  margin-top: 24px;
}

.delete-shul-icon {
  padding-right: 4px;
  font-size: 14px;
  color: #ef6a6e;
  cursor: pointer;
}

/*** Table Styles ***/
.table-row {
  display: grid;
  grid-column-gap: 16px;
  border-bottom: 1px solid rgba(42, 50, 51, 0.12);
  padding: 6px;
  transition: background 300ms linear;
}
.table-row:first-child {
  border-top: 1px solid rgba(42, 50, 51, 0.12);
}
.minyanim-table .table-row {
  grid-template-columns: 1fr 1fr 2fr 2fr 1.5fr 2.5fr 72px;
  min-height: 53px;
}
.events-table .table-row {
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.7fr 2fr 2fr 72px;
  min-height: 53px;
  align-items: center;
}
.contacts-table .table-row {
  grid-template-columns: 1.25fr 1fr 1.5fr 2fr 72px;
  min-height: 53px;
}
.admins-table .table-row {
  grid-template-columns: 1.25fr 1fr 72px;
  min-height: 53px;
}
.notes-text-area {
  min-height: 80px;
  font-size: 12px;
  width: 100%;
}
.shul-info-table .table-row:hover {
  background: rgba(42, 50, 51, 0.02);
}
.shul-info-table .table-header-row:hover {
  background: #fff;
}
/* .shul-info-table-actions {text-align: right; opacity: 0; visibility: hidden; transition: opacity 300ms linear; font-size: 18px;} */
/* .shul-info-table .table-row:hover .shul-info-table-actions {opacity: 1; visibility: visible;} */
.shul-info-table-actions i {
  font-size: 18px;
  color: #dcdcdc;
  /* transition: opacity 300ms linear;  */
  cursor: pointer;
  margin-left: 4px;
}
.shul-info-table-actions i:hover {
  color: #23d7f2;
}

/* Contacts Table Styles */

.phone-email-column i {
  margin-right: 4px;
  font-size: 14px;
  margin-bottom: 2px;
}
.phone-email-column {
  line-height: 1.5em;
}
.locked-icon {
  color: #ef6a6e;
}
.unlocked-icon {
  color: #17cd90;
}
.edit-icon {
  color: #2a3233;
}

/***** ADD / EDIT MINYAN/CONTACTS MODALS STYLES *****/
.modal-content {
  max-width: 680px;
  margin: 0 auto;
  padding-top: 40px;
  position: relative; /* TODO:Can this be done with flex */
}

.close-modal {
  height: 56px;
  width: 56px;
  border-radius: 28px;
  background-color: rgba(42, 50, 51, 0.08);
  position: absolute;
  right: 20px;
  top: 20px;
}

/*** Table Styles ***/
.edit-modal .table-row {
  display: grid;
  grid-column-gap: 16px;
  padding: 16px 8px;
  grid-template-columns: 130px auto;
  border: none;
  align-items: center;
}

.table-row > select,
.table-row > input {
  width: 350px;
  height: 32px;
  border: 1px solid rgba(28, 60, 83, 0.16);
  border-radius: 4px;
  background: white;
}

.button-container {
  display: flex;
  height: 40px;
  width: 620px;
  position: absolute;
  right: 60;
  justify-content: space-between;
}

.edit-modal .btn {
  width: 120px;
}
.btn.save-and-add {
  width: 260px;
}

.accent-text-secondary {
  margin-top: 15px;
}

.admin-contact-us-inputs {
  max-width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.admin-contact-us-input {
  max-width: 100%;
  flex: 1;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px) {
}
@media only screen and (min-width: 561px) and (max-width: 1024px) {
}
@media only screen and (max-width: 560px) {
  .add-edit-shul-outer-wraper {
    margin-left: 0;
    padding: 0;
  }
  header.header-fixed {
    position: relative;
    width: 100%;
    border-bottom: none !important;
  }

  /* doesn't seem right */
  /* .shul-info-card .flex-align-center {
    margin-bottom: 32px;
  } */

  .shul-info-card .shul-update-message {
    width: 270px;
  }
  .edit-shul-start-date {
    width: 80px !important;
    /* padding-right: 10px; */
  }
  .edit-shul-end-date {
    width: 80px !important;
    padding-left: 0;
    /* padding-right: 10px; */
  }
  .edit-shul-date-picker {
    margin: 0 !important;
  }
  .mobile-back-arrow,
  .edit-icon {
    padding-right: 12px;
    color: #2a3233;
  }
  .edit-icon {
    padding-top: 6px;
  }
  .temp-minyan-box {
    margin-left: 0;
  }
  .contacts-table,
  .minyanim-table,
  .admins-table {
    border-top: 1px solid rgba(42, 50, 51, 0.12);
  }
  .minyanim-table .table-row,
  .contacts-table .table-row,
  .admins-table .table-row {
    display: grid;
    line-height: 1.4em;
    grid-template-columns: 100%;
    padding: 0px;
    margin-top: 8px;
    padding-bottom: 8px;
  }
  .minyanim-table p {
    font-size: 10px;
  }
  .minyan-days {
    padding-bottom: 6px;
    line-height: 0.5em;
  }
  .minyanim-table .minyan-type,
  .contacts-table .contact-name {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .mobile-minyan-notes {
    line-height: 1em;
  }
  .add-shul {
    padding-top: 10px;
  }

  .admin-contact-us-inputs {
    margin-bottom: 10px;
  }
}
