.minyan-suggestions-container{
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  padding: 36px;
  min-height: 100vh;
}

.minyan-suggestions-title{
  font-size: 24px;
  margin-bottom: 40px;
}

.minyan-suggestion-row-container{
  border-bottom: solid 1px rgba(42,50,51,0.12);
}

.minyan-suggestion-row{
  display: grid;
  grid-template-columns: 1fr 70px 45px 65px 70px 1fr .9fr 100px 100px 63px;
  grid-column-gap: 16px;
  padding: 8px 0;
  min-height: 58px;
}

.minyan-suggestions-current-minyan-row{
  display: grid;
  grid-template-columns: 1fr 70px 45px 65px 70px 1fr .9fr 100px 100px 63px;
  grid-column-gap: 16px;
  background-color: #f5f5f5;
  min-height: 30px;
  padding: 6px 0;
  margin-bottom: 8px;
  font-size: 11px;
}

.approve-suggestion{
  margin-right: 10px;
  color: #17CD90;
  cursor: pointer;
}

.deny-suggestion{
  color: #EF6A6E;
  cursor: pointer;
}

.no-minyan-suggestions{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-top: 50px;
}


@media only screen and (max-width: 560px){
  .minyan-suggestions-container{
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  
  .minyan-suggestions-title{
    font-size: 16px;
    margin: 0;
  }

  .mobile-minyan-suggestions-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    box-shadow: 0 0 12px -7px rgba(0,0,0,0.12), 0 0 12px -2px rgba(0,0,0,0.12);
    padding: 16px;
  }

  .minyan-suggestions-table{
    margin: 20px 10px 40px 10px;
  }

  .minyan-suggestion-row{
    grid-template-columns: 100%;
    line-height: 1.2em;
  }

  .mobile-minyan-sugg-first-row{
    border-top: solid 1px rgba(42,50,51,0.12);
  }

  .minyan-suggestions-current-minyan-row{
    grid-template-columns: 100%;
    line-height: 1.2em;
    padding-left: 8px;
  }

}