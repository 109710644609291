/***** FLEX STYLES *****/
.flex {display: flex;}
.flex-1 {flex: 1;}
.flex-no-shrink {display: flex; flex-shrink: 0;}
.flex-align-center {display: flex; align-items: center;}
.flex-justify-center {display: flex; justify-content: center;}
.flex-justify-left {display: flex; justify-content: flex-start;}
.flex-justify-space {display: flex; justify-content: space-between;}
.flex-justify-space-center {display: flex; justify-content: space-between; align-items: center;}
.flex-align-middle {display: flex; justify-content: center; align-items: center;}
.flex-left-center-columns {display: flex; justify-content: center; align-items: flex-start; flex-direction: column;}
.flex-bottom-right{ display: flex; align-items: flex-end; justify-content: flex-end;}
.flex-left-center {display: flex; justify-content: flex-start; align-items: center;}
.flex-right-center {display: flex; justify-content: flex-end; align-items: center;}
.flex-center-columns {display: flex; justify-content: center; align-items: center; flex-direction: column;}
.flex-columns {display: flex; flex-direction: column;}
.flex-columns-align-center {display: flex; align-items: center; flex-direction: column;}
.flex-justify-center-columns {display: flex; justify-content: center; flex-direction: column;}
.flex-justify-end-columns {display: flex; justify-content: flex-end; flex-direction: column;}


/***** DISPLAY STYLES *****/
.inline {display: inline;}

/***** CONTAINER STYLES *****/
.container {max-width: 1200px; margin: 0 auto; width: 100%;}
.max-100 {max-width: 100%;}
.max-60 {max-width: 60%;}

/***** COLUMN STYLES *****/
.full-width {width: 100%;}
.column-half {width: 50%;}
.column-half-left {margin-right: 16px;}
.column-half-right {margin-left: 16px;}

/***** MARGIN STYLES *****/
.mt-40 {margin-top: 40px;}
.mr-40 {margin-right: 40px;}
.mb-40 {margin-bottom: 40px;}
.ml-40 {margin-left: 40px;}
.mt-32 {margin-top: 32px;}
.mr-32 {margin-right: 32px;}
.mb-32 {margin-bottom: 32px;}
.ml-32 {margin-left: 32px;}
.mt-24 {margin-top: 24px;}
.mr-24 {margin-right: 24px;}
.mb-24 {margin-bottom: 24px;}
.ml-24 {margin-left: 24px;}
.mt-16 {margin-top: 16px;}
.mr-16 {margin-right: 16px;}
.mb-16 {margin-bottom: 16px;}
.ml-16 {margin-left: 16px;}
.mr-12 {margin-right: 12px;}
.mb-12 {margin-bottom: 12px;}
.mt-12 {margin-top: 12px;}
.mt-8 {margin-top: 8px;}
.mr-8 {margin-right: 8px;}
.mb-8 {margin-bottom: 8px;}
.ml-8 {margin-left: 8px;}
.mr-6 {margin-right: 6px;}
.mb-6 {margin-bottom: 6px;}
.mt-4 {margin-top: 4px;}
.mr-4 {margin-right: 4px;}
.mb-4 {margin-bottom: 4px;}
.ml-4 {margin-left: 4px;}

/***** POSITION STYLES *****/
.relative {position: relative;}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 561px){
  /* for now most tablet styles were not implemented so we only want to hide for mobile */
  .desktop-hidden {display: none !important;}
}
@media only screen and (min-width: 1024px){
  .desktop-inline {display: inline !important;}
}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .tablet-hidden {display: none !important;}
  .tablet-visible {display: block;}
}
@media only screen and (max-width: 560px){
  .mobile-hidden {display: none !important;}
  .mobile-showing {display: block;}
  .mobile-full-width {
    width: calc(100% - 16px) !important;
    margin: 8px !important;
  }
  .mobile-min-100{min-width: 100%; max-width: 100%;}
 
  .mobile-mb-12 {margin-bottom: 12px;}
  .mobile-mb-6 {margin-bottom: 6px;}
  .mobile-mb-30 {margin-bottom: 30px !important;}
  .mobile-mb-40 {margin-bottom: 40px !important;}
  .mobile-ml-32 {margin-left: 32px;}
  .mobile-mt-0 {margin-top: 0;}
  .mobile-mb-0 {margin-bottom: 0;}
  .mobile-mt-6 {margin-top: 6px;}
  .mobile-mt-12 {margin-top: 12px;}
  .mobile-mt-10{margin-top: 10px;}
  .mobile-pl-8 {padding-left: 8px;}
  .mobile-mt-8 {margin-top: 8px;}
  .mobile-mr-8 {margin-right: 8px;}
  .mobile-mr-12 {margin-right: 12px;}
  .mobile-pb-8 {padding-bottom: 8px;}
  .mobile-margin-0 {margin: 0;}
  .mobile-padding-0{padding: 0 !important;}
  

  /***** FLEX STYLES *****/
  .mobile-flex {display: flex;}
  .mobile-flex-1 {flex: 1;}
  .mobile-flex-align-middle {display: flex; justify-content: center; align-items: center;}
  .mobile-flex-align-center {display: flex; align-items: center;}
  .mobile-flex-center-columns{display: flex; justify-content: center; align-items: center; flex-direction: column;}
}
