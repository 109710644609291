.suggest-minyanim-wraper{
  padding: 40px;
}

.suggest-minyanim-header{
  position: sticky;
  border-bottom: 1px solid #e9e9e9;
  height: 80px;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  right: 0;
}

.back-to-shul-details{
  color: #23D7F2;
  margin-right: 20px;
}

.back-to-shul-details-icon{
  font-size: 28px;
}

.suggest-minyan-shul-name{
  font-size: 24px;
  text-transform: capitalize;
}

.suggest-minyanim-content{
  padding-bottom: 40px;
  /* padding-top: 40px; */
}

.minyanim-table-header{
  border-top: 1px solid rgba(42,50,51,0.12);
}

.current-minyan-row{
  display: grid;
  grid-column-gap: 16px;
  border-bottom: 1px solid rgba(42,50,51,0.12);
  padding: 6px;
  transition: background 300ms linear;
  grid-template-columns: .7fr 1fr 2fr 2fr 1.5fr 2.5fr 72px;
  min-height: 53px;
}

.suggested-minyan-row{
  display: grid;
  grid-column-gap: 16px;
  border-bottom: 1px solid rgba(42,50,51,0.12);
  padding: 6px;
  transition: background 300ms linear;
  grid-template-columns: 72px 100px 1fr 1.7fr 1.5fr 1fr 2.2fr;
  min-height: 53px;
}

.delete-warning-dialog{
  /* height: 364px; */
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
}

.delete-waning-icon{
  margin-top: 38px;
  margin-bottom: 24px;
  height: 92px;
  width: 92px;
  border: 4px solid #EF6A6E;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-waning-icon-x{
  font-size: 40px;
  color: #EF6A6E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.are-you-sure{
  font-size: 24.53px;
  margin-bottom: 12px;
}

.delete-warning-message{
  color: rgba(0,0,0,0.38);
  font-size: 14.31px;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin-bottom: 40px;
}

.delete-warning-cancel{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  background-color: #EEEEEE;
  color: #2A3233;
  font-size: 14.31px;
  font-weight: 500;
  line-height: 16px;
  margin-right: 16px;
  cursor: pointer;
}

.delete-warning-delete{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  color: #FFFFFF;
  background-color: #EF6A6E;
  font-size: 14.31px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

@media only screen and (max-width: 560px){
  .suggest-minyanim-header{
    height: 60px;
    padding: 10px;
  }

  .back-to-shul-details {
    margin-right: 10px;
}

  .suggest-minyan-shul-name{
    font-size: 14px;
    line-height: 1.2em;
  }

  .current-minyan-row, .suggested-minyan-row{
    display: grid;
    grid-template-columns: 100%;
    line-height: 1.2em;
  }

  .delete-warning-dialog{
    width: 260px;
    margin-bottom: 26px;
  }
  
  .delete-waning-icon{
    margin-top: 22px;
    margin-bottom: 16px;
    height: 65px;
    width: 65px;
    border: 2.8px solid #EF6A6E;
  }
  
  .delete-waning-icon-x{
    font-size: 28px;
  }
  
  .delete-warning-message{
    padding: 0 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 100%;
  }
  
  .delete-warning-cancel, .delete-warning-delete{
    height: 29px;
    width: 88px;
    font-size: 10px;
  }

}
