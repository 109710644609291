.component-font-color{
  color: #2A3233;
}

/***** SEARCHBOX STYLES *****/
.searchbox-container {
  height: 40px;
  border: 1px solid rgba(28,60,83,0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 16px;
}
.searchbox {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  padding-left: 16px;
}
.searchbox:focus {
  outline: none;
}
.searchbox-container i {
  color: #23D7F2;
}
/*** SEEMS TO BE SAME SIZE ON ALL DEVICES ***/
.searchbox-container {height: 32px;}
/*
@media only screen and (max-width: 1024px){
  .searchbox-container {height: 32px;}
}
*/
/***** BUTTON STYLES *****/
.btn {
  height: 40px;
  min-width: 168px;
  border-radius: 3px;
  font-size: 14px;
  border: none;
  text-transform: uppercase;
  transition: all 300ms linear;
  cursor: pointer;
}
.btn:focus {outline: none;}
/* @media only screen and (max-width: 1024px){
  .btn {height: 32px; width: 96px; font-size: 11px;}
} */

/*** Button Accent Styles ***/
.btn-accent {background-color: #23D7F2; color: #FFFFFF; border: 1px solid #23D7F2;}
.btn-accent:hover {background: #fff; color: #23D7F2;}
.btn.btn-small.btn-accent {width: 140px; font-size: 12px;}

/*** Button Light Styles ***/
.btn-light {background-color: #eee; color: #2A3233; border: 1px solid #eee;}
.btn-light:hover {background: #2a3233; color: #eee;}
.btn.btn-light.btn-small {width: 80px; font-weight: 500;}

/*** Button Approve Styles ***/
.btn-approve {background: #17CD90; color: #fff; border: 1px solid #17cd90;}
.btn-approve:hover {background: #fff; color: #17cd90;}
.btn.btn-approve.btn-small {width: unset; padding: 0 16px;}

/*** Button Deny Styles ***/
.btn-deny {background: #EF6A6E; color: #fff; border: 1px solid #EF6A6E;}
.btn-deny:hover {background: #fff; color: #EF6A6E;}
.btn.btn-deny.btn-small {width: unset; padding: 0 16px;}

/*** Button Sizes ***/
.btn.btn-small {height: 32px;}

/***** CARD STYLES *****/
.card {
  background: #fff;
  box-shadow: 0 0 12px -7px rgba(0,0,0,0.12), 0 0 12px -2px rgba(0,0,0,0.12);
  border-radius: 3px;
  padding: 24px;
}

/***** INPUT STYLES *****/
input.custom-input, select.custom-input, textarea.custom-input {
  border: 1px solid rgba(28,60,83,0.16);
  border-radius: 3px;
  height: 40px;
  padding: 8px;
  background: transparent;
  transition: border 300ms linear;
}
input.custom-input:focus, select.custom-input:focus, textarea.custom-input:focus {outline: none; border: 1px solid #23D7F2;}
.required {border: 1px solid red !important;}

/***** CHECKBOX LABEL STYLES *****/
.checkbox-labels-container {display: flex; flex-wrap: wrap;}
.checkbox-label label {
  display: block;
  min-width: 96px;
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  margin-right: 8px;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.08);
  transition: background 300ms linear;
}
.checkbox-label input {display: none !important;}
.checkbox-label label:hover {background: #f5f5f5; border-color: #f5f5f5;}
.checkbox-label input:checked + label {background-color: rgba(0,0,0,0.08);}

/***** CUSTOM RADIO BUTTON STYLES *****/
.radio-button-container [type="radio"]:checked, .radio-button-container [type="radio"]:not(:checked) {position: absolute; left: -9999px;}
.radio-button-container [type="radio"]:checked + label, .radio-button-container [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size: 13px;
    margin-right: 16px;
}
.radio-button-container [type="radio"]:not(:checked) + label {color: $accent-text-light;}
.radio-button-container [type="radio"]:checked + label.inline, .radio-button-container [type="radio"]:not(:checked) + label.inline {display: inline;}
.radio-button-container [type="radio"]:checked + label:before, .radio-button-container [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #FBFBFC;;
}
.radio-button-container [type="radio"]:checked + label:before {border: 1px solid rgba(0,0,0,0.54);}
.radio-button-container [type="radio"]:checked + label:after, .radio-button-container [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: rgba(0,0,0,0.54);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.radio-button-container [type="radio"]:not(:checked) + label:after {opacity: 0; -webkit-transform: scale(0); transform: scale(0);}
.radio-button-container [type="radio"]:checked + label:after {opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
.radio-button-container .radio-label {color: #76838f; font-size: 13px;}


/***** OVERRIDE FOR SEASONAL RADIO BOXES *****/
.preset-labels-div {flex-wrap: wrap;}
.preset-labels-div .checkbox-label [type="radio"]:checked + label:after, 
.preset-labels-div .checkbox-label [type="radio"]:checked + label:before,
.preset-labels-div .checkbox-label [type="radio"]:not(:checked) + label:after,
.preset-labels-div .checkbox-label [type="radio"]:not(:checked) + label:before {
  display: none;
}
.preset-labels-div [type="radio"]:checked + label, .preset-labels-div [type="radio"]:not(:checked) + label {
  padding-left: 16px;
  display: flex;
}


/***** UPLOAD BOX STYLES *****/
.upload-box {
  border: 1px dashed rgba(42,50,51,0.24);
  border-radius: 3px;
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone-custom{
  height: 100%;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.image-thumb-nails-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.image-thumb-nail{
  height: 70px;
  width: 90px;
  margin: 5px;
}

.image-thumb-nail-div{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-thumb-nail-div:hover .image-thumb-nail-delete-overlay{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,0.72);
}

.image-thumb-nail-delete-overlay{
  display: none;
}

.image-thumb-nail-delete-icon{
  color: #ffffff;
  cursor: pointer;
}

.images-coming-soon{
  width: 168px;
  height: 32px;
  background-color: darkgray;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.image-placeholder-message{
  margin-top: 50px;
  line-height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
}

.image-placeholder-message a{
  color: #2A3233;
  text-decoration: underline;
}

.uploaded-images {margin-top: 32px; display: flex;}
.uploaded-images > div {margin-right: 16px;}
.uploaded-images img {height: 64px; width: 64px; border-radius: 3px; object-fit: cover;}
.uploaded-images > div > i {
  height: 64px;
  width: 64px;
  border-radius: 3px;
  background: rgba(0, 0, 0, .6);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: none;
  cursor: pointer;
  transition: opacity 300ms linear;
}
.uploaded-images > div:hover i {opacity: 1; visibility: visible;}
.pipe { margin: 0 15px; }

/***** FULL PAGE MODAL STYLES *****/
.full-page-modal {margin: 96px 16px 32px;}
.full-page-modal-close {
  position: absolute;
  top: 40px;
  right: 64px;
  height: 56px;
  width: 56px;
  border-radius: 100%;
  background-color: rgba(42,50,51,0.08);
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-page-modal .container {max-width: 680px;}
.bordered-title {border-bottom: 1px solid #E9E9E9; padding-bottom: 16px; margin-bottom: 24px;}
.input-label {display: inline-block; min-width: 136px; max-width: 100%; font-size: 14px;}
.full-page-modal input.custom-input {width: 360px; max-width: 100%;}
.full-page-modal select.custom-input {width: 360px; max-width: 100%;}
.full-page-modal textarea.custom-input {width: 100%; height: 80px; resize: none;}
.full-page-modal-footer {border-top: 1px solid #E9E9E9; margin-top: 24px; padding-top: 24px;}
.full-page-modal-footer-btns {display: flex; justify-content: flex-end;}
.full-page-modal-footer-btns .btn {margin-left: 8px; width: auto; padding: 0 16px; min-width: 104px;}

/*** Auto Complete ***/
.autocomplete-container {
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item.active {
  background-color: #fafafa;
}

/*** front end feedback box ***/

.feedback-div{
  position: fixed;
  /* height: 184px;
  width: 71px; */
  height: 150px;
  width: 40px;
  background-color: #636363;
  /* top: 465px; */
  bottom: 150px;
  z-index: 1;
}

.feedback{
  font-weight: 500;
  color: #FFFFFF;
  font-size: 18px;
  transform: rotate(-90deg);
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .full-page-modal {margin: 38px 0px 0px;}
  .full-page-modal .container {padding-right: 16px; padding-left: 16px;}
  .full-page-modal-close {
    top: 46px;
    right: 31px;
    height: 0;
    width: 0;
    background-color: #ffffff;
  }
  .bordered-title{
    border-bottom: none;
    padding-left: 20px;
    padding-bottom: 22px;
    box-shadow: 0 8px 6px -6px rgba(0,0,0,0.12);
  }
  .full-page-modal input.custom-input {width: 210px; max-width: 100%;}
  .input-label {display: inline-block; min-width: 100px; max-width: 100%; font-size: 12px;}
  .full-page-modal select.custom-input {width: 210px; max-width: 100%; height: 32px;}
  .mobile-block {
    display: block !important;
  }
  input.custom-input {
    width: 100%;
    margin: 10px 0;
  }
  .btn {
    height: 28px;
    min-width: 50px;
    font-size: 10px;
  }
  .full-page-modal-footer-btns .btn{min-width: 0;}
  .btn.btn-small.btn-accent {width: 100%;}

}