.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  /* width: 150px !important; */
}

.slick-track{
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.sponsor-img{
  max-width: 100%;
  max-height: 100%;
}