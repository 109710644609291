.tos-container{
  width: 100%;
  font-size: 16px;
  color: #121013; 
  margin: 38px 8px;
  max-width: 800px;
}

.tos-header{
  display: flex;
  justify-content: center;
  color: #11B6D7;
  font-size: 30px;
  font-weight: bold;
}

.tos-sub-header{
  color: #11B6D7;
  font-size: 20px;
  margin: 36px 0 18px 0;
}

.tos-topic-title{
  color: #11B6D7;
  font-size: 16px;
  margin: 14px 0 6px 0;
}

.tos-topic-text{
  font-size: 12px;
  line-height: 1.3em;
  margin-left: 15px;
}

.tos-ul{
  list-style-type: lower-roman;
  margin: 4px 0 10px 25px;
}

.tos-privacy-ul{
  list-style-type: square;
  margin: 4px 0 10px 25px;
}

.tos-contact-us-link{
  color: #11B6D7;
  text-decoration: underline;
}


/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){
 
}
@media only screen and (max-width: 560px){
  .tos-header{
    font-size: 25px;
  }

  .tos-sub-header{
    font-size: 18px;
  }
  
}