.login-container {
	height: 463px;
	width: 364px;
	border: 2.4px solid #11B6D7;
	background-color: #FFFFFF;
  box-shadow: 0 2px 4px 4px rgba(0,0,0,0.4);
  z-index: 9999999;
  text-align: center;
  position: fixed;
  right: 20px;
  top: 20px;
}

.login-title {
	color: #11B6D7;
	font-size: 24px;
  font-weight: bold;
  padding-top: 70px;
  padding-bottom: 15px;
}

.login-input {
	height: 40px;
	width: 217px;
	border: 0.72px solid #595A5A;
  background-color: #F2F2F2;
  font-size: 18px;
  text-align: center;
  color: #4B4C4D;
  margin-bottom: 15px;
}

.login-submit-button {
	height: 43px;
	min-width: 126px;
  background-color: #11B6D7;
  color: #FCFCFC;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: none;
}

.login-submit-button:focus, .login-input:focus {
  outline: none;
  border: 0.72px solid #11B6D7;
}

.submit-button-div{
  height: 43px;
  margin-top: 14px;
  margin-bottom: 7px;
}

.link-text{
  color: #4B4C4D;
  font-size: 12px;
  cursor: pointer;
}

.link-text-blue{
  color: #11B6D7;
  font-size: 14px;
}

.x-icon{
  cursor: pointer;
  position: absolute;
  right: 0;
}

.login-message-box{
  padding-bottom: 35px;
  padding-top: 40px;
}

.login-sub-titile {
	color: #595A5A;
	font-size: 16px;
  font-weight: bold;
}

.reset-message{
  color: #4B4C4D;
  font-size: 16px;
  padding-top: 130px;
  padding-bottom: 65px;
  line-height: 1.5em;
}

.login-message-text{
    color: #595A5A; 
    font-size: 16px;
    font-weight: 300;
}

.login-message-text-div{
  padding-bottom: 25px;
  line-height: 1.5em;
}

.login-required-field{
  border: 1px solid red;
}

.user-not-found-text-div{
  line-height: 1.5em;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .login-container {
    width: 300px;
    right: 6px;
    height: inherit;
    position: absolute;
    padding-bottom: 10px;
  }
}
