.shul-details-outer-wrapper{
  margin: 20px 4px 48px 4px;
  font-family: Roboto;
  color:  #484848;
}

.back-to-results{
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #11B6D7;
  font-weight: 500;
}

.shul-details-featured-sponsor{
  color: rgba(0,0,0,0.38);
  font-size: 10px;
  letter-spacing: 1px;
}

.featured-sponsor-img{
  width: 115px;
  height: auto;
  padding-bottom: 5px;
}

.last-updated{
  color: rgba(0,0,0,0.38);
  font-size: 12px;
  flex-shrink: 0;
  margin-left: 8px;
}

.shul-details-container{
  width: 940px;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  color:  #484848;
}

.shul-details-color{
  color: #484848;
}

.shul-details-warning{
  min-height: 20px;
  width: 100%;
  border-radius: 3px;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 8px;
  text-align: center;
  padding: 14px;
}

.shul-name-contacts-box {
  width: 100%;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.shul-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* display: grid;
  grid-template-columns: 1fr .5fr; */
  min-height: 90px;
}

.shul-details-info-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 19px;
}

.shul-name {
  font-size: 24px;
  font-weight: 500;
  color: #11B6D7;
  padding-bottom: 8px;
}

.shul-details-temp-info{
  display: flex;
  color: #484848;
  font-size: 12px;
  font-weight: bold;
  margin-top: 16px;
}

.shul-details-other-info-column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shul-details-nusach-temp-div{
  display: flex;
  justify-content: flex-end;
}

.shul-details-nusach{
  height: 26px;
  width: 104px;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 8px;
}

.shul-details-temp-minyan-flag{
  height: 26px;
  width: 104px;
  border-radius: 3px;
  background-color: #FCAC3A;
  color: #FFFFFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 8px;
}

.shul-details-temp-dates{
  display: flex;
  justify-content: flex-end;
  color: #FCAC3A;
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
}

.shul-details-icons-div{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
}

.shul-details-icons{
  font-size: 24px;
  color: #11B6D7;
  cursor: pointer;
}

.shul-details-icons-text{
  font-weight: 500;
  font-size: 14px;
  color: #11B6D7;
  cursor: pointer;
}

.shul-contacts-box{
  width: 100%;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #F2F2F2;
  font-size: 16px;
  line-height: 1.25em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  letter-spacing: .2px;

}
.shul-contacts-box-row{
  font-size: 12px;
  color: #484848;
  width: 100%;
  min-height: 28px;
  display: grid;
  grid-template-columns: 186px 95px 186px 186px;
  grid-column-gap: 5px;
}

.shul-details-week-sponsor{
  min-height: 40px;
  width: 100%;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  line-height: 12px;
}

.dedication-oppor-link {
	height: 24px;
	width: 180px;
  border-radius: 3px;
  color: #FFFFFF;
  background-color: rgba(255,255,255,0.16);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.minyan-table-legend{
  /* margin-top: 16px; */
  margin-bottom: 28px;
  height: 40px;
  width: 100%;
  background-color: #F2F2F2;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.87);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
}


.reg-minyanim{
  height: 31px;
  width: 115px;
  color: #484848;
  font-size: 14px;
  font-weight: bold;
  margin-right: 40px;
  cursor: pointer;
}

.reg-minyanim-active{
  border-bottom: 2px solid #11B6D7;
  color: #11B6D7;
}

.special-minyanim{
  height: 31px;
  width: 88px;
  color: #484848;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 40px;
}

.events-tab{
  height: 31px;
  width: 106px;
  color: #484848;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.events-tab-active{
  border-bottom: 2px solid #11B6D7;
  color: #11B6D7;
}

.events-cards-container{
  display: flex;
  flex-wrap: wrap;
}

.event-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 210px;
  min-height: 150px;
  width: 296px;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  padding: 12px;
  margin: 8px;
  margin-top: 25px;
}

.event-card-lang {
  background-color: #E8E8E8;
  color: #000000;
  padding: 10px 30px;
}

.capitalize-text.event-type-name {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 700;
}

.special-minyanim-active{
  border-bottom: 2px solid #11B6D7;
  color: #11B6D7;
}

.special-days-text{
  color: rgba(0,0,0,0.6);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 18px;
}

.minyanim-box {
	width: 100%;
}

.minyanim-header{
  height: 69px;
  background-color: #11B6D7;
  color: #ffffff;
  width: 940px;
  display: grid;
  font-size: 20px;
  grid-template-columns: .7fr 1fr 1fr 1fr;
  -ms-grid-columns: .7fr 1fr 1fr 1fr;
  margin-top: 20px;
}

.minyan-row{
  min-height: 60px;
  display: grid;
  border-bottom: 1px solid #E4E4E4;
  grid-template-columns: .7fr 1fr 1fr 1fr;
  -ms-grid-columns: .7fr 1fr 1fr 1fr;
}

.minyan-day-name{
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 48px;
}

.minyan-times{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-wrap: wrap;
  /* padding: 2px 24px; */
}

.minyan-time-column{
  max-width: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.minyan-times .minyan-time-column > div:not(:last-child) > div:after { content: ","; position: absolute; }

.minyan-time-display{
  padding: 5px;
}

.minyan-time-display:focus{
  outline: none;
}


.greyed-out-time{
  opacity: .7;
}

.clickable-time{
  color: #11B6D7;
}

.expanded-time-display-container{
  position: relative;
}


.expanded-time-display{
  width: 300px;
  border: 2px solid #11B6D7;
  background-color: #FFFFFF;
  box-shadow: -1px 1px 4px 2px rgba(0,0,0,0.3);
  position: absolute;
  padding: 0 7px;
  top: -1px;
  z-index: 2;
  border-radius: 5px;
}

.schacharis-popup{
  left: 0;
}

.mincha-popup{
  right: -131px;
}

.maariv-popup{
  right: 0;
}

.expanded-time-row{
  min-height: 25px;
  display: grid;
  padding: 2px 0;
  grid-template-columns: .4fr 1fr;
  -ms-grid-columns: .4fr 1fr;
  font-size: 11px;
  border-bottom: 1px solid rgba(163, 227, 239, 0.43);
}

.expanded-time-row-name{
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  flex-wrap: wrap;
  text-align: left;
}

.expanded-time-row-info{
  text-align: left;
}

.notes-box{
  margin-top: 15px;
}

.notes-header{
  color: #11B6D7;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 8px;
}

.notes-text{
  color: #484848;
  font-size: 16px;
  line-height: 18px;
}

.shiurim-link-div{
  padding-top: 20px;
}

.shiurim-link{
  color: #11B6D7;
  font-size: 16px;
  text-decoration: underline;
}

.shul-details-link{
  height: 40px;
  width: 208px;
  border: 1px solid #11B6D7;
  color: #11B6D7;
  font-size: 16px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
}


/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .minyan-day-name{
    padding-left: 30px;
  }
}
@media only screen and (max-width: 560px){
  .shul-details-outer-wrapper{
    margin: 16px 4px 30px 4px;
  }

  .shul-details-container{
    width: 96%;
  }

  .last-updated{
    font-size: 10px;
  }

  .shul-details-warning{
    width: 100%;
    font-size: 12px;
    text-align: center;
    padding: 10px;
    line-height: 14px;
  }

  .shul-name-contacts-box {
    padding: 8px;
  }

  .shul-box{
    grid-template-columns: 1fr .5fr;
    min-height: 90px;
  }

  .shul-details-info-column{
    font-size: 12px;
    line-height: 16px;
    flex-wrap: wrap;
  }

  .shul-name {
    font-size: 14px;
  }

  .shul-details-temp-info{
    flex-direction: column;
    font-size: 12px;
    margin-top: 8px;
  }

  .shul-details-other-info-column{
    display: flex;
    flex-direction: column;
  }

  .shul-details-nusach-temp-div{
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .shul-details-nusach{
    height: 18px;
    width: 72px;
    font-size: 10px;
    margin-bottom: 6px;
  }

  .shul-details-temp-minyan-flag{
    height: 18px;
    width: 72px;
    font-size: 10px;
  }

  .shul-details-icons{
    font-size: 18px;
  }

  .shul-details-icons-text{

    font-size: 12px;

  }

  .shul-contacts-box{
    width: 100%;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #F2F2F2;
    font-size: 16px;
    line-height: 1.25em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    letter-spacing: .2px;

  }
  .shul-contacts-box-row{
    font-size: 12px;
    color: #484848;
    width: 100%;
    min-height: 28px;
    display: flex;
    flex-direction: column;
    grid-template-columns: 186px 95px 186px 186px;
    grid-column-gap: 5px;
    padding-bottom: 10px;
    line-height: normal;
  }

  .shul-details-week-sponsor{
    min-height: 46px;
    font-size: 10px;
    padding: 8px;
  }

  .dedication-oppor-link {
    height: 30px;
    width: 95px;
    text-align: center;
    line-height: 12px;
  }

  .minyanim-header{
    height: 50px;
    width: 100%;
    font-size: 12px;
  }

  .minyan-day-name {
    font-size: 11px;
    padding-left: 12px;
  }

  .minyan-row{
    min-height: 45px;
  }

  .minyan-time-column {
    padding: 0 2px;
}

  .minyan-table-legend {
    height: 46px;
    font-size: 12px;
    line-height: 15px;
  }

  .expanded-time-display{
    width: 189px;
    top: -1px;
  }

  .schacharis-popup{
    left: 0;
  }

  .mincha-popup{
    left: inherit;
    right: -88px !important;
  }

  .maariv-popup{
    left: inherit;
    right: 0 !important;
  }

  .expanded-time-row{
    min-height: 20px;
    font-size: 8px;
  }

  .notes-header{
    text-align: left;
    font-size: 18px;
  }

  .shiurim-link {
    color: #11B6D7;
    font-size: 12px;
    text-decoration: underline;
}

  .shul-details-link {
    height: 40px;
    width: 100%;
    font-size: 12px;
    margin: 8px 16px;
  }
}
