@media only screen and (max-width: 560px){
.filter-sidebar-container{
  position: absolute;
  top: 0;
  color: #484848;
  width: 100%;
  max-width: 100%;
  background-color: #FFFFFF;
  z-index: 999;
  /* padding: 0 16px; */
  font-size: 14px;
  letter-spacing: 0.25px;
}

.mobile-filter-line{
  border-bottom: 1px solid #E4E4E4;
}

.filter-sidebar-top{
  padding: 0 16px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-reset-filters{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.21px;
  color: #11B6D7;
}

.sidebar-filter-section{
  padding: 16px 16px 0 16px;
}

.sidebar-filter-section-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 24px;
}

.active-filters{
  margin-bottom: 16px;
  text-transform: capitalize;
}

.sidebar-time-content.sidebar-filter-content{
  padding-left: 0;
}

.sidebar-filter-li{
  margin-bottom: 16px;
}

.radio-checked{
  color: #11B6D7;
  font-size: 23px;
  margin-right: 8px;
}

.radio-unchecked{
  color: #DCDCDC;
  font-size: 23px;
  margin-right: 8px;
}

.checkbox-checked{
  color: #11B6D7;
  font-size: 23px;
}

.checkbox-unchecked{
  color:  #DCDCDC;
  font-size: 23px;
}

.mobile-clear-time{
  margin-bottom: 16px;
  color: #11B6D7;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.21px;
  width: 38px;
}


.sidebar-distance-slider{
  padding: 20px;
}

.mobile-fiter-show-results{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 28px;
  background-color: #11B6D7;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  margin: 16px 16px 30px 16px;
}
}
