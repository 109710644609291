.about-container{
  width: 100%;
  font-size: 16px;
  color: #121013; 
  text-align: justify;
  background-image: url(/images/aboutPageBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-text-container{
  padding-left: 115px;
  padding-top: 38px;
  padding-bottom: 38px;
  width: 50%;
  line-height: 1.3em;
}

.about-paragraph-title{
  font-size: 24px;
  font-weight: bold;
  color: #11B6D7;
  padding-bottom: 7px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .about-text-container{
    padding-left: 20px;
    font-size: 13px;
  }

  .about-paragraph-title{
    font-size: 18px;
  }

}
@media only screen and (max-width: 560px){
  .about-container{
    width: 100%;
    font-size: 11px;
    color: #121013; 
    text-align: justify;
    background-image: none;
  }

  .about-text-container{
    padding: 20px 18px;
    width: 100%;
    line-height: 1.2em;
  }
  
  .about-paragraph-title{
    font-size: 15px;
    font-weight: bold;
    color: #11B6D7;
    padding-bottom: 5px;
  }
  
}