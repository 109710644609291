.missing-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}
.mi-input {
  padding-left: 12px;
  height: 56px;
  width: 750px;
  max-width: 100%;
  border: 1.5px solid #d7d7d7;
  background-color: #ffffff;
  font-size: 16px;
}
.mi-input:focus {
  outline: none;
}
.mi-clear {
  position: absolute;
  right: 3px;
  top: 14px;
  color: #d7d7d7;
  font-size: 28px;
  cursor: pointer;
}
.mi-places-container {
  width: 100%;
  position: relative;
}
.mi-loading {
  position: absolute !important;
  /* top: 56px; */
}
.mi-places-autocomplete-container {
  position: absolute !important;
  top: 56px;
  width: 100%;
  border: 1px solid #d7d7d7;
  border-top: none;
  background-color: #f8f8f8 !important;
  z-index: 20 !important;
}
.mi-ac-active:hover,
.mi-ac-active:active {
  color: #ffffff !important;
  text-decoration: none !important;
  background-color: #11b6d7 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  background-image: none !important;
}
.mi-ac-active {
  padding: 8px 0 8px 12px;
}
.mi-card-container {
  margin-top: 70px;
  min-height: 200px;
}
.mi-empty-msg{
  text-align: center;
}
.missing-info-card {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  height: 86px;
  width: 556px;
  border: 1.5px solid #d7d7d7;
  background-color: #ffffff;
  margin-bottom: 36px;
  padding: 18px;
}
.mi-info-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mi-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: #0ea6c3;
  font-size: 17px;
  font-weight: 500;
  width: 320px;
  max-width: 100%;
}
.missing-info-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 320px;
  max-width: 100%;
  font-size: 14px;
  color: #484848;
}

.missing-info-update {
  background-color: #000000;
  color: #ffffff;
  height: 36px;
  width: 194px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

@media only screen and (max-width: 560px) {
  .missing-info-container {
    max-width: 100%;
    margin-bottom: 10px;
    margin-top: 16px;
    padding: 0 16px;
  }
  .mi-input {
    height: 36px;
  }
  .mi-clear {
    top: 8px;
    font-size: 22px;
}
.mi-places-autocomplete-container {
  top: 36px;
}
  .mi-card-container {
    margin-top: 21px;
    width: 100%;
  }
  .missing-info-card {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    height: 45px;
    width: 556px;
    margin-bottom: 24px;
    padding: 10px;
  }
  .mi-info-column {
    width: calc(100% - 100px);
  }
  .mi-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    color: #0ea6c3;
    font-size: 9px;
    font-weight: bold;
    width: 100%;
    max-width: 100%;
  }
  .missing-info-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    font-size: 9px;
    color: #484848;
  }

  .missing-info-update {
    height: 18px;
    width: 95px;
    font-size: 7px;
  }
}
