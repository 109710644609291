/***** FLEX STYLES *****/
.flex {display: flex;}
.flex-1 {flex: 1 1;}
.flex-no-shrink {display: flex; flex-shrink: 0;}
.flex-align-center {display: flex; align-items: center;}
.flex-justify-center {display: flex; justify-content: center;}
.flex-justify-left {display: flex; justify-content: flex-start;}
.flex-justify-space {display: flex; justify-content: space-between;}
.flex-justify-space-center {display: flex; justify-content: space-between; align-items: center;}
.flex-align-middle {display: flex; justify-content: center; align-items: center;}
.flex-left-center-columns {display: flex; justify-content: center; align-items: flex-start; flex-direction: column;}
.flex-bottom-right{ display: flex; align-items: flex-end; justify-content: flex-end;}
.flex-left-center {display: flex; justify-content: flex-start; align-items: center;}
.flex-right-center {display: flex; justify-content: flex-end; align-items: center;}
.flex-center-columns {display: flex; justify-content: center; align-items: center; flex-direction: column;}
.flex-columns {display: flex; flex-direction: column;}
.flex-columns-align-center {display: flex; align-items: center; flex-direction: column;}
.flex-justify-center-columns {display: flex; justify-content: center; flex-direction: column;}
.flex-justify-end-columns {display: flex; justify-content: flex-end; flex-direction: column;}


/***** DISPLAY STYLES *****/
.inline {display: inline;}

/***** CONTAINER STYLES *****/
.container {max-width: 1200px; margin: 0 auto; width: 100%;}
.max-100 {max-width: 100%;}
.max-60 {max-width: 60%;}

/***** COLUMN STYLES *****/
.full-width {width: 100%;}
.column-half {width: 50%;}
.column-half-left {margin-right: 16px;}
.column-half-right {margin-left: 16px;}

/***** MARGIN STYLES *****/
.mt-40 {margin-top: 40px;}
.mr-40 {margin-right: 40px;}
.mb-40 {margin-bottom: 40px;}
.ml-40 {margin-left: 40px;}
.mt-32 {margin-top: 32px;}
.mr-32 {margin-right: 32px;}
.mb-32 {margin-bottom: 32px;}
.ml-32 {margin-left: 32px;}
.mt-24 {margin-top: 24px;}
.mr-24 {margin-right: 24px;}
.mb-24 {margin-bottom: 24px;}
.ml-24 {margin-left: 24px;}
.mt-16 {margin-top: 16px;}
.mr-16 {margin-right: 16px;}
.mb-16 {margin-bottom: 16px;}
.ml-16 {margin-left: 16px;}
.mr-12 {margin-right: 12px;}
.mb-12 {margin-bottom: 12px;}
.mt-12 {margin-top: 12px;}
.mt-8 {margin-top: 8px;}
.mr-8 {margin-right: 8px;}
.mb-8 {margin-bottom: 8px;}
.ml-8 {margin-left: 8px;}
.mr-6 {margin-right: 6px;}
.mb-6 {margin-bottom: 6px;}
.mt-4 {margin-top: 4px;}
.mr-4 {margin-right: 4px;}
.mb-4 {margin-bottom: 4px;}
.ml-4 {margin-left: 4px;}

/***** POSITION STYLES *****/
.relative {position: relative;}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 561px){
  /* for now most tablet styles were not implemented so we only want to hide for mobile */
  .desktop-hidden {display: none !important;}
}
@media only screen and (min-width: 1024px){
  .desktop-inline {display: inline !important;}
}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .tablet-hidden {display: none !important;}
  .tablet-visible {display: block;}
}
@media only screen and (max-width: 560px){
  .mobile-hidden {display: none !important;}
  .mobile-showing {display: block;}
  .mobile-full-width {
    width: calc(100% - 16px) !important;
    margin: 8px !important;
  }
  .mobile-min-100{min-width: 100%; max-width: 100%;}
 
  .mobile-mb-12 {margin-bottom: 12px;}
  .mobile-mb-6 {margin-bottom: 6px;}
  .mobile-mb-30 {margin-bottom: 30px !important;}
  .mobile-mb-40 {margin-bottom: 40px !important;}
  .mobile-ml-32 {margin-left: 32px;}
  .mobile-mt-0 {margin-top: 0;}
  .mobile-mb-0 {margin-bottom: 0;}
  .mobile-mt-6 {margin-top: 6px;}
  .mobile-mt-12 {margin-top: 12px;}
  .mobile-mt-10{margin-top: 10px;}
  .mobile-pl-8 {padding-left: 8px;}
  .mobile-mt-8 {margin-top: 8px;}
  .mobile-mr-8 {margin-right: 8px;}
  .mobile-mr-12 {margin-right: 12px;}
  .mobile-pb-8 {padding-bottom: 8px;}
  .mobile-margin-0 {margin: 0;}
  .mobile-padding-0{padding: 0 !important;}
  

  /***** FLEX STYLES *****/
  .mobile-flex {display: flex;}
  .mobile-flex-1 {flex: 1 1;}
  .mobile-flex-align-middle {display: flex; justify-content: center; align-items: center;}
  .mobile-flex-align-center {display: flex; align-items: center;}
  .mobile-flex-center-columns{display: flex; justify-content: center; align-items: center; flex-direction: column;}
}

/***** LAYOUT STYLES *****/

/*** HEADER STYLES ***/

.todays-sponsor-banner {
  min-height: 32px;
  background-color: #F2F2F2;
  color: rgba(0,0,0,0.87);
  width: 100%;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.todays-sponsor-banner a{
  color: rgba(0,0,0,0.87);
}

.front-layout-header-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: #ffffff;
  margin: 0 90px;
}

.front-layout-header-border{
  border-bottom: 1px solid #E4E4E4;
}

.front-layout-header-logo{
  width: 130px;
}

.front-layout-footer-logo{
  width: 130px;
}

.front-layout-next-minyan{
  height: 40px;
  width: 188px;
  border-radius: 24px;
  border: 1px solid #FA5858;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
}

.front-layout-search-bar{
  margin-left: 16px;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,0.12), 0 2px 8px 1px rgba(0,0,0,0.08);
  border-radius: 3px;
}
.front-layout-mode-toggle{
  height: 48px;
  width: 205px;
  /* border: 1px solid rgba(0,0,0,0.04);
  border-right: none; */
  background-color:  #FFFFFF;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.front-layout-mode-toggle-line{
  height: 34px;
  border-right: solid 1px #E4E4E4;
  margin: 6px;
  height: 30px;
}

.front-layout-mode-toggle-label{
  color: rgba(0,0,0,0.6);
  font-size: 12px;
  cursor: pointer;
}

.front-layout-mode-toggle-label-active{
  color: #11B6D7;
}

.front-layout-input-box{
  height: 48px;
  width: 306px;
  background-color:  #FFFFFF;
}

.front-layout-input-div{
  height: 48px;
  width: 306px;
  background-color:  #FFFFFF;
  position: relative;
}

.front-layout-use-location-icon{
  position: absolute;
  right: 11px;
  top: 15px;
  color: rgba(0,0,0,0.6);
  font-size: 20px;
}

.front-layout-input{
  border: none transparent;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  /* position: relative; */
}

.front-layout-search-button{
  height: 48px;
  width: 78px;
  border-radius: 0 3px 3px 0;
  background-color: #11B6D7;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

.front-layout-shul-auto-complete-container{
  position: absolute;
  background-color: #FFFFFF;
  z-index: 20;
  width: 588px;
  left: -205px;
  margin-top: 3px;
  padding-left: 0px;
  line-height: 1.8em;
  /* border: solid 1px #E4E4E4; */
  border-top: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

/* .shul-auto-complete-row{
  text-transform: capitalize;
  cursor: pointer;
  padding: 2px 5px;
}

.shul-auto-complete-row:hover{
  color: #ffffff;
  text-decoration: none;
  background-color: #11B6D7;
} */









/*** main content styles ***/
.main-content{
  max-width: 100%;
}

/*** footer styles ***/

.front-layout-footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-sponsors{
  min-height: 72px;
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: rgba(0,0,0,0.87);
  font-size: 14px;
  font-weight: 500;
}

.footer-sponsor-colunm{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 7px;
}

.footer-sponsor-title{
  color: rgba(0,0,0,0.32);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.footer-dedication-opportunities{
  color: #11B6D7;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.corporate-sponsors{
  color: rgba(0,0,0,0.32);
  font-size: 12px;
  font-weight: 500;
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.corporate-sponsor-link{
  color: #11B6D7;
  font-size: 12px;
  font-weight: 500;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* .this-weeks-sponsor {
  background-color: #F2F2F2;
  min-height: 32px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
}

.this-weeks-sponsor a{
  color: rgba(0,0,0,0.87);
} */

.footer-main-content-container{
  background-color: #222222;
  height: 250px;
  display: grid;
  grid-template-columns: 1fr .8fr .8fr 1fr;
  -ms-grid-columns: 1fr .8fr .8fr 1fr;
}

.godaven-footer-logo{
  padding-top: 37px;
}

.footer-colunm{
  height: 186px;
  border-left: solid 1px rgba(255,255,255,0.16);
  padding-left: 24px;
}

.footer-page-links-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.58em;
  color: #FFFFFF;
  cursor: pointer;
}

.footer-page-links-column a{
  color: #FFFFFF;
}

.footer-support-gd-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.support-gd-title{
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
}

.support-gd-text{
  font-size: 12px;
  font-weight: 500;
  color: rgba(255,255,255,0.64);
  line-height: 15px;
  padding: 13px 0 40px 0;
}

.support-gd-button{
  height: 40px;
  width: 180px;
  border: 1px solid #FFFFFF;
  border-radius: 24px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
}

.footer-external-links-column{
  display: flex;
  justify-content: flex-end; 
  flex-direction: column;
}

.bitbean-link{
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  margin-top: 63px;
}

.yosi-fishkin-text{
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.3em;
}

.yosi-fishkin-name{
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  padding-top: 8px;
}

.klal-govoah{
  margin-left: 60px;
  margin-top: 32px;
}






/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .todays-sponsor-banner {
    min-height: 25px;
    font-size: 8px;
  }

  .front-layout-header-logo{
    width: 107px;
  }

  .front-layout-footer-logo{
    width: 95px;
  }

  .mobile-front-layout-header-content{
    height: 61px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .mobile-menu-icon{
    position: absolute;
    font-size: 28px;
    color: rgba(0,0,0,0.54);
    left: 13px;
  }

  /*** footer styles ***/

  .footer-sponsors{
    min-height: 193px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    /* padding: 16px; */
  }

  .footer-sponsor-colunm{
    margin: 16px;
  }

  .corporate-sponsors{
    margin-top: 12px;
  }

  .corporate-sponsor-link{
    padding: 8px;
  }

  .footer-main-content-container{
    grid-template-columns: 50% 50%;
    -ms-grid-columns: 50% 50%;
  }

  .footer-colunm{
    height: 180px;
    width: 100%;
    border-left: none;
    padding: 0 16px;
    margin-bottom: 10px;
  }

  .footer-support-gd-column{
    border-right: solid 1px rgba(255,255,255,0.16);
  }
  

  .godaven-footer-logo{
    padding-top: 0;
  }

  .support-gd-title{
    font-size: 14px;
    padding-top: 14px;
  }
  
  .support-gd-text{
    font-size: 11px;
    line-height: 13px;
    padding: 7px 0 11px 0;
  }
  
  .support-gd-button{
    height: 32px;
    width: 130px;
    font-size: 12px;
  }

  .footer-external-links-column{
    display: flex;
    justify-content: space-between; 
    flex-direction: column;
    height: 100%;
  }

  .bitbean-link{
    font-size: 13px;
    margin-top: 10px;
  }
  
  .yosi-fishkin-text{
    font-size: 12px;
    /* line-height: 1.3em; */
  }
  
  .yosi-fishkin-name{
    padding-top: 8px;
  }
  
  .klal-govoah{
    margin-left: 0;
    margin-top: 19px;
  }
  

  
}

.slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  /* width: 150px !important; */
}

.slick-track{
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.sponsor-img{
  max-width: 100%;
  max-height: 100%;
}
.overlay {
	height: 364px;
  width: 400px;
  text-align: center;
}

.x-container {
	text-align: center;
  color: #EF6A6E;
}
.x {
	font-size: 120px;
	font-weight: 100;
}

.message-container {
	height: 81.85px;
	width: 328px;
	margin: 0 auto;
	color: #2A3233;
	font-family: "Helvetica Neue";
	font-size: 24.53px;
	line-height: 29px;
	text-align: center;
}

.message {
	height: 58.56px;
	width: 100%;
	color: rgba(0,0,0,0.38);
	font-family: "Helvetica Neue";
	font-size: 14.31px;
	letter-spacing: 0.1px;
	line-height: 24px;
	text-align: center;
}

.buttons-container{
  width: 328px;
	margin: 0 auto;
}

.button {
	height: 48px;
	width: 156px;
	font-family: "Helvetica Neue";
	font-size: 14.31px;
	font-weight: 500;
	letter-spacing: 1.25px;
	line-height: 16px;
	text-align: center;
  border-radius: 3px;
  border: none;
  cursor: pointer;
	box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
}
.button:focus {outline: none;}

.delete{
  background-color: #EF6A6E;
  color: #FFFFFF;
}

.cancel{
  background-color: #FAFAFA;
  color: #2A3233;
}
/* COVID POLICY */
.covid.buttons-container {
  width: 400px;
}

.covid.buttons-container button{
  width: 125px;
}

select.custom-input.mobile-mt-10.covid {
  font-size: 12px;
}

@media only screen and (max-width: 560px) {
  .covid.buttons-container {
    width: 360px;
  }
  .covid.buttons-container button {
    width: 110px;
  }
}
.ssp-container-wrapper {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 24px;
  font-family: "Satoshi", sans-serif;
  font-feature-settings: "ss03", "liga" off;
}
.ssp-container {
  background-image: url(/images/ShulSpaceDesktopBG.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Satoshi", sans-serif;
  font-feature-settings: "ss03", "liga" off;
  width: 100%;
  border-radius: 5px;
  position: relative;
  padding: 0 16px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.ssp-image-container {
  line-height: 0;
}
.ssp-image {
  height: 109px;
  margin-bottom: 15px;
}
.ssp-txt {
  padding: 0px 80px 0px 46px;
}
.ssp-txt-top-row {
  color: #222c64;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
  display: flex;
  align-items: center;
}
.ssp-shulspace-logo-row {
  display: flex;
  align-items: center;
}
.ssp-shulspace-logo {
  margin: 0 4px 0 9px;
}
.ssp-shulspace-word {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  margin-right: 12px;
}
.ssp-discover-txt {
  color: #222c64;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 6px;
}

@media only screen and (min-width: 1201px) {
  .ssp-container-wrapper {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 930px) and (max-width: 1200px) {
  .ssp-container-wrapper {
    padding: 0 16px;
    margin-bottom: 24px;
  }
  .ssp-container {
    margin-top: 24px;
    background-image: url(/images/ShulSpaceTabletBG.png);
    min-height: 156px;
    align-items: flex-end;
    height: 156px;
    border-radius: 5px;
    justify-content: flex-start;
  }
  .ssp-image {
    height: 166px;
    margin-bottom: 0 !important;
    margin-right: 16px;
  }
  .ssp-txt {
    padding: 0px 0px 7px 0;
  }
}

@media only screen and (max-width: 929px) {
  .ssp-container-wrapper {
    padding: 0;
  }
  .ssp-container {
    margin-top: 8px;
    flex-direction: column;
    padding: 0;
    height: unset;
    background-image: none;
  }
  .ssp-image-container {
    width: 100;
    line-height: 0;
    display: flex;
    justify-content: center;
  }
  .ssp-image {
    width: 100%;
    max-width: 450px;
    height: unset;
    margin-bottom: 0;
  }
  .ssp-txt-top-row {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 8px;
  }
  .ssp-txt {
    margin-top: -2px;
    background-image: url(/images/ShulSpaceMobileBG.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 215px;
    padding: 0 26px 0 19px;
    border-radius: 18px;
    background-color: #ffffff;
  }
  .ssp-partners-with {
    padding-bottom: 8px;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
  }
  .ssp-shulspace-logo {
    margin: 0 4px 0 0;
  }
  .ssp-shulspace-word {
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;

    margin-right: 4px;
  }
  .ssp-shulspace-logo-row {
    font-size: 21px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .ssp-discover-txt {
    padding-left: 8px;
  }
}

.home-page-places-container{
  width: 100%;
  position: relative;
}

.search-page-places-container{
  width: 100%;
  height: 100%;
  position: relative;
}


.search-page-location-input{
  text-transform: capitalize;
}

.google-places-input{
  max-width: 100%;
}

.autocomplete-container{
  width: 100%;
  max-width: 100%;
  /* position: absolute; */
}

.home-input{
  border-radius: 3px;
  width: 100%;
  max-width: 100%;
}

.home-page-places-autocomplete-container{
  position: absolute !important;
  top: 56px;
  background-color: #FFFFFF !important;
  z-index: 20 !important;
  width: 764px !important;
  max-width: 764px !important;
  left: -205px !important;
  margin-top: 1px !important;
  padding-left: 0px !important;
  line-height: 1.8em !important;
}

.front-layout-places-autocomplete-container{
  position: absolute !important;
  top: 49px;
  background-color: #FFFFFF !important;
  z-index: 20 !important;
  width: 588px !important;
  max-width: 662px !important;
  left: -205px !important;
  margin-top: 1px !important;
  padding-left: 0px !important;
  line-height: 1.8em !important;
}

.home-ac-active:hover, .home-ac-active:active{
  color: #ffffff !important;
  text-decoration: none !important;
  background-color: #11B6D7 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  background-image: none !important;
}

.search-autocomplete-container{
 width: 100%;
 position: absolute;
 top: 50px;
 z-index: 999999;
}

@media only screen and (max-width: 560px){

  .home-page-places-autocomplete-container{
    width: 100% !important;
    top: 40px !important;
    left: 0 !important;
    margin-top: 1px !important;
    padding-left: 0px !important;
    line-height: 1.3em !important;
  }

  .autocomplete-container{
    font-size: 10px;
  }

  .search-autocomplete-container{
    width: 100%;
    position: absolute;
    top: 42px;
    z-index: 999999;
   }
}
.login-container {
	height: 463px;
	width: 364px;
	border: 2.4px solid #11B6D7;
	background-color: #FFFFFF;
  box-shadow: 0 2px 4px 4px rgba(0,0,0,0.4);
  z-index: 9999999;
  text-align: center;
  position: fixed;
  right: 20px;
  top: 20px;
}

.login-title {
	color: #11B6D7;
	font-size: 24px;
  font-weight: bold;
  padding-top: 70px;
  padding-bottom: 15px;
}

.login-input {
	height: 40px;
	width: 217px;
	border: 0.72px solid #595A5A;
  background-color: #F2F2F2;
  font-size: 18px;
  text-align: center;
  color: #4B4C4D;
  margin-bottom: 15px;
}

.login-submit-button {
	height: 43px;
	min-width: 126px;
  background-color: #11B6D7;
  color: #FCFCFC;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: none;
}

.login-submit-button:focus, .login-input:focus {
  outline: none;
  border: 0.72px solid #11B6D7;
}

.submit-button-div{
  height: 43px;
  margin-top: 14px;
  margin-bottom: 7px;
}

.link-text{
  color: #4B4C4D;
  font-size: 12px;
  cursor: pointer;
}

.link-text-blue{
  color: #11B6D7;
  font-size: 14px;
}

.x-icon{
  cursor: pointer;
  position: absolute;
  right: 0;
}

.login-message-box{
  padding-bottom: 35px;
  padding-top: 40px;
}

.login-sub-titile {
	color: #595A5A;
	font-size: 16px;
  font-weight: bold;
}

.reset-message{
  color: #4B4C4D;
  font-size: 16px;
  padding-top: 130px;
  padding-bottom: 65px;
  line-height: 1.5em;
}

.login-message-text{
    color: #595A5A; 
    font-size: 16px;
    font-weight: 300;
}

.login-message-text-div{
  padding-bottom: 25px;
  line-height: 1.5em;
}

.login-required-field{
  border: 1px solid red;
}

.user-not-found-text-div{
  line-height: 1.5em;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .login-container {
    width: 300px;
    right: 6px;
    height: inherit;
    position: absolute;
    padding-bottom: 10px;
  }
}

.mobile-menu-container{
  /* position: absolute;
  top: 0; */
  width: 288px;
  max-width: 100%;
  height: 100%;
  background-color: #222222;
  /* box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2);
  z-index: 9999999; */
  color: #ffffff;
}

.mobile-menu-close{
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.mobile-menu-logo-div{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255,255,255,0.16);
}

.mobile-menu-logo{
  width: 168px;
}

.mobile-menu-links{
  padding-left: 16px;
  font-size: 16px;
  color: rgba(255,255,255,0.64);
}

.mobile-menu-links li{
  padding-top: 24px;
}

.mobile-menu-links li a{
  color: rgba(255,255,255,0.64);
}
.home-container{
  background-image: url(/images/homePageBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: calc(100vh - 314px);
  font-size: 16px;
  color: #121013; 
  padding: 0 8px;
}

.home-headers{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 120px 0 120px;
}

.home-header-logo{
  width: 130px;
}

.home-add-minyan{
  height: 40px;
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #11B6D7;
  border-radius: 24px;
  color: #11B6D7;
  font-size: 14px;
  font-weight: 500;
}

.home-title{
  color: #FFFFFF;
  font-size: 38px;
  font-weight: 500;
  margin-top: 84px;
}

.home-next-minyan{
  height: 48px;
  width: 250px;
  border-radius: 28px;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 64px;
  cursor: pointer;
}

.home-search-minyanim{
  color: #FFFFFF;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
  letter-spacing: 1px;
}

.home-search-bar{
  margin-bottom: 158px;
}

.home-mode-toggle{
  height: 56px;
  width: 205px;
  /* border: 1px solid rgba(0,0,0,0.04);
  border-right: none; */
  background-color:  #FFFFFF;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.home-mode-toggle-line{
  height: 40px;
  border-right: solid 1px #E4E4E4;
  margin: 6px;
}

.home-mode-toggle-label{
  color: rgba(0,0,0,0.6);
  font-size: 12px;
}

.home-mode-toggle-label-active{
  color: #11B6D7;
}

.home-input-box{
  height: 56px;
  width: 463px;
  background-color:  #FFFFFF;
}

.home-input-div{
  height: 56px;
  width: 463px;
  background-color:  #FFFFFF;
  position: relative;
}

.use-location-icon{
  position: absolute;
  right: 11px;
  top: 19px;
  color: rgba(0,0,0,0.6);
  font-size: 20px;
}

.home-input{
  border: none transparent;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  /* position: relative; */
}

.home-search-button{
  height: 56px;
  width: 96px;
  border-radius: 0 3px 3px 0;
  background-color: #11B6D7;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

.shul-auto-complete-container{
  position: absolute;
  background-color: #FFFFFF;
  z-index: 20;
  width: 764px;
  left: -205px;
  /* margin-top: .65px; */
  padding-left: 0px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
  border-top: .8px solid  #E4E4E4;
}

.shul-auto-complete-row{
  text-transform: capitalize;
  /* color:  #A3A3A3; */
  cursor: pointer;
  padding: 6px 5px;
  line-height: 1.3em;
}

.shul-auto-complete-row:hover{
  color: #ffffff;
  text-decoration: none;
  background-color: #11B6D7;
}

.geo-dialog{
  display: flex;
  justify-content: center;
  color: #484848;
  width: 600px;
}

.geo-error-div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
}

.geo-message{
  color: #484848;
  font-size: 14px;
  letter-spacing: 0.11px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.3em;

}

.geo-warning-header{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 24px;
}

.geo-dialog-ok-button{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  border-radius: 24px;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 14.31px;
  letter-spacing: 1.25px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  margin: 32px;
}




/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .home-search-title{
    font-size: 42px;
  }
}
@media only screen and (max-width: 560px){
  .home-container{
    background-image: url(/images/homePageMobileBackground.jpg);
  }

  .home-headers{
    justify-content: center;
    padding: 16px 0 0 0;
  }

  .mobile-home-menu-icon{
    position: absolute;
    font-size: 28px;
    color: #ffffff;
    left: 16px;
    top: 16px;
  }

  .home-title{
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    margin-top: 58px;
  }
  
  .home-next-minyan{
    height: 40px;
    width: 200px;
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 40px;
  }
  
  .home-search-minyanim{
    letter-spacing: 0.75px;
    font-size: 12px;
    margin-bottom: 24px;
  }

  .mobile-home-mode-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .home-mode-toggle-label{
    color: rgba(255,255,255,0.6);
    font-weight: 500;
  }

  .home-mode-toggle-label-active{
    color: #11B6D7;
  }

  .home-search-bar{
    width: 100%;
    max-width: 100%;
    margin-bottom: 98px;
  }

  .home-input-box{
    height: 40px;
    width: 100%;
    max-width: 100%;
    /* padding-left: 5px; */
    border-radius: 3px;
  }
  
  .home-input-div{
    height: 40px;
    width: 100%;
    max-width: 100%;
    border-radius: 3px;
  }

  .home-input{
    padding-left: 5px;
  }

  .home-mobile-search{
    position: absolute;
    z-index: 1000;
    height: 40px;
    width: 40px;
    border-radius: 0 3px 3px 0;
    right: 0;
    /* border: 1px solid rgba(0,0,0,0.04); */
    background-color: #11B6D7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-mobile-search-icon{
    color: #ffffff;
  }

  .use-location-icon {
    right: 46px;
    top: 11px;
  }

  .shul-auto-complete-container{
    width: 100%;
    left: 0;
    padding-left: 0px;
  }
  
  .shul-auto-complete-row{
    font-size: 8px;
    padding: 7px 5px;
  }

  .geo-dialog{
    display: flex;
    justify-content: center;
    color: #484848;
    width: 100%;
    text-align: center;
  }
  
  .geo-error-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    text-align: center;
    padding: 10px;
  }
  
  .geo-message{
    font-size: 12px;
    text-align: center;
  }
  
  .geo-warning-header{
    font-size: 15px;
    text-align: center;
  }
  
  .geo-dialog-ok-button{
    height: 35px;
    width: 120px;
  }
}
.about-container{
  width: 100%;
  font-size: 16px;
  color: #121013; 
  text-align: justify;
  background-image: url(/images/aboutPageBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-text-container{
  padding-left: 115px;
  padding-top: 38px;
  padding-bottom: 38px;
  width: 50%;
  line-height: 1.3em;
}

.about-paragraph-title{
  font-size: 24px;
  font-weight: bold;
  color: #11B6D7;
  padding-bottom: 7px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .about-text-container{
    padding-left: 20px;
    font-size: 13px;
  }

  .about-paragraph-title{
    font-size: 18px;
  }

}
@media only screen and (max-width: 560px){
  .about-container{
    width: 100%;
    font-size: 11px;
    color: #121013; 
    text-align: justify;
    background-image: none;
  }

  .about-text-container{
    padding: 20px 18px;
    width: 100%;
    line-height: 1.2em;
  }
  
  .about-paragraph-title{
    font-size: 15px;
    font-weight: bold;
    color: #11B6D7;
    padding-bottom: 5px;
  }
  
}
.tos-container{
  width: 100%;
  font-size: 16px;
  color: #121013; 
  margin: 38px 8px;
  max-width: 800px;
}

.tos-header{
  display: flex;
  justify-content: center;
  color: #11B6D7;
  font-size: 30px;
  font-weight: bold;
}

.tos-sub-header{
  color: #11B6D7;
  font-size: 20px;
  margin: 36px 0 18px 0;
}

.tos-topic-title{
  color: #11B6D7;
  font-size: 16px;
  margin: 14px 0 6px 0;
}

.tos-topic-text{
  font-size: 12px;
  line-height: 1.3em;
  margin-left: 15px;
}

.tos-ul{
  list-style-type: lower-roman;
  margin: 4px 0 10px 25px;
}

.tos-privacy-ul{
  list-style-type: square;
  margin: 4px 0 10px 25px;
}

.tos-contact-us-link{
  color: #11B6D7;
  text-decoration: underline;
}


/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){
 
}
@media only screen and (max-width: 560px){
  .tos-header{
    font-size: 25px;
  }

  .tos-sub-header{
    font-size: 18px;
  }
  
}
.contact-form-page{
  color: #4B4C4D;
  margin-top: 88px;
}

.contact-form{
  width: 445px;
}

.contact-form-title{
  color: #11B6D7;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.contact-form-input, .contact-form-textarea{
  width: 445px;
  border: 0.72px solid #595A5A;
  background-color: #F2F2F2;
  font-size: 18px;
  text-align: center;
  color: #4B4C4D;
}

.contact-form-input{
  height: 48px;
}

.contact-form-textarea{
  height: 327px;
  padding: 20px;
}

.contact-form-input:focus, .contact-form-textarea:focus {
  outline: none; 
  border: 1px solid #11B6D7;
}

.contact-form-submit{
  height: 43px;
  width: 160px;
  background-color: #11B6D7;
  text-align: center;
  margin-top: 34px;
  font-size: 18px;
  font-weight: bold;
  color: #FCFCFC;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  border: none;
}

.contact-form-submit:focus {outline: none;}

.form-success-messgae{
  margin-top: 83px;
  line-height: 1.5em;
  margin-bottom: 65px;
  font-size: 24px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .contact-form-page{
    margin-top: 50px;
  }
  .contact-form{
    width: 221px;
  }
  .contact-form-title{
    font-size: 25px;
  }
  .contact-form-input, .contact-form-textarea{
    width: 221px;
    font-size: 15px;
  }
  .contact-form-input{
    height: 32px;
  }
  .contact-form-textarea{
    height: 128px;
    padding: 20px;
  }
  .contact-form-submit{
    width: 126px;
    margin-top: 34px;
  }
  .form-success-messgae{
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 15px;
  }
}
.search-page-container{
  display: flex;
  font-family: Roboto;
  color: #484848;
}

.results-page-color{
  color: #484848;
}

.search-content-container {
 width: 864px;
 max-width: 864px;
 z-index: 10;
}

.search-mode-toggle{
  margin-left: 120px;
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search-mode-toggle-label{
  color: rgba(0,0,0,0.6);
  font-size: 14px;
}

.search-mode-toggle-label-active{
  color: #11B6D7;
}

.search-input-box{
  display: flex;
  align-items: center;
  height: 48px;
  flex: 1 1;
  border: 1px solid #DEDEDE;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.24);
  margin-right: 40px;
  margin-left: 120px;
  margin-bottom: 16px;
}

.search-input{
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: #FFFFFF;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  border: none transparent;
  outline: none;
  padding: 0 10px;
}

.search-page-icon-cover-up {
  position: absolute;
  background: #ffffff;
  width: 34px;
  height: 41px;
  bottom: -20px;
  right: 1px;
}

.search-page-clear-input-icon{
  color: #A3A3A3;
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
  z-index: 100;
}

.input-search-icon{
  position: absolute;
  right: 10px;
  top: -9px;
  color: #11B6D7;
  z-index: 100;
}

.filters-container{
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  margin-left: 120px;
  background-color: #FFFFFF;
  padding-bottom: 16px;
}

.filter-box{
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 7px 14px;
  border: 1px solid #E4E4E4;
  font-size: 14px;
  color: #484848;
  border-radius: 3px;
  background-color: #FFFFFF;
  cursor: pointer;
  margin-right: 12px;
}

.filter-box-active{
  border: 1px solid #11B6D7;
  background-color: #11B6D7;
  color: #FFFFFF;
  /* font-weight: 500; */
}

.filter{
  position: absolute;
  top: 40px;
  left: -1px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 0 14px 2px rgba(0,0,0,0.12), 0 2px 5px -3px rgba(0,0,0,0.2);
  z-index: 10;
  color: #484848;
  font-size: 14px;
}

.filter-li{
  display: flex;
  align-items: center;
  margin-top: 7px;
  padding-left: 6px;
}

.apply-filter{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.21px;
  color: #11B6D7;
  padding: 12px;
}

.time-filter-actions{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.21px;
  padding: 12px;
}

.tefillah-filter, .nusach-filter{
  padding-top: 5px;
  width: 140px;
}

.distance-filter{
  padding: 16px 0 0 16px;
  width: 220px;
}

.distance-title{
  font-size: 12px;
  padding-bottom: 7px;
}
.distance-slider-div{
  margin-right: 20px;
}

.time-filter{
  padding-top: 12px;
  width: 304px;
}

.time-title{
  padding-left: 12px;
  padding-bottom: 12px;
  font-size: 12px;
  padding-left: 85px
}

.time-pickers{
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 16px;
}

.apply-times,.clear-times{
  cursor: pointer;
  color: #11B6D7;
}

.timeFlex{
  display: flex;
}

.more-filter{
  width: 220px;
}

.more-filter-title{
  padding: 14px 0 1px 12px;
  font-size: 12px;
}

.reset-filters{
  font-size: 12px;
  cursor: pointer;
}

.search-content-separator{
  border-bottom: 1px solid #E4E4E4;
}

.search-week-sponsor{
  min-height: 40px;
  width: 704px;
  margin-left: 120px;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  /* margin-bottom: 8px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  line-height: 12px;
}

.search-dedication-oppor-link {
	height: 24px;
	width: 180px;
  border-radius: 3px;
  color: #FFFFFF;
  background-color: rgba(255,255,255,0.16);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.suggest-update-link{
  margin-top:1em;
  height: 25px;
  width: 175px;
  border: 1px solid #11B6D7;
  color: #11B6D7; 
  border-radius: 12px;  
  font-weight: 500;
}

/*** results ***/

.results-container{
  min-height: 300px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}

.total-results{
  margin-top: 16px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #484848;
  margin-left: 120px;
  letter-spacing: 0.25px;
}

.result-cards{
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 40px;
}

.result-card {
  min-height: 108px;
  width: 704px;
  padding: 16px;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  background-color: #FFFFFF;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.results-shul-info-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 100;
}

.results-shul-name{
  color: #11B6D7;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.results-shul-info{
  display: flex;
  color: #484848;
  font-size: 14px;
  text-transform: capitalize;
  flex-wrap: wrap;
  /* word-wrap: none; */
  line-height: 1.3em;
}

.results-phone{
  color: #484848;
}

.result-card-minyan-message{
  font-size: 14px;
  font-weight: 500;
  padding-top: 8px;
}

.upcoming-minyan{
  color: #1DB864;
}

.no-upcoming-minyan{
  color: #FA5858;
}

.temp-minyan-message{
  color: #FCAC3A;
}

.nusach-action-icons-column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.nusach-temp-minyan-boxes{
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.results-nusach{
  height: 24px;
  width: 88px;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 8px;
}

.results-temp-shul-flag{
  height: 24px;
  width: 90px;
  border-radius: 3px;
  background-color: #FCAC3A;
  color: #FFFFFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 8px;
}

.view-details-icon{
  color: #11B6D7;
  font-size: 16px;
}

.directions-icon{
  color: #11B6D7;
  font-size: 16px;
  padding-left: 16px;
}

.results-view-icon-text, .results-directions-icon-text{
  color: rgba(0,0,0,0.32);
  font-size: 12px;
  font-weight: 500;
  padding-left: 4px;
}

.location-status-warning{
  font-size: 18px;
  color: #FA5858;
  margin-left: 4px;
  display: inline-block;
  vertical-align: top;
  /* align-items: center;
  justify-content: center; */
  cursor: pointer;
}

/*** pagination ***/

.pagination-container {
  color: #484848;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
}

.pagination-circle{
  margin: 4px;
  padding: 4px;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 300ms linear, color 300ms linear;
}

.pagination-circle:hover{
  background-color: rgba(67,66,66,0.08);
}

.pagination-active{
  background-color: #11B6D7;
  color: #ffffff;
}

.pagination-active:hover{
  background-color: #11B6D7;
  color: #ffffff;
}

.pagination-disabled{
  opacity: .3;
}

.map-container{
  display: flex;
  flex: 1 1;
  height: auto;
}

.fixed-map {
  position: fixed;
  right: 0;
  padding-left: 864px;
  width: 100%;
  top: 0;
  height: 100%;
}
.margin-top{
  margin-top: 5.5em;
}

.search-add-minyan{
  height: 30px;
   width: 132px;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 1px solid #11B6D7;
   border-radius: 24px;
   color: #11B6D7;
   font-size: 14px;
   font-weight: 500;
   margin-top: 1em;
 }

/* loading */
.loading-div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-results{
  font-size: 16px;
  margin-top: 60px;

}

.temp-minyan-alert{
  font-size: 11px;
  color: #11B6D7;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-top: 6px;
}

.temp-minyan-alert-icon{
  font-size: 20px;
  padding-right: 5px;
}

.clear-all-filters{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #3A3A3A;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}

/***** DIALOG STYLES *****/
.dialog-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 340px;
  width: 560px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 0 14px 2px rgba(0,0,0,0.12), 0 2px 5px -3px rgba(0,0,0,0.2);
}

.dialog-warning-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 16px;
  font-size: 32px;
  color: #FA5858;
}

.dialog-title{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2A3233;
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 23px;
}

.dialog-text{
  color: #484848;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 24px;
}

.dialog-button-div{
  margin-top: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-cancel-button{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  border-radius: 24px;
  background-color: #E4E4E4;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
  color: #2A3233;
  font-size: 14.31px;
  letter-spacing: 1.25px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 16px;
  cursor: pointer;
}

.dialog-ok-button{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  border-radius: 24px;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 14.31px;
  letter-spacing: 1.25px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}



/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .search-page-container{
    display: unset;
  }

  .map-container{
    height: 400px;
  }

  .search-content-container {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
   }

   .search-mode-toggle{
    margin: 8px 8px;
  }

  .search-input-box{
    height: 40px;
    margin-left: 8px;
    margin-right: 8px;
  }

   .search-input{
    width: 100%;
    height: 34px;
    font-size: 16px;
    padding: 0 5px;
  }

  .search-page-clear-input-icon{
    font-size: 16px;
    top: 10px;
    right: 2px;
    z-index: 99;
  }

  .input-search-icon{
    font-size: 16px;
    top: -6px;
    right: 2px;
    z-index: 100;
  }

  .search-page-icon-cover-up {
    position: absolute;
    background: #ffffff;
    width: 20px;
    height: 32px;
    top: -16px;
    right: 1px;
}

  .mobile-filter-list-map-toggle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 8px 16px 8px;
  }

  .mobile-filter-button{
    height: 32px;
    min-width: 63px;
    margin-right: 8px;
    padding: 0 5px;
    border: 1px solid #E4E4E4;
    border-radius: 3px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,0.75);
    font-size: 14px;
  }

  .mobile-filter-button-active{
    border: 1px solid #11B6D7;
    background-color: #11B6D7;
    color: #FFFFFF;
  }

  .list-map-toggle{
    color: #11B6D7;
    font-size: 12px;
    font-weight: 500;
  }

  .list-map-icons{
    font-size: 20px;
    padding-right: 4px;
  }

  .search-week-sponsor{
    width: unset;
    margin: 8px;
    margin-bottom: 0;
    min-height: 46px;
    font-size: 10px;
    padding: 8px;
  }
  
  .search-dedication-oppor-link {
    height: 30px;
    width: 95px;
    text-align: center;
    line-height: 12px;
  }

  /*** results  ***/

  .total-results{
    font-size: 12px;
    margin-left: 8px;
  }

  .mobile-content-separator{
    border-bottom: 1px solid #E4E4E4;
    /* margin: 12px 0; */
  }

  .result-cards{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }

  .result-card {
    min-height: 90px;
    width: 100%;
    max-width: 100%;
    padding: 12px 8px;
    border: none;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;
  }

  .results-shul-name{
    font-size: 12px;
    padding-bottom: 4px;
  }

  .results-shul-info{
    font-size: 12px;
    flex-direction: column;
    flex-wrap: wrap;
    /* line-height: 1.1em; */
  }

  .mobile-phone-rabbi{
    flex-wrap: wrap;
  }

  .result-card-minyan-message{
    font-size: 12px;
  }

  .nusach-action-icons-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .nusach-temp-minyan-boxes{
    flex-direction: column-reverse;
  }

  .results-nusach{
    height: 18px;
    width: 72px;
    font-size: 10px;
    display: flex;
  }

  .results-temp-shul-flag{
    height: 18px;
    width: 72px;
    font-size: 10px;
    margin-top: 6px;
  }

  .directions-icon{
    padding-left: 0;
  }

.results-view-icon-text{
  font-size: 10px;
  padding-left: 4px;
}

.results-directions-icon-text{
  font-size: 10px;
  padding-left: 10px;
}

.location-status-warning{
  font-size: 14px;
  /* margin-left: 6px; */
}

  .pagination-container {
    margin-top: 10px;
    margin-right: 8px;
  }

  /***** DIALOG STYLES *****/
.dialog-container{
  height: 271px;
  width: 100%;
  max-width: 100%;
  padding: 10px;
}

.dialog-warning-icon{
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 24px;
}

.dialog-title{
  font-size: 16px;
  padding-bottom: 16px;
}

.dialog-text{
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 20px;
}

.dialog-button-div{
  margin-top: 48px;
}

.dialog-cancel-button{
  height: 32px;
  width: 100px;
  font-size: 12px;
  /* margin-right: 16px; */
}

.dialog-ok-button{
  height: 32px;
  width: 100px;
  font-size: 12px;
}

}

.map-window-shul-name{
  color: #11B6D7;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding-bottom: 8px;
}

.map-window-shul-address{
  color: #484848;
  font-size: 12px;
  text-transform: capitalize;
}
@media only screen and (max-width: 560px){
.filter-sidebar-container{
  position: absolute;
  top: 0;
  color: #484848;
  width: 100%;
  max-width: 100%;
  background-color: #FFFFFF;
  z-index: 999;
  /* padding: 0 16px; */
  font-size: 14px;
  letter-spacing: 0.25px;
}

.mobile-filter-line{
  border-bottom: 1px solid #E4E4E4;
}

.filter-sidebar-top{
  padding: 0 16px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-reset-filters{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.21px;
  color: #11B6D7;
}

.sidebar-filter-section{
  padding: 16px 16px 0 16px;
}

.sidebar-filter-section-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 24px;
}

.active-filters{
  margin-bottom: 16px;
  text-transform: capitalize;
}

.sidebar-time-content.sidebar-filter-content{
  padding-left: 0;
}

.sidebar-filter-li{
  margin-bottom: 16px;
}

.radio-checked{
  color: #11B6D7;
  font-size: 23px;
  margin-right: 8px;
}

.radio-unchecked{
  color: #DCDCDC;
  font-size: 23px;
  margin-right: 8px;
}

.checkbox-checked{
  color: #11B6D7;
  font-size: 23px;
}

.checkbox-unchecked{
  color:  #DCDCDC;
  font-size: 23px;
}

.mobile-clear-time{
  margin-bottom: 16px;
  color: #11B6D7;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.21px;
  width: 38px;
}


.sidebar-distance-slider{
  padding: 20px;
}

.mobile-fiter-show-results{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  border-radius: 28px;
  background-color: #11B6D7;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  margin: 16px 16px 30px 16px;
}
}

.shul-details-outer-wrapper{
  margin: 20px 4px 48px 4px;
  font-family: Roboto;
  color:  #484848;
}

.back-to-results{
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #11B6D7;
  font-weight: 500;
}

.shul-details-featured-sponsor{
  color: rgba(0,0,0,0.38);
  font-size: 10px;
  letter-spacing: 1px;
}

.featured-sponsor-img{
  width: 115px;
  height: auto;
  padding-bottom: 5px;
}

.last-updated{
  color: rgba(0,0,0,0.38);
  font-size: 12px;
  flex-shrink: 0;
  margin-left: 8px;
}

.shul-details-container{
  width: 940px;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  color:  #484848;
}

.shul-details-color{
  color: #484848;
}

.shul-details-warning{
  min-height: 20px;
  width: 100%;
  border-radius: 3px;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 8px;
  text-align: center;
  padding: 14px;
}

.shul-name-contacts-box {
  width: 100%;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.shul-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* display: grid;
  grid-template-columns: 1fr .5fr; */
  min-height: 90px;
}

.shul-details-info-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 19px;
}

.shul-name {
  font-size: 24px;
  font-weight: 500;
  color: #11B6D7;
  padding-bottom: 8px;
}

.shul-details-temp-info{
  display: flex;
  color: #484848;
  font-size: 12px;
  font-weight: bold;
  margin-top: 16px;
}

.shul-details-other-info-column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shul-details-nusach-temp-div{
  display: flex;
  justify-content: flex-end;
}

.shul-details-nusach{
  height: 26px;
  width: 104px;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 8px;
}

.shul-details-temp-minyan-flag{
  height: 26px;
  width: 104px;
  border-radius: 3px;
  background-color: #FCAC3A;
  color: #FFFFFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 8px;
}

.shul-details-temp-dates{
  display: flex;
  justify-content: flex-end;
  color: #FCAC3A;
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
}

.shul-details-icons-div{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 10px;
}

.shul-details-icons{
  font-size: 24px;
  color: #11B6D7;
  cursor: pointer;
}

.shul-details-icons-text{
  font-weight: 500;
  font-size: 14px;
  color: #11B6D7;
  cursor: pointer;
}

.shul-contacts-box{
  width: 100%;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #F2F2F2;
  font-size: 16px;
  line-height: 1.25em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  letter-spacing: .2px;

}
.shul-contacts-box-row{
  font-size: 12px;
  color: #484848;
  width: 100%;
  min-height: 28px;
  display: grid;
  grid-template-columns: 186px 95px 186px 186px;
  grid-column-gap: 5px;
}

.shul-details-week-sponsor{
  min-height: 40px;
  width: 100%;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  margin-top: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  line-height: 12px;
}

.dedication-oppor-link {
	height: 24px;
	width: 180px;
  border-radius: 3px;
  color: #FFFFFF;
  background-color: rgba(255,255,255,0.16);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.minyan-table-legend{
  /* margin-top: 16px; */
  margin-bottom: 28px;
  height: 40px;
  width: 100%;
  background-color: #F2F2F2;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.87);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
}


.reg-minyanim{
  height: 31px;
  width: 115px;
  color: #484848;
  font-size: 14px;
  font-weight: bold;
  margin-right: 40px;
  cursor: pointer;
}

.reg-minyanim-active{
  border-bottom: 2px solid #11B6D7;
  color: #11B6D7;
}

.special-minyanim{
  height: 31px;
  width: 88px;
  color: #484848;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 40px;
}

.events-tab{
  height: 31px;
  width: 106px;
  color: #484848;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.events-tab-active{
  border-bottom: 2px solid #11B6D7;
  color: #11B6D7;
}

.events-cards-container{
  display: flex;
  flex-wrap: wrap;
}

.event-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 210px;
  min-height: 150px;
  width: 296px;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  padding: 12px;
  margin: 8px;
  margin-top: 25px;
}

.event-card-lang {
  background-color: #E8E8E8;
  color: #000000;
  padding: 10px 30px;
}

.capitalize-text.event-type-name {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 700;
}

.special-minyanim-active{
  border-bottom: 2px solid #11B6D7;
  color: #11B6D7;
}

.special-days-text{
  color: rgba(0,0,0,0.6);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 18px;
}

.minyanim-box {
	width: 100%;
}

.minyanim-header{
  height: 69px;
  background-color: #11B6D7;
  color: #ffffff;
  width: 940px;
  display: grid;
  font-size: 20px;
  grid-template-columns: .7fr 1fr 1fr 1fr;
  -ms-grid-columns: .7fr 1fr 1fr 1fr;
  margin-top: 20px;
}

.minyan-row{
  min-height: 60px;
  display: grid;
  border-bottom: 1px solid #E4E4E4;
  grid-template-columns: .7fr 1fr 1fr 1fr;
  -ms-grid-columns: .7fr 1fr 1fr 1fr;
}

.minyan-day-name{
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 48px;
}

.minyan-times{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-wrap: wrap;
  /* padding: 2px 24px; */
}

.minyan-time-column{
  max-width: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.minyan-times .minyan-time-column > div:not(:last-child) > div:after { content: ","; position: absolute; }

.minyan-time-display{
  padding: 5px;
}

.minyan-time-display:focus{
  outline: none;
}


.greyed-out-time{
  opacity: .7;
}

.clickable-time{
  color: #11B6D7;
}

.expanded-time-display-container{
  position: relative;
}


.expanded-time-display{
  width: 300px;
  border: 2px solid #11B6D7;
  background-color: #FFFFFF;
  box-shadow: -1px 1px 4px 2px rgba(0,0,0,0.3);
  position: absolute;
  padding: 0 7px;
  top: -1px;
  z-index: 2;
  border-radius: 5px;
}

.schacharis-popup{
  left: 0;
}

.mincha-popup{
  right: -131px;
}

.maariv-popup{
  right: 0;
}

.expanded-time-row{
  min-height: 25px;
  display: grid;
  padding: 2px 0;
  grid-template-columns: .4fr 1fr;
  -ms-grid-columns: .4fr 1fr;
  font-size: 11px;
  border-bottom: 1px solid rgba(163, 227, 239, 0.43);
}

.expanded-time-row-name{
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  flex-wrap: wrap;
  text-align: left;
}

.expanded-time-row-info{
  text-align: left;
}

.notes-box{
  margin-top: 15px;
}

.notes-header{
  color: #11B6D7;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 8px;
}

.notes-text{
  color: #484848;
  font-size: 16px;
  line-height: 18px;
}

.shiurim-link-div{
  padding-top: 20px;
}

.shiurim-link{
  color: #11B6D7;
  font-size: 16px;
  text-decoration: underline;
}

.shul-details-link{
  height: 40px;
  width: 208px;
  border: 1px solid #11B6D7;
  color: #11B6D7;
  font-size: 16px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
}


/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .minyan-day-name{
    padding-left: 30px;
  }
}
@media only screen and (max-width: 560px){
  .shul-details-outer-wrapper{
    margin: 16px 4px 30px 4px;
  }

  .shul-details-container{
    width: 96%;
  }

  .last-updated{
    font-size: 10px;
  }

  .shul-details-warning{
    width: 100%;
    font-size: 12px;
    text-align: center;
    padding: 10px;
    line-height: 14px;
  }

  .shul-name-contacts-box {
    padding: 8px;
  }

  .shul-box{
    grid-template-columns: 1fr .5fr;
    min-height: 90px;
  }

  .shul-details-info-column{
    font-size: 12px;
    line-height: 16px;
    flex-wrap: wrap;
  }

  .shul-name {
    font-size: 14px;
  }

  .shul-details-temp-info{
    flex-direction: column;
    font-size: 12px;
    margin-top: 8px;
  }

  .shul-details-other-info-column{
    display: flex;
    flex-direction: column;
  }

  .shul-details-nusach-temp-div{
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .shul-details-nusach{
    height: 18px;
    width: 72px;
    font-size: 10px;
    margin-bottom: 6px;
  }

  .shul-details-temp-minyan-flag{
    height: 18px;
    width: 72px;
    font-size: 10px;
  }

  .shul-details-icons{
    font-size: 18px;
  }

  .shul-details-icons-text{

    font-size: 12px;

  }

  .shul-contacts-box{
    width: 100%;
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #F2F2F2;
    font-size: 16px;
    line-height: 1.25em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    letter-spacing: .2px;

  }
  .shul-contacts-box-row{
    font-size: 12px;
    color: #484848;
    width: 100%;
    min-height: 28px;
    display: flex;
    flex-direction: column;
    grid-template-columns: 186px 95px 186px 186px;
    grid-column-gap: 5px;
    padding-bottom: 10px;
    line-height: normal;
  }

  .shul-details-week-sponsor{
    min-height: 46px;
    font-size: 10px;
    padding: 8px;
  }

  .dedication-oppor-link {
    height: 30px;
    width: 95px;
    text-align: center;
    line-height: 12px;
  }

  .minyanim-header{
    height: 50px;
    width: 100%;
    font-size: 12px;
  }

  .minyan-day-name {
    font-size: 11px;
    padding-left: 12px;
  }

  .minyan-row{
    min-height: 45px;
  }

  .minyan-time-column {
    padding: 0 2px;
}

  .minyan-table-legend {
    height: 46px;
    font-size: 12px;
    line-height: 15px;
  }

  .expanded-time-display{
    width: 189px;
    top: -1px;
  }

  .schacharis-popup{
    left: 0;
  }

  .mincha-popup{
    left: inherit;
    right: -88px !important;
  }

  .maariv-popup{
    left: inherit;
    right: 0 !important;
  }

  .expanded-time-row{
    min-height: 20px;
    font-size: 8px;
  }

  .notes-header{
    text-align: left;
    font-size: 18px;
  }

  .shiurim-link {
    color: #11B6D7;
    font-size: 12px;
    text-decoration: underline;
}

  .shul-details-link {
    height: 40px;
    width: 100%;
    font-size: 12px;
    margin: 8px 16px;
  }
}

.image-carousel{
	width: 600px;
  height: 400px;
  top: 325px;
	max-width: 100%;
	max-height: 100vh;
  margin: 0 auto;
  position: absolute;
  z-index: 100000;
  outline: none
}
.carousel {
	width: 100%;
	height: 100%;
	position: relative;
  overflow: hidden;
  z-index: 1000000;
}

.carousel .next,
.carousel .prev {
	width: 50px;
	height: 50px;
	line-height: 50px;
	font-size: 20px;
	text-align: center;
	position: absolute;
	top: calc(50% - 50px - 25px);
	z-index: 3;
	color: #fff;
	background-color: #000;
	opacity: .7;
	border-radius: 50%;
	cursor: pointer
}

.carousel .next:hover,
.carousel .prev:hover {
	background-color: #fff;
	color: #000
}

.carousel .prev {
	left: -25px
}

.carousel .prev:before {
	content: "<";
	position: absolute;
	right: 12px
}

.carousel .next {
	right: -25px
}

.carousel .next:before {
	content: ">";
	position: absolute;
	left: 12px
}

.carousel .carousel-main {
	width: 100%;
	height: calc(100% - 100px);
	background-color: #000;
	overflow: hidden
}

.carousel .carousel-main .carouselContent-enter {
	opacity: .01
}

.carousel .carousel-main .carouselContent-enter.carouselContent-enter-active {
	opacity: 1;
	transition: opacity .5s ease-in
}

.carousel .carousel-main .carouselContent-exit {
	opacity: 1
}

.carousel .carousel-main .carouselContent-exit.carouselContent-exit-active {
	opacity: .01;
	transition: opacity .5s ease-in
}

.carousel .carousel-main .carouselContent-appear {
	opacity: .01
}

.carousel .carousel-main .carouselContent-appear.carouselContent-appear-active {
	opacity: 1;
	transition: opacity .5s ease-in
}

.carousel .carousel-main div {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: block;
	position: relative;
}

.carousel .carousel-main img {
	position: absolute;
	top: -9999px;
	right: -9999px;
	bottom: -9999px;
	left: -9999px;
	margin: auto;
  max-width: 100%;
  max-height: 100%;
	z-index: 0
}

.carousel .carousel-footer {
	height: 100px;
	background-color: #ddd;
	display: flex;
	align-items: center;
	overflow: auto;
	margin: 0 auto
}

.carousel .carousel-footer .box {
	margin: 0 auto;
	display: flex
}

.carousel .carousel-footer .box .carousel-dot {
	width: 10px;
	height: 10px;
	background-color: #000;
	border-radius: 50%;
	margin: 5px;
	cursor: pointer
}

.carousel .carousel-footer .box .carousel-thumb {
	width: 120px;
	height: 80px;
	background-size: contain;
	background-position: 50%;
	background-repeat: no-repeat;
	margin: 5px;
	cursor: pointer
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .image-carousel {
    width: 100%;
    padding: 0 11px;
    height: 275px;
    max-width: 100%;
    max-height: 90vh;
    margin: 0 auto;
    position: absolute;
    z-index: 100000;
    top: 265px;
}
  /* .carousel .carousel-main img {
    min-width: 100%;
    min-height: 100%;
    width: 300px;
    height: 250px;
    z-index: 0
  } */
  .carousel .carousel-footer {
    height: 60px;
  }
  .carousel .carousel-footer .box .carousel-thumb {
    width: 50px;
    height: 36px;
  }
}



.client-add-shul-page{
    color: #4B4C4D;
}

.client-add-shul-container{
    width: 565px;
}

.client-add-shul-title{
    position: absolute;
    left: -260px;
    font-size: 36px;
    font-weight: bold;
}

.client-input-color{
    color: #898989;
}

.client-shul-info {
	border: 1px solid #898989;
    border-radius: 10px;
    padding: 20px 30px;
    margin-bottom: 32px;
    margin-top: 32px;
}

.client-shul-title{
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 25px;
    text-transform: uppercase;
}

.client-shul-label{
    width: 125px;
    font-size: 18px;
}

.client-add-shul-address-input{
    flex: 1 1 !important;
    color: #898989 !important;
    font-size: 18px !important;
    width: 100% !important;
}

.custom-checkbox-square{
    position: absolute;
    /* top: 1px; */
    right: 153px;
    height: 12px;
    width: 12px;
    border: 1.5px solid #535353;
    background-color: #E1E1E1;
    cursor: pointer;
  }

  .temp-radio-square{
    position: absolute;
    left: 20px;
    height: 12px;
    width: 12px;
    border: 1.5px solid #535353;
    background-color: #E1E1E1;
    cursor: pointer;
  }

.temp-minyan-checkbox-label{
    position: relative;
}

.temp-minyan-radio-label{
    padding-left: 40px;
    position: relative;
    margin-bottom: 13px;
}

.temp-minyan-checkbox-label input[type=checkbox],
.temp-minyan-radio-label input[type=radio]{
    position: absolute;
    opacity: 0;
}

.temp-minyan-checkbox-label input[type=checkbox]:checked ~ .custom-checkbox-square,
.temp-minyan-radio-label input[type=radio]:checked ~ .temp-radio-square{
    background-color: #11B6D7;
}

.temp-minyan-text{
    line-height: 20px;
}


.client-contact-info{
	border: 1px solid #898989;
    border-radius: 10px;
    padding: 20px 30px;
    margin-bottom:16px;
}

.client-add-shul-submit{
    height: 45px;
    width: 274px;
    background-color: #11B6D7;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 32px;
    cursor: pointer;
    border: none;
}

.client-add-shul-submit:focus {
  outline: none;
}

.client-add-shul-success{
    margin-top: 150px;
}
.client-add-shul-home{
  margin-top: 36px;
  margin-bottom: 136px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){
   
  }
  @media only screen and (min-width: 561px) and (max-width: 1024px){
   
  }
  @media only screen and (max-width: 560px){
    .client-shul-info {
        margin-bottom: 12px;
        margin-top: 0;
    }
    .client-add-shul-title{
        display: flex;
        justify-content: center;
        align-items: center;
        position: inherit;
        left: 0;
        top: 0;
        font-size: 20px;
        padding: 20px;
    }
    .client-shul-label{
        width: 96px;
        
    }
    .mobile-client-input{
        height: 20px !important;
        font-size: 10px !important;
        padding: 0 10px !important;
        margin: 0 !important;
    }
    .client-add-shul-address-input{
        min-height: 20px !important;
        max-height: 20px !important;
        font-size: 10px !important;
        padding: 0 10px !important;
        margin: 0 !important;
    }
    .temp-minyan-checkbox-label{
        padding-right: 0;
    }
    .custom-checkbox-square{
        position: absolute;
        top: -1px;
        right: 107px;
        height: 9px;
        width: 9px;
        border: 1.5px solid #707070;
      }
      .temp-minyan-text{
        padding-right: 0; 
        line-height: 20px;
    }
    .client-add-shul-submit{
        height: 29px;
        width: 169px;
        font-size: 12px;
        margin-bottom: 16px;
    }
    .client-add-shul-success{
        margin-top: 60px;
    }
    .client-add-shul-home{
      margin-top: 36px;
      margin-bottom: 60px;
    }
  
  }

  
  
.contact-on-multiple-shuls{
    margin-bottom: 20px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    font-size: 14px;
    color: #EF6A6E;
}

.contact-on-multiple-shuls-warning-icon{
    font-size: 18px;
    display: flex;
    align-items: center; 
    margin-right: 6px;  
}

.phone-input-div, .email-input-div{
    margin-top: 20px;    
}

#phone-number-input{
    width: 235px;
    margin-right: 15px;
}

#phone-ext-input {
    width: 65px;
    margin-right: 15px;
}

#email-input{
    width: 315px;
    margin-right: 15px;
}

.delete-phone-email-icon{
  color: #DCDCDC; 
  cursor: pointer;
  padding-left: 13px;
}

.delete-phone-email-icon:hover{
  color: #23D7F2
}

.phone-email-add{
    color: #23D7F2;
    padding-left: 148px;
    padding-top: 15px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.08px;
    position: relative;
}

.plus-icon{
    position: absolute;
    bottom: 1.5px;
    left: 135px;
    font-size: 14px;
}

.contact-buttons-div{
    display: flex;
    height: 40px;
    justify-content: flex-end;  
}

.contact-button{
    margin-left: 15px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
    .contact-on-multiple-shuls{
        font-size: 11px;
    } 

    .plus-icon{
        position: absolute;
        bottom: 1.5px;
        left: 0;
        font-size: 14px;
    }
    .phone-email-add{
        padding-left: 12px;
        font-size: 12px;
    }
    .phone-input-div, .email-input-div{
        margin-top: 10px;    
    }
    #phone-number-input{
        width: 100%;
        margin-right: 8px;
    }
    #phone-ext-input {
        width: 55px;
        margin-right: 15px;
    }
    #email-input{
        width: 100%;
        margin-right: 15px;
    }

    .contact-buttons-div{
       margin-bottom: 40px;
    }
    
    
}
.location-warning-dialog{
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
}

.location-warning-icon{
  font-size: 60px;
  margin-top: 30px;
  margin-bottom: 24px;
  color: #EF6A6E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-warning-title{
  text-align: center;
  margin-bottom: 40px; 
}
.location-warning-title h4{
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  margin: 10px;
}

.location-warning-shul-names{
  font-size: 15px;
  margin-bottom: 12px;
  line-height: 1.5;
  text-transform: capitalize;
}




@media only screen and (max-width: 560px){
  .location-warning-dialog{
    width: 260px;
    margin-bottom: 26px;
  }
}

.temp-shuls-page{
  min-height: calc(100vh - 390px);
  margin: 0 8px;
}

.temp-shuls-header{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 25px;
  color: #484848;
  font-size: 24px;
  font-weight: 500;
}

.temp-shuls-container{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1234px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.temp-shul-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 210px;
  max-width: 380px;
  min-height: 210px;
  min-width: 380px;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: 16px;
  margin: 15px;
}

.temp-shul-name{
  text-transform: capitalize;
  color: #11B6D7;
  font-size: 18px;
  font-weight: 500;
  max-width: 235px;
  display: flex;
  flex-wrap: wrap;
}

.temp-shul-nusach{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 104px;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 14px;
  text-transform: capitalize;
}

.minyan-torah-message{
  color: rgba(0,0,0,0.32);
}

.temp-shul-info{
  /* margin-top: 20px; */
  font-size: 14px;
  line-height: 20px;
  color: #484848;
}

.temp-shul-dates{
  color: #FCAC3A;
  font-size: 16px;
  font-weight: 500;
}

.no-temp-minyanim-message{
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin: 25px;
  text-align: center;
}

@media only screen and (max-width: 560px){
  .temp-shuls-header{
    padding: 24px 0;
    font-size: 20px;
  }
  
  .temp-shuls-container{
    max-width: 100%;
    margin-bottom: 10px;
  }
  
  .temp-shul-card{
    max-height: auto;
    max-width: 100%;
    min-height: 154px;
    min-width: 100%;
    padding: 8px;
    margin: 0 0 16px 0;
  }
  
  .temp-shul-name{
    font-size: 16px;
    font-weight: 500;
    /* max-width: 235px; */
  }
  
  .temp-shul-nusach{
    height: 18px;
    width: 72px;
    font-size: 10px;
  }
  
  .temp-shul-info{
    font-size: 12px;
    line-height: 20px;
  }
  
  .temp-shul-dates{
    font-size: 12px;
  }

}
/***** ADD SHUL STYLES *****/
/* a {color: #fff;} */
.add-edit-shul-outer-wraper {
  margin-left: 80px;
  padding: 40px;
}

.add-shul {
  padding-top: 24px;
}
.material-icons a {
  color: #000;
}
/*** Shul Info Styles ***/
.edit-shul-name-header {
  text-transform: capitalize;
}
.temp-minyan-box {
  height: 16px;
  width: 64.39px;
  color: #2a3233;
  font-size: 10px;
  background-color: #eeeeee;
  margin: 0 10px;
  flex-shrink: 0;
}
.shul-info-card .flex-align-center {
  margin-bottom: 16px;
}
.shul-info-card > .flex-align-center > *:first-child {
  width: 112px;
}
.shul-info-card .shul-update-message {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 352px;
  max-width: 100%;
}
.shul-info-card .success-message {
  background-color: rgba(23, 205, 144, 0.12);
  color: #17cd90;
}
.shul-info-card .error-message {
  background-color: red;
  color: white;
}
.custom-input {
  width: 352px;
  max-width: 100%;
}

.temp-shul-dates-div {
  flex-wrap: wrap;
}

.edit-shul-date-picker {
  min-width: 132px !important;
  max-width: 132px !important;
  display: flex;
}

.edit-shul-start-date-div {
  padding-right: 25px;
}

.edit-shul-start-date {
  width: 112px !important;
}

.edit-shul-end-date {
  padding-right: 5px;
}

.has-minyan-checkbox {
  color: #2a3233;
  font-size: 16px;
  padding-right: 4px;
}

.delete-shul-div {
  color: #ef6a6e;
  width: 104px;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.96px;
  margin-top: 24px;
}

.delete-shul-icon {
  padding-right: 4px;
  font-size: 14px;
  color: #ef6a6e;
  cursor: pointer;
}

/*** Table Styles ***/
.table-row {
  display: grid;
  grid-column-gap: 16px;
  border-bottom: 1px solid rgba(42, 50, 51, 0.12);
  padding: 6px;
  transition: background 300ms linear;
}
.table-row:first-child {
  border-top: 1px solid rgba(42, 50, 51, 0.12);
}
.minyanim-table .table-row {
  grid-template-columns: 1fr 1fr 2fr 2fr 1.5fr 2.5fr 72px;
  min-height: 53px;
}
.events-table .table-row {
  grid-template-columns: 1.5fr 1.5fr 1.5fr 0.7fr 2fr 2fr 72px;
  min-height: 53px;
  align-items: center;
}
.contacts-table .table-row {
  grid-template-columns: 1.25fr 1fr 1.5fr 2fr 72px;
  min-height: 53px;
}
.admins-table .table-row {
  grid-template-columns: 1.25fr 1fr 72px;
  min-height: 53px;
}
.notes-text-area {
  min-height: 80px;
  font-size: 12px;
  width: 100%;
}
.shul-info-table .table-row:hover {
  background: rgba(42, 50, 51, 0.02);
}
.shul-info-table .table-header-row:hover {
  background: #fff;
}
/* .shul-info-table-actions {text-align: right; opacity: 0; visibility: hidden; transition: opacity 300ms linear; font-size: 18px;} */
/* .shul-info-table .table-row:hover .shul-info-table-actions {opacity: 1; visibility: visible;} */
.shul-info-table-actions i {
  font-size: 18px;
  color: #dcdcdc;
  /* transition: opacity 300ms linear;  */
  cursor: pointer;
  margin-left: 4px;
}
.shul-info-table-actions i:hover {
  color: #23d7f2;
}

/* Contacts Table Styles */

.phone-email-column i {
  margin-right: 4px;
  font-size: 14px;
  margin-bottom: 2px;
}
.phone-email-column {
  line-height: 1.5em;
}
.locked-icon {
  color: #ef6a6e;
}
.unlocked-icon {
  color: #17cd90;
}
.edit-icon {
  color: #2a3233;
}

/***** ADD / EDIT MINYAN/CONTACTS MODALS STYLES *****/
.modal-content {
  max-width: 680px;
  margin: 0 auto;
  padding-top: 40px;
  position: relative; /* TODO:Can this be done with flex */
}

.close-modal {
  height: 56px;
  width: 56px;
  border-radius: 28px;
  background-color: rgba(42, 50, 51, 0.08);
  position: absolute;
  right: 20px;
  top: 20px;
}

/*** Table Styles ***/
.edit-modal .table-row {
  display: grid;
  grid-column-gap: 16px;
  padding: 16px 8px;
  grid-template-columns: 130px auto;
  border: none;
  align-items: center;
}

.table-row > select,
.table-row > input {
  width: 350px;
  height: 32px;
  border: 1px solid rgba(28, 60, 83, 0.16);
  border-radius: 4px;
  background: white;
}

.button-container {
  display: flex;
  height: 40px;
  width: 620px;
  position: absolute;
  right: 60;
  justify-content: space-between;
}

.edit-modal .btn {
  width: 120px;
}
.btn.save-and-add {
  width: 260px;
}

.accent-text-secondary {
  margin-top: 15px;
}

.admin-contact-us-inputs {
  max-width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.admin-contact-us-input {
  max-width: 100%;
  flex: 1 1;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px) {
}
@media only screen and (min-width: 561px) and (max-width: 1024px) {
}
@media only screen and (max-width: 560px) {
  .add-edit-shul-outer-wraper {
    margin-left: 0;
    padding: 0;
  }
  header.header-fixed {
    position: relative;
    width: 100%;
    border-bottom: none !important;
  }

  /* doesn't seem right */
  /* .shul-info-card .flex-align-center {
    margin-bottom: 32px;
  } */

  .shul-info-card .shul-update-message {
    width: 270px;
  }
  .edit-shul-start-date {
    width: 80px !important;
    /* padding-right: 10px; */
  }
  .edit-shul-end-date {
    width: 80px !important;
    padding-left: 0;
    /* padding-right: 10px; */
  }
  .edit-shul-date-picker {
    margin: 0 !important;
  }
  .mobile-back-arrow,
  .edit-icon {
    padding-right: 12px;
    color: #2a3233;
  }
  .edit-icon {
    padding-top: 6px;
  }
  .temp-minyan-box {
    margin-left: 0;
  }
  .contacts-table,
  .minyanim-table,
  .admins-table {
    border-top: 1px solid rgba(42, 50, 51, 0.12);
  }
  .minyanim-table .table-row,
  .contacts-table .table-row,
  .admins-table .table-row {
    display: grid;
    line-height: 1.4em;
    grid-template-columns: 100%;
    padding: 0px;
    margin-top: 8px;
    padding-bottom: 8px;
  }
  .minyanim-table p {
    font-size: 10px;
  }
  .minyan-days {
    padding-bottom: 6px;
    line-height: 0.5em;
  }
  .minyanim-table .minyan-type,
  .contacts-table .contact-name {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .mobile-minyan-notes {
    line-height: 1em;
  }
  .add-shul {
    padding-top: 10px;
  }

  .admin-contact-us-inputs {
    margin-bottom: 10px;
  }
}

.suggest-minyanim-wraper{
  padding: 40px;
}

.suggest-minyanim-header{
  position: sticky;
  border-bottom: 1px solid #e9e9e9;
  height: 80px;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  right: 0;
}

.back-to-shul-details{
  color: #23D7F2;
  margin-right: 20px;
}

.back-to-shul-details-icon{
  font-size: 28px;
}

.suggest-minyan-shul-name{
  font-size: 24px;
  text-transform: capitalize;
}

.suggest-minyanim-content{
  padding-bottom: 40px;
  /* padding-top: 40px; */
}

.minyanim-table-header{
  border-top: 1px solid rgba(42,50,51,0.12);
}

.current-minyan-row{
  display: grid;
  grid-column-gap: 16px;
  border-bottom: 1px solid rgba(42,50,51,0.12);
  padding: 6px;
  transition: background 300ms linear;
  grid-template-columns: .7fr 1fr 2fr 2fr 1.5fr 2.5fr 72px;
  min-height: 53px;
}

.suggested-minyan-row{
  display: grid;
  grid-column-gap: 16px;
  border-bottom: 1px solid rgba(42,50,51,0.12);
  padding: 6px;
  transition: background 300ms linear;
  grid-template-columns: 72px 100px 1fr 1.7fr 1.5fr 1fr 2.2fr;
  min-height: 53px;
}

.delete-warning-dialog{
  /* height: 364px; */
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px;
}

.delete-waning-icon{
  margin-top: 38px;
  margin-bottom: 24px;
  height: 92px;
  width: 92px;
  border: 4px solid #EF6A6E;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-waning-icon-x{
  font-size: 40px;
  color: #EF6A6E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.are-you-sure{
  font-size: 24.53px;
  margin-bottom: 12px;
}

.delete-warning-message{
  color: rgba(0,0,0,0.38);
  font-size: 14.31px;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin-bottom: 40px;
}

.delete-warning-cancel{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  background-color: #EEEEEE;
  color: #2A3233;
  font-size: 14.31px;
  font-weight: 500;
  line-height: 16px;
  margin-right: 16px;
  cursor: pointer;
}

.delete-warning-delete{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  color: #FFFFFF;
  background-color: #EF6A6E;
  font-size: 14.31px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

@media only screen and (max-width: 560px){
  .suggest-minyanim-header{
    height: 60px;
    padding: 10px;
  }

  .back-to-shul-details {
    margin-right: 10px;
}

  .suggest-minyan-shul-name{
    font-size: 14px;
    line-height: 1.2em;
  }

  .current-minyan-row, .suggested-minyan-row{
    display: grid;
    grid-template-columns: 100%;
    line-height: 1.2em;
  }

  .delete-warning-dialog{
    width: 260px;
    margin-bottom: 26px;
  }
  
  .delete-waning-icon{
    margin-top: 22px;
    margin-bottom: 16px;
    height: 65px;
    width: 65px;
    border: 2.8px solid #EF6A6E;
  }
  
  .delete-waning-icon-x{
    font-size: 28px;
  }
  
  .delete-warning-message{
    padding: 0 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 100%;
  }
  
  .delete-warning-cancel, .delete-warning-delete{
    height: 29px;
    width: 88px;
    font-size: 10px;
  }

}

.minyan-input{
  height: 32px;
  padding-left: 10px;
  font-size: 13px;
  border-radius: 4px;
  color: rgba(0,0,0,0.87);
  border: 1px solid rgba(28,60,83,0.16);
  background-color: #ffffff;
  cursor: pointer;
}

.standard-minyan-input{
  width: 209px;
}

.minyan-minutes-imput{
  width: 78px;
}

.minyan-time-input{
  width: 130px;
}

.minyan-offset-input{
  width: 166px;
}
/* .minyan-half-width-input{
  width: 252px;
} */

.minyan-date-input{
  width: 183.7px;
}

.before-after-label {
  display: inline-block;
  min-width: 86px;
  margin-left: 32px;
}

.minyan-time-input:focus, .minyan-input:focus, .minyan-minutes-imput:focus {
  outline: none;
  border: 1px solid #23D7F2;
}

.winter-summer{
  display: flex;
  height: 35px;
  width: 100px;
  border: 1px solid rgba(28,60,83,0.16);
  cursor: pointer;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .mobile-minyan-button{
    padding: 2px !important;
  }
  .minyan-input{
    /* height: 32px;
    width: 160px;
    padding: 5px; */
    font-size: 10px;
  }

  .mobile-temp-label{
    display: inline-block;
    min-width: 65px;
  }

}
/***** DASHBOARD STYLES *****/
.dashboard-outer-wraper{
  margin-left: 80px;
  padding: 40px;
}
.dashboard-content {
  width: calc(100% - 352px);
}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .dashboard-content {width: calc(100% - 224px);}
}

/*** Searchbox Styles ***/
.dashboard-search-types-dropdown{
  height: 40px;
  width: 130px;
  border: 1px solid rgba(28,60,83,0.16);
  border-radius: 4px 0 0 4px;
  background-color: #F5F5F5;
  color: #2A3233;
  font-size: 14.31px;
  letter-spacing: 0.25px;
  border-right: none;
}

.dashboard-search-types-dropdown:focus{
  outline: none;
  border: 1px solid #23D7F2;
}
.dashboard-searchbox{
  height: 40px;
  width: 370px; 
  max-width: 100%;
  border-radius: 0 4px 4px 0;
  border: 1px solid rgba(28,60,83,0.16);
  padding-right: 16px;
}

.small-search-icon{
  color: #23D7F2;
}

/*** Table Styles ***/
.dashboard-table {margin-top: 48px;}
.dashboard-table .table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr .5fr;
  padding: 16px 0;
  border-bottom: solid 1px rgba(42,50,51,0.12);
  grid-column-gap: 16px;
}
.dashboard-table .table-row a {
  margin-right: 24px;
  text-transform: uppercase;
  color: #23D7F2;
  text-decoration: none;
  font-weight: 500;
  transition: opacity 300ms linear;
}
.dashboard-table .table-row a:hover {
  opacity: .5;
}
@media only screen and (max-width: 560px){
  .dashboard-table .table-row {grid-template-columns: 1fr 96px;}
  .dashboard-table .table-row > *:nth-child(2) {display: none;} 
  .dashboard-table .table-row > *:nth-child(1) {order: 1;}
  .dashboard-table .table-row > *:nth-child(3) {order: 3;}
  .dashboard-table .table-row > *:nth-child(4) {order: 2;}
  .dashboard-table .table-row > .bold-text {margin-bottom: 8px;}
}

/*** Pagination Styles ***/
.pagination {display: flex; align-items: center; justify-content: flex-end; margin-top: 32px;}
.pagination li {
    margin: 4px;
    padding: 4px;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 300ms linear, color 300ms linear;
}
.pagination li.active {background: #23D7F2; color: #fff;}
.pagination-arrow i {vertical-align: middle; font-size: 16px;}
.pagination-arrow:first-child {margin-right: 8px; margin-left: 0;}
.pagination-arrow:last-child {margin-left: 8px; margin-right: 0;}

/*** Empty State Styles ***/
.dashboard-empty-state {
  margin-top: 100px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dashboard-empty-state i {color: #e9e9e9; font-size: 80px; margin-bottom: 16px;}
.dashboard-empty-state p {color: rgba(0, 0, 0, .38); font-size: 16px;}

/*** Table Loader Styles ***/
.dashboard-table-loader {height: calc(100vh - 280px); display: flex; justify-content: center; align-items: center;}
.dashboard-table-loader svg {height: 80px;}

/*** Pending Approval Sidebar Styles ***/
.pending-approval-panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  overflow: auto;
  width: 352px;
  border: 1px solid #E9E9E9;
}
.pending-approval-header, .find-a-shul-header {
  background-color: #FAFAFA;
  text-transform: uppercase;
  height: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 8px;
}
.pending-approval-list-item {
  padding: 16px 24px;
  border-bottom: 1px solid #e9e9e9;
}
.pending-approval-actions i:hover {
  transition: opacity 300ms linear;
  cursor: pointer;
  opacity: .5;
}
.pending-approval-open-icon{
  color: #2A3233;
}
.adm-buttons {
  width: 350px;
  display: flex;
  justify-content: space-between;
}
/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){
 
}
@media only screen and (min-width: 561px) and (max-width: 1024px){
  .pending-approval-panel {width: 224px;}
}
@media only screen and (max-width: 560px){
  .dashboard-outer-wraper{
    margin-left: 0;
    padding: 0;
  }

  .content header {
    display: flex;
    height: 30px;
    margin: 16px;
    justify-content: space-between;
  }
  .dashboard-content .flex-justify-content {
    display: block;
  }
  .dashboard-content {
    width: 100%;
  }
  .dashboard-left-header {
    width: 160px;
    display: flex;
    justify-content: space-between;
  }
  .dashboard-search-types-dropdown{
    height: 30px;
    width: 65px;
    font-size: 10px;
    letter-spacing: 0.25px;
    margin-left: 16px;
  }
  
  .dashboard-searchbox{
    height: 30px;
    width: 250px;
    padding-right: 10px;
  }
  .dashboard-empty-state {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .pending-approval-panel {
    position: relative;
    min-width: 100%;
  }
  
  .dashboard-table {
    margin: 16px;
  }
  table .table-row {
    grid-template-columns: 2.5fr 1fr 2.5fr .5fr .5fr;
    padding: 8px 0;
    grid-column-gap: 2px;
  }
}
/**** VIEW SHUL PAGE STYLES ****/
.view-shul-outer-wraper{
  margin-left: 80px;
  padding: 40px;
  display: flex;
  justify-content: center;
}

.view-shul-outer-wraper-my-shuls{
  margin-left: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.view-shul-my-shuls-info-column{
  width: 100%;
  max-width: 810px;
  margin: 0 80px;
  padding: 40px 0;
}
/*** View Shul Header Styles ***/

.admin-shul-info {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 20px;
  font-size: 12.26px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

/*** ADMIN UPDATE STATUS Buttons***/
.update-shul-status-btns > .btn-approve {
  margin-right: 15px;
}

.back-to-dash {
  height: 24px;
  color: #23d7f2;
  font-family: "Helvetica Neue";
  font-size: 14.31px;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.back-to-dash img {
  padding-right: 10px;
}
.back-to-dash {
  margin-bottom: 10px;
}

/*** View Shul my-shuls Styles ***/
.my-shuls-sidebar{
  width: 352px;
  border-right: 1px solid #E9E9E9;
  height: auto;
  margin-left: 80px;
}

.my-shuls-sidebar-header{
  height: 60px;
  width: 100%;
  background-color: #FAFAFA;
  border-bottom: 1px solid #E9E9E9;
  color: #2A3233;
  font-size: 14px;
  font-weight: 500;
}

.my-shuls-sidebar-shul-box{
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #2A3233;
  font-weight: 500;
  letter-spacing: 0.11px;
  font-size: 16px;
  text-transform: capitalize;
  border-bottom: 1px solid #E9E9E9;
  padding: 0 25px;
  cursor: pointer;
}

.my-shuls-active{
  color: #23D7F2;
}

#view-shul-my-shuls-edit-button{
  max-width: 80px;
  min-width: 80px;
  margin-left: 20px;
}


/*** View Shul Card Styles ***/
 .view-shul-cards {
  display: flex;
}

.view-shul-cards-my-shuls {
  flex-direction: column;
  align-items: center;
}

.view-shul-minyanim{
  display: flex;
  flex-direction: column;
  width: 50%;
}

.view-shul-contacts-notes{
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 40px;
}

.my-shuls{
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  max-width: 100%;
  margin-left: 0;
}




/* Minayanim Table Styles */

.view-shul-minyanim .table-row {
  grid-template-columns: 1fr .7fr .6fr;
  padding: 10px 0;
  min-height: 45px;
}

/* Contacts Table Styles */
.view-shul-contacts{
  margin-bottom: 40px;
}
.view-shul-contacts .table-row {
  grid-template-columns: 1.25fr 1fr 1fr;
  padding: 10px 0;
  min-height: 45px;
}
.view-shul-contacts .table-row i {
  font-size: 14px;
  margin-right: 4px;
}

/* notes Table Styles */
.view-notes{
  border-top: 1px solid rgba(42,50,51,0.12);
  padding-top: 20px;
}




/* View Shul General */

.view-shul-minyanim h2, .view-shul-contacts h2, .view-shul-notes h2{
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 15px;
  letter-spacing: 0.25px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (max-width: 1024px) {
  .view-shul-cards {
    display: block;
  }
  .view-shul-cards > div {
    width: 100%;
    margin: 32px 0;
  }
}

@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .view-shul-outer-wraper{
    flex-direction: column;
  }
  .view-shul-mobile-header{
    height: 74px !important;;
    width: 100%;
    box-shadow: 0 0 12px -7px rgba(0,0,0,0.12), 0 0 12px -2px rgba(0,0,0,0.12);
    margin: 0 !important;
    padding: 16px;
    align-items: center;
  }

  .my-shuls-mobile-title{
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-shul-outer-wraper{
    margin-left: 0;
    padding: 0;
  }

  .view-shul-my-shuls-info-column{
    margin: 0;
    padding: 0;
  }

  #view-shul-my-shuls-edit-button {
    margin-left: 0;
}

  .my-shuls-edit-button-mobile{
    margin: 32px 0 16px 0;
    display: flex;
    justify-content: center;
  }

  .my-shuls-sidebar-shul-box{
    height: 50px;
    font-size: 13px;
    padding: 0;
    /* border-bottom: 1px solid #E9E9E9;
    padding: 0 25px; */
  }

  .desktop-hidden.myshuls-list.mobile-full-width {
    padding: 15px;
  }

  .mobile-my-shuls-message{
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #E9E9E9;
    text-align: center;
  }

  .container.view-shul-cards.flex {
    display: block;
  }
  .view-shul.page {
    padding: 15px;
  }
  .view-shul-minyanim .table-row, .view-shul-contacts .table-row{
    line-height: 1.4em;
    grid-template-columns: 100%;
    padding: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .minyan-days{
    padding-bottom: 6px;
    line-height: .5em;
  }
  .view-shul-cards > div {
    margin: 0;
  }
}
.sponsor-close{
  color: #2A3233;
}

.sponsor-mode-toggle{
  font-size: 70px;
  cursor: pointer;
  color: gray;
}

.sponsor-mode-toggle-label{
  font-size: 16px;
}

.sponsor-mode-toggle-label-active{
  color: #23D7F2;
}

.sponsors-table-header{
  min-height: 53px;
  display: grid;
  grid-column-gap: 10px;
  border-bottom: 1px solid rgba(42,50,51,0.12);
  border-top: 1px solid rgba(42,50,51,0.12);
}

.daily-header, .daily-row{
  grid-template-columns: .3fr .2fr .8fr 60px;
}

.weekly-header, .weekly-row{
  grid-template-columns: .3fr .2fr .2fr .8fr 60px;
}

.sponsors-table-row{
  min-height: 53px;
  display: grid;
  grid-column-gap: 10px;
  border-bottom: 1px solid rgba(42,50,51,0.12);
}

.sponsor-icons{
  font-size: 18px;
  color: #2A3233;
}

.back-to-add-sponsor{
  width: 200px !important;
}


.minyan-suggestions-container{
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  padding: 36px;
  min-height: 100vh;
}

.minyan-suggestions-title{
  font-size: 24px;
  margin-bottom: 40px;
}

.minyan-suggestion-row-container{
  border-bottom: solid 1px rgba(42,50,51,0.12);
}

.minyan-suggestion-row{
  display: grid;
  grid-template-columns: 1fr 70px 45px 65px 70px 1fr .9fr 100px 100px 63px;
  grid-column-gap: 16px;
  padding: 8px 0;
  min-height: 58px;
}

.minyan-suggestions-current-minyan-row{
  display: grid;
  grid-template-columns: 1fr 70px 45px 65px 70px 1fr .9fr 100px 100px 63px;
  grid-column-gap: 16px;
  background-color: #f5f5f5;
  min-height: 30px;
  padding: 6px 0;
  margin-bottom: 8px;
  font-size: 11px;
}

.approve-suggestion{
  margin-right: 10px;
  color: #17CD90;
  cursor: pointer;
}

.deny-suggestion{
  color: #EF6A6E;
  cursor: pointer;
}

.no-minyan-suggestions{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-top: 50px;
}


@media only screen and (max-width: 560px){
  .minyan-suggestions-container{
    max-width: 1250px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  
  .minyan-suggestions-title{
    font-size: 16px;
    margin: 0;
  }

  .mobile-minyan-suggestions-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    box-shadow: 0 0 12px -7px rgba(0,0,0,0.12), 0 0 12px -2px rgba(0,0,0,0.12);
    padding: 16px;
  }

  .minyan-suggestions-table{
    margin: 20px 10px 40px 10px;
  }

  .minyan-suggestion-row{
    grid-template-columns: 100%;
    line-height: 1.2em;
  }

  .mobile-minyan-sugg-first-row{
    border-top: solid 1px rgba(42,50,51,0.12);
  }

  .minyan-suggestions-current-minyan-row{
    grid-template-columns: 100%;
    line-height: 1.2em;
    padding-left: 8px;
  }

}
.campaign-container {
  /* font-family: "Helvetica Neue"; */
  background-image: url(/images/campaignBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.c-home-header {
  padding: 100px 0 90px 0;
  font-size: 40px;
  font-weight: 500;
  line-height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  text-align: center;
}
.c-home-users {
  color: #0ea6c3;
}
.c-home-sub {
  font-size: 34px;
  font-weight: 300;
  line-height: 54px;
  color: #ffffff;
  text-align: center;
}
.c-home-sub-sub {
  font-size: 23px;
  line-height: 39px;
  padding: 76px 0 40px 0;
  color: #ffffff;
  text-align: center;
}
.c-search-bar {
  display: flex;
  padding-bottom: 140px;
}
.campaign-input-wrapper {
  background-color: #ffffff;
  height: 56px;
  width: 500px;
  display: flex;
  font-size: 16px;
}
.campaign-input {
  padding-left: 12px;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 16px;
}
.campaign-input:focus {
  outline: none;
}
.campaign-places-container {
  width: 100%;
  position: relative;
}
.campaign-places-autocomplete-container {
  position: absolute !important;
  top: 56px;
  background-color: #f8f8f8 !important;
  z-index: 20 !important;
  width: 596px !important;
  max-width: 764px !important;

  /* margin-top: 1px !important; */
  /* line-height: 35px !important; */
}
.campaign-ac-active:hover,
.campaign-ac-active:active {
  color: #ffffff !important;
  text-decoration: none !important;
  background-color: #11b6d7 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  background-image: none !important;
}
.campaign-ac-active {
  padding: 8px 0 8px 12px;
}
.campaign-search-button {
  height: 56px;
  width: 96px;
  border-radius: 0 3px 3px 0;
  background-color: #11b6d7;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

@media only screen and (max-width: 560px) {
  .campaign-container {
    padding: 0 16px;
  }
  .c-home-header {
    padding: 32px 0 30px 0;
    font-size: 16px;
    line-height: 26px;
  }
  .c-home-sub {
    font-size: 15px;
    line-height: 20px;
  }
  .c-home-sub-sub {
    font-size: 12px;
    line-height: 21px;
    padding: 44px 0 36px 0;
    color: #ffffff;
    text-align: center;
  }
  .c-search-bar {
    width: 100%;
    padding-bottom: 80px;
  }
  .campaign-input-wrapper {
    width: 100%;
    height: 32px;
  }
  .campaign-mobile-search {
    position: absolute;
    z-index: 1000;
    height: 32px;
    width: 40px;
    right: 16px;
    background-color: #11b6d7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .campaign-mobile-search-icon {
    color: #ffffff;
  }
  .campaign-places-autocomplete-container {
    font-size: 12px;
    top: 32px;
    width: 100% !important;
    max-width: 100% !important;
    /* margin-top: 1px !important; */
    /* line-height: 35px !important; */
  }
}

.c-banner {	
  height: 65px;	
  background-color: #0EA6C3;
  display: flex;
  color: #ffffff;
  font-size: 25px;
  justify-content: center;
  align-items: center;
}
.c-bergman{
  font-weight: bold;
}

@media only screen and (max-width: 560px){
  .c-banner {	
    height: 45px;	
    font-size: 8px;
    flex-direction: column;
  }


}
.missing-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}
.mi-input {
  padding-left: 12px;
  height: 56px;
  width: 750px;
  max-width: 100%;
  border: 1.5px solid #d7d7d7;
  background-color: #ffffff;
  font-size: 16px;
}
.mi-input:focus {
  outline: none;
}
.mi-clear {
  position: absolute;
  right: 3px;
  top: 14px;
  color: #d7d7d7;
  font-size: 28px;
  cursor: pointer;
}
.mi-places-container {
  width: 100%;
  position: relative;
}
.mi-loading {
  position: absolute !important;
  /* top: 56px; */
}
.mi-places-autocomplete-container {
  position: absolute !important;
  top: 56px;
  width: 100%;
  border: 1px solid #d7d7d7;
  border-top: none;
  background-color: #f8f8f8 !important;
  z-index: 20 !important;
}
.mi-ac-active:hover,
.mi-ac-active:active {
  color: #ffffff !important;
  text-decoration: none !important;
  background-color: #11b6d7 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  background-image: none !important;
}
.mi-ac-active {
  padding: 8px 0 8px 12px;
}
.mi-card-container {
  margin-top: 70px;
  min-height: 200px;
}
.mi-empty-msg{
  text-align: center;
}
.missing-info-card {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  height: 86px;
  width: 556px;
  border: 1.5px solid #d7d7d7;
  background-color: #ffffff;
  margin-bottom: 36px;
  padding: 18px;
}
.mi-info-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mi-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: #0ea6c3;
  font-size: 17px;
  font-weight: 500;
  width: 320px;
  max-width: 100%;
}
.missing-info-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 320px;
  max-width: 100%;
  font-size: 14px;
  color: #484848;
}

.missing-info-update {
  background-color: #000000;
  color: #ffffff;
  height: 36px;
  width: 194px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

@media only screen and (max-width: 560px) {
  .missing-info-container {
    max-width: 100%;
    margin-bottom: 10px;
    margin-top: 16px;
    padding: 0 16px;
  }
  .mi-input {
    height: 36px;
  }
  .mi-clear {
    top: 8px;
    font-size: 22px;
}
.mi-places-autocomplete-container {
  top: 36px;
}
  .mi-card-container {
    margin-top: 21px;
    width: 100%;
  }
  .missing-info-card {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    height: 45px;
    width: 556px;
    margin-bottom: 24px;
    padding: 10px;
  }
  .mi-info-column {
    width: calc(100% - 100px);
  }
  .mi-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    color: #0ea6c3;
    font-size: 9px;
    font-weight: bold;
    width: 100%;
    max-width: 100%;
  }
  .missing-info-address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 100%;
    font-size: 9px;
    color: #484848;
  }

  .missing-info-update {
    height: 18px;
    width: 95px;
    font-size: 7px;
  }
}

.c-form-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #0ea6c3;
  font-size: 18px;
  font-weight: 500;
  padding: 48px 0 50px 125px;
  /* font-family: "Helvetica Neue"; */
  cursor: pointer;
}
.c-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-family: "Helvetica Neue"; */
}
.c-form-txt {
  width: 916px;
  font-size: 23px;
  line-height: 42.33px;
  text-align: center;
}
.c-form-ins {
  font-weight: bold;
  font-size: 32px;
  padding: 24px 0 60px 0;
}
.c-form-shul {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #0ea6c3;
  line-height: 50px;
  text-transform: capitalize;
}
.c-form-shul-add {
  font-size: 30px;
  text-align: center;
  padding-bottom: 46px;
}
.c-form-box {
  box-sizing: border-box;
  width: 806px;
  border: 2px solid #d7d7d7;
  background-color: #ffffff;
  box-shadow: 0 0 13px 1px rgba(53, 56, 54, 0.07);
  padding: 0 48px 24px 48px;
  margin-bottom: 38px;
}
.c-form-header {
  background-color: #0ea6c3;
  width: 100%;
  height: 60px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: bold;
  margin: 58px 0 28px 0;
}
.c-input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}
.c-input-label {
  font-size: 20px;
  font-weight: 500;
}
.c-input {
  height: 45px;
  width: 324px;
  border: 1.5px solid #d7d7d7;
  padding-left: 12px;
  font-size: 16px;
  background: transparent;
  transition: border 300ms linear;
  border-radius: 0%;
}
.c-input:focus {
  outline: none;
}
.c-form-left-button {
  height: 81px;
  width: 299px;
  background-color: #0ea6c3;
  margin-right: 30px;
  color: #ffffff;
  font-size: 23px;
  line-height: 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.c-form-right-button {
  height: 81px;
  width: 299px;
  background-color: #0ea6c3;
  color: #ffffff;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-form-remaining-txt {
  padding: 60px 0 80px 0;
  font-size: 32px;
  font-weight: bold;
}
.c-form-remaining {
  color: #0ea6c3;
}

@media only screen and (max-width: 560px) {
  .c-form-back {
    justify-content: center;
    font-size: 9px;
    font-weight: 500;
    padding: 14px 0 18px 0;
  }
  .c-form-container {
    margin: 0 12px;
  }
  .c-form-txt {
    width: 100%;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
  }
  .c-form-ins {
    font-size: 14px;
    text-align: center;
    padding: 18px 0 24px 0;
  }
  .c-form-shul {
    font-size: 13px;
    line-height: 20px;
  }
  .c-form-shul-add {
    font-size: 12px;
    padding-bottom: 18px;
  }

  .c-form-box {
    width: 100%;
    padding: 0 10px 16px 10px;
    margin-bottom: 20px;
  }
  .c-form-header {
    height: 28px;
    color: #ffffff;
    font-size: 11px;
    margin: 12px 0 14px 0;
  }
  .c-input-row {
    margin-bottom: 8px;
  }
  .c-input-label {
    font-size: 9px;
  }
  .c-input {
    height: 23px;
    width: 124px;
    border: 1.5px solid #d7d7d7;
    padding-left: 6px;
    font-size: 10px;
  }
  .c-form-left-button {
    height: 37px;
    width: 134px;
    margin-right: 14px;
    font-size: 10px;
    line-height: 12px;
  }
  .c-form-right-button {
    height: 37px;
    width: 134px;
    font-size: 14px;
  }
  .c-form-remaining-txt {
    padding: 20px 0 32px 0;
    font-size: 18px;
    font-weight: bold;
  }
}

.c-ty-container {
  /* font-family: "Helvetica Neue"; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 86px;
}
.ty-thank-you {
  color: #0EA6C3;
  font-size: 32px;
  font-weight: bold;
  padding: 46px 0 32px 0;
  text-align: center;
}
.ty-we-are-wrapper{
  padding-bottom: 92px;
}
.ty-we-are{
  color: #000000;
  font-size: 23px;
  line-height: 39.33px;
  text-align: center;
}
.ty-would-you {
  color: #000000;
  font-size: 27px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}

.ty-search-button {
  height: 81px;
  width: 474px;
  margin-top: 50px;
  margin-bottom: 82px;
  background-color: #0EA6C3;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 560px) {
  .c-ty-container {
    margin-top: 17px;
  }
  .ty-thank-you {
    font-size: 12px;
    padding: 16px 0 10px 0;
  }
  .ty-we-are-wrapper{
    padding-bottom: 25px;
  }
  .ty-we-are{
    font-size: 10px;
    line-height: 16px;
  }
  .ty-would-you {
    font-size: 11px;
    line-height: 16px;
  }
  
  .ty-search-button {
    height: 27px;
    width: 156px;
    margin-top: 16px;
    margin-bottom: 29px;
    font-size: 10px;
  }
}

.c-tyb-container {
  /* font-family: "Helvetica Neue"; */
  width: 806px;
  max-width: 100%;
  margin: 0 auto;
}
.tyb-header {
  display: flex;
  justify-content: center;
  font-size: 27px;
  font-weight: bold;
  padding-bottom: 42px;
}
.tyb-social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 96px;
}
.tyb-button {
  height: 106px;
  width: 173px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.typ-facebook {
  background-color: #3e5a9a;
}
.typ-twitter {
  background-color: #36b7ea;
}
.typ-whatsapp {
  background-color: #74bf59;
}
.typ-linkedin {
  background-color: #0f78b4;
}
.tyb-social-txt {
  padding-left: 14px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.1px;
}
.typ-memory-box {
  width: 806px;
  border: 2px solid #d7d7d7;
  background-color: #ffffff;
  box-shadow: 0 0 13px 1px rgba(53, 56, 54, 0.07);
  margin-bottom: 90px;
  padding: 30px;
}
.mb-txt {
  /* font-family: Graphik; */
  font-size: 20.83px;
  line-height: 33.33px;
  margin-left: 34px;
}
.mb-header1 {
  font-size: 18px;
  font-weight: 300;
  line-height: 33.33px;
  text-align: center;
}
.mb-header2 {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 30px;
}

@media only screen and (max-width: 560px) {
  .c-tyb-container {
  padding: 0 18px;
  display: flex;
    flex-direction: column;
    align-items: center;
  }
  .tyb-header {
    font-size: 11px;
    padding-bottom: 18px;
  }
  .tyb-social-links {
    margin-bottom: 16px;
    width: 200px;
    flex-wrap: wrap;
  }
  .tyb-button {
    height: 54px;
    width: 88px;
    margin-bottom: 10px;
  }
  .tyb-icon{
    height: 16px;
    width: 16px;
  }
  .tyb-social-txt {
    padding-left: 6px;
    font-size: 9px;
    line-height: 11px;
  }
  .typ-memory-box {
    width: 100%;
    margin-bottom: 46px;
    padding: 18px;
  }
  .mb-header1 {
    font-size: 10px;
    line-height: 14px;
  }
  .mb-header2 {
    font-size: 10px;
    line-height: 14px;
    padding-bottom: 0;
  }
  .typ-mobile-img{
    padding-top: 18px;
    padding-bottom: 12px;
  }
  .mb-txt {
    /* font-family: Graphik; */
    font-size: 10px;
    line-height: 18px;
    margin-left: 0;
    text-align: center;
  }
}

.nr-content {
  /* font-family: "Helvetica Neue"; */
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nr-header {
  color: #0ea6c3;
  /* font-family: "Helvetica Neue"; */
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  line-height: 40.67px;
  text-transform: uppercase;
}

.nr-search-button {
  height: 81px;
  width: 474px;
  margin-top: 50px;
  margin-bottom: 78px;
  background-color: #0EA6C3;
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 560px){
  .nr-content {
    padding-top: 36px;
  }
  
  .nr-header {
    font-size: 11px;
    line-height: 13px;
  }
  
  .nr-search-button {
    height: 26px;
    width: 156px;
    margin-top: 23px;
    margin-bottom: 27px;
    font-size: 10px;
  }
  
}


/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
  line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.component-font-color{
  color: #2A3233;
}

/***** SEARCHBOX STYLES *****/
.searchbox-container {
  height: 40px;
  border: 1px solid rgba(28,60,83,0.16);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 16px;
}
.searchbox {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  padding-left: 16px;
}
.searchbox:focus {
  outline: none;
}
.searchbox-container i {
  color: #23D7F2;
}
/*** SEEMS TO BE SAME SIZE ON ALL DEVICES ***/
.searchbox-container {height: 32px;}
/*
@media only screen and (max-width: 1024px){
  .searchbox-container {height: 32px;}
}
*/
/***** BUTTON STYLES *****/
.btn {
  height: 40px;
  min-width: 168px;
  border-radius: 3px;
  font-size: 14px;
  border: none;
  text-transform: uppercase;
  transition: all 300ms linear;
  cursor: pointer;
}
.btn:focus {outline: none;}
/* @media only screen and (max-width: 1024px){
  .btn {height: 32px; width: 96px; font-size: 11px;}
} */

/*** Button Accent Styles ***/
.btn-accent {background-color: #23D7F2; color: #FFFFFF; border: 1px solid #23D7F2;}
.btn-accent:hover {background: #fff; color: #23D7F2;}
.btn.btn-small.btn-accent {width: 140px; font-size: 12px;}

/*** Button Light Styles ***/
.btn-light {background-color: #eee; color: #2A3233; border: 1px solid #eee;}
.btn-light:hover {background: #2a3233; color: #eee;}
.btn.btn-light.btn-small {width: 80px; font-weight: 500;}

/*** Button Approve Styles ***/
.btn-approve {background: #17CD90; color: #fff; border: 1px solid #17cd90;}
.btn-approve:hover {background: #fff; color: #17cd90;}
.btn.btn-approve.btn-small {width: unset; padding: 0 16px;}

/*** Button Deny Styles ***/
.btn-deny {background: #EF6A6E; color: #fff; border: 1px solid #EF6A6E;}
.btn-deny:hover {background: #fff; color: #EF6A6E;}
.btn.btn-deny.btn-small {width: unset; padding: 0 16px;}

/*** Button Sizes ***/
.btn.btn-small {height: 32px;}

/***** CARD STYLES *****/
.card {
  background: #fff;
  box-shadow: 0 0 12px -7px rgba(0,0,0,0.12), 0 0 12px -2px rgba(0,0,0,0.12);
  border-radius: 3px;
  padding: 24px;
}

/***** INPUT STYLES *****/
input.custom-input, select.custom-input, textarea.custom-input {
  border: 1px solid rgba(28,60,83,0.16);
  border-radius: 3px;
  height: 40px;
  padding: 8px;
  background: transparent;
  transition: border 300ms linear;
}
input.custom-input:focus, select.custom-input:focus, textarea.custom-input:focus {outline: none; border: 1px solid #23D7F2;}
.required {border: 1px solid red !important;}

/***** CHECKBOX LABEL STYLES *****/
.checkbox-labels-container {display: flex; flex-wrap: wrap;}
.checkbox-label label {
  display: block;
  min-width: 96px;
  height: 32px;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  margin-right: 8px;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.08);
  transition: background 300ms linear;
}
.checkbox-label input {display: none !important;}
.checkbox-label label:hover {background: #f5f5f5; border-color: #f5f5f5;}
.checkbox-label input:checked + label {background-color: rgba(0,0,0,0.08);}

/***** CUSTOM RADIO BUTTON STYLES *****/
.radio-button-container [type="radio"]:checked, .radio-button-container [type="radio"]:not(:checked) {position: absolute; left: -9999px;}
.radio-button-container [type="radio"]:checked + label, .radio-button-container [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size: 13px;
    margin-right: 16px;
}
.radio-button-container [type="radio"]:not(:checked) + label {color: $accent-text-light;}
.radio-button-container [type="radio"]:checked + label.inline, .radio-button-container [type="radio"]:not(:checked) + label.inline {display: inline;}
.radio-button-container [type="radio"]:checked + label:before, .radio-button-container [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #FBFBFC;;
}
.radio-button-container [type="radio"]:checked + label:before {border: 1px solid rgba(0,0,0,0.54);}
.radio-button-container [type="radio"]:checked + label:after, .radio-button-container [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: rgba(0,0,0,0.54);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
.radio-button-container [type="radio"]:not(:checked) + label:after {opacity: 0; transform: scale(0);}
.radio-button-container [type="radio"]:checked + label:after {opacity: 1; transform: scale(1);}
.radio-button-container .radio-label {color: #76838f; font-size: 13px;}


/***** OVERRIDE FOR SEASONAL RADIO BOXES *****/
.preset-labels-div {flex-wrap: wrap;}
.preset-labels-div .checkbox-label [type="radio"]:checked + label:after, 
.preset-labels-div .checkbox-label [type="radio"]:checked + label:before,
.preset-labels-div .checkbox-label [type="radio"]:not(:checked) + label:after,
.preset-labels-div .checkbox-label [type="radio"]:not(:checked) + label:before {
  display: none;
}
.preset-labels-div [type="radio"]:checked + label, .preset-labels-div [type="radio"]:not(:checked) + label {
  padding-left: 16px;
  display: flex;
}


/***** UPLOAD BOX STYLES *****/
.upload-box {
  border: 1px dashed rgba(42,50,51,0.24);
  border-radius: 3px;
  width: 100%;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone-custom{
  height: 100%;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.image-thumb-nails-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.image-thumb-nail{
  height: 70px;
  width: 90px;
  margin: 5px;
}

.image-thumb-nail-div{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-thumb-nail-div:hover .image-thumb-nail-delete-overlay{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0,0,0,0.72);
}

.image-thumb-nail-delete-overlay{
  display: none;
}

.image-thumb-nail-delete-icon{
  color: #ffffff;
  cursor: pointer;
}

.images-coming-soon{
  width: 168px;
  height: 32px;
  background-color: darkgray;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.image-placeholder-message{
  margin-top: 50px;
  line-height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
}

.image-placeholder-message a{
  color: #2A3233;
  text-decoration: underline;
}

.uploaded-images {margin-top: 32px; display: flex;}
.uploaded-images > div {margin-right: 16px;}
.uploaded-images img {height: 64px; width: 64px; border-radius: 3px; object-fit: cover;}
.uploaded-images > div > i {
  height: 64px;
  width: 64px;
  border-radius: 3px;
  background: rgba(0, 0, 0, .6);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: none;
  cursor: pointer;
  transition: opacity 300ms linear;
}
.uploaded-images > div:hover i {opacity: 1; visibility: visible;}
.pipe { margin: 0 15px; }

/***** FULL PAGE MODAL STYLES *****/
.full-page-modal {margin: 96px 16px 32px;}
.full-page-modal-close {
  position: absolute;
  top: 40px;
  right: 64px;
  height: 56px;
  width: 56px;
  border-radius: 100%;
  background-color: rgba(42,50,51,0.08);
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-page-modal .container {max-width: 680px;}
.bordered-title {border-bottom: 1px solid #E9E9E9; padding-bottom: 16px; margin-bottom: 24px;}
.input-label {display: inline-block; min-width: 136px; max-width: 100%; font-size: 14px;}
.full-page-modal input.custom-input {width: 360px; max-width: 100%;}
.full-page-modal select.custom-input {width: 360px; max-width: 100%;}
.full-page-modal textarea.custom-input {width: 100%; height: 80px; resize: none;}
.full-page-modal-footer {border-top: 1px solid #E9E9E9; margin-top: 24px; padding-top: 24px;}
.full-page-modal-footer-btns {display: flex; justify-content: flex-end;}
.full-page-modal-footer-btns .btn {margin-left: 8px; width: auto; padding: 0 16px; min-width: 104px;}

/*** Auto Complete ***/
.autocomplete-container {
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item.active {
  background-color: #fafafa;
}

/*** front end feedback box ***/

.feedback-div{
  position: fixed;
  /* height: 184px;
  width: 71px; */
  height: 150px;
  width: 40px;
  background-color: #636363;
  /* top: 465px; */
  bottom: 150px;
  z-index: 1;
}

.feedback{
  font-weight: 500;
  color: #FFFFFF;
  font-size: 18px;
  transform: rotate(-90deg);
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .full-page-modal {margin: 38px 0px 0px;}
  .full-page-modal .container {padding-right: 16px; padding-left: 16px;}
  .full-page-modal-close {
    top: 46px;
    right: 31px;
    height: 0;
    width: 0;
    background-color: #ffffff;
  }
  .bordered-title{
    border-bottom: none;
    padding-left: 20px;
    padding-bottom: 22px;
    box-shadow: 0 8px 6px -6px rgba(0,0,0,0.12);
  }
  .full-page-modal input.custom-input {width: 210px; max-width: 100%;}
  .input-label {display: inline-block; min-width: 100px; max-width: 100%; font-size: 12px;}
  .full-page-modal select.custom-input {width: 210px; max-width: 100%; height: 32px;}
  .mobile-block {
    display: block !important;
  }
  input.custom-input {
    width: 100%;
    margin: 10px 0;
  }
  .btn {
    height: 28px;
    min-width: 50px;
    font-size: 10px;
  }
  .full-page-modal-footer-btns .btn{min-width: 0;}
  .btn.btn-small.btn-accent {width: 100%;}

}
* {box-sizing: border-box;}
body {font-family: Roboto, sans-serif; font-size: 13px; color: #2A3233;}

/***** TYPOGRAPHY STYLES *****/
/*** Font Weight Styles ***/
.text-500 {font-weight: 500;}
.bold-text {font-weight: bold;}

/*** Font style Styles ***/
.italic {font-style: italic;}

/*** Font Size Styles ***/
.tiny-text {font-size: 6px;}
.text-8  {font-size: 8px;}
.xs-text {font-size: 10px;}
.small-text {font-size: 12px;}
.medium-text {font-size: 14px;}
.text-15 {font-size: 15px;}
.large-text {font-size: 16px;}
.text-18 {font-size: 18px;}
.xl-text {font-size: 24px;}
.text-36 {font-size: 36px;}

/*** Font Color Styles ***/
.accent-text {color: rgba(0, 0, 0, .6);}
.accent-text-light {color: rgba(0, 0, 0, .38);}
.accent-text-dark {color: rgba(0, 0, 0, 0.87);}
.accent-text-secondary {color: #23D7F2;}
.danger{color: red;}

/*** Text Transform Styles ***/
.uppercase-text {text-transform: uppercase;}
.capitalize-text {text-transform: capitalize;}
.underline {text-decoration: underline;}

/*** Text Alignment Styles ***/
.text-right {text-align: right;}
.text-center {text-align: center;}

/*** Remove all underlines from link ***/
a {text-decoration: none}

/*** Simulate Link ***/
.link {cursor: pointer;}

/*** Make hr uniform ***/
hr {
	height: 1px;
	width: 680px;
	border: 1px solid #E9E9E9;
	transform: scaleY(-1);
	margin: 20px 0;
}
/*** Hide ***/
.hide {display: none;}

/*** disable ***/
.disable {opacity: .5; cursor: default}

/*** position ***/
.relative {position: relative;}

/*** display ***/
.inline {display: inline;}

/* Chrome/Opera/Safari  */
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder { opacity: 0.5;}
/* Firefox 19+ */
input::-moz-placeholder, textarea::-moz-placeholder { opacity: 0.5;}
/* IE 10+ */
input:-ms-input-placeholder, textarea:-ms-input-placeholder { opacity: 0.5;}
/* Firefox 18- */
input:-moz-placeholder, textarea:-moz-placeholder { opacity: 0.5;}


/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
	.mobile-bold-text {font-weight: bold;}
	.mobile-text-500 {font-weight: 500;}
	.mob-text-4 {font-size: 4px;}
	.mob-text-6 {font-size: 6px;}
	.mob-text-8 {font-size: 8px;}
	.mob-xs-text {font-size: 10px;}
	.mob-small-text {font-size: 12px;}
	.mob-medium-text {font-size: 14px;}
	.mob-large-text {font-size: 16px;}
	.mob-text-18 {font-size: 18px;}
	.mob-text-20 {font-size: 20px;}
	.mob-xl-text {font-size: 24px;}

	.mobile-block {display: block;}

	hr {
		width: 100%;
		margin: 16px 0;
	}
}

/***** LAYOUT STYLES *****/
/*** SIDE BAR STYLES ***/
aside {
  min-height: 100vh;
  height: 100vh;
  width: 80px;
  background-color: #2A3233;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: #fff;
  padding: 32px 16px;
  text-align: center;
  width: 250px;
  box-shadow: 6px 0 12px 0 rgba(0,0,0,0.24);
  z-index: 10;
  transition: width 300ms linear, box-shadow 300ms linear;
}
/* Closed Sidebar Styles */
aside.closed {width: 80px; box-shadow: none;}

/* Sidebar - Nav Styles */
nav {display: inline-block;}
nav ul li a {
  margin-top: 48px;
  display: flex;
  align-items: center;
  transition: color 300ms linear;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
}
nav ul li:hover {color: #23D7F2;}
nav ul li:hover a {color: #23D7F2;}
nav ul li p {margin-left: 24px; white-space: nowrap;}
/* Closed Sidebar Nav Styles */
aside.closed nav ul li a{justify-content: center;}
aside.closed nav ul li p {display: none;}
/* Active Nav Link Styles */
nav ul li a.active {color: #23D7F2 !important}

.aside-logo-div{
  display: flex;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  letter-spacing: 0.65px;
  cursor: pointer;
}

/*** CONTENT STYLES ***/
/* .content {margin-left: 80px; padding: 40px;} */

/*** HEADER STYLES ***/
header.header-fixed {
  width: calc(100% - 80px);
  height: 80px;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  background: #fff;
}
header h1 {font-size: 24px;}
.header-fixed-page {margin-top: 40px;}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .content {
    margin: 0px;
    padding: 0px;
  }
  header.header-fixed {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 24px;
    box-shadow: 0 0 12px -7px rgba(0,0,0,0.12), 0 0 12px -2px rgba(0,0,0,0.12);
  }
  .header-fixed-page {
    margin-top: 0px;
  }
}

