.c-form-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #0ea6c3;
  font-size: 18px;
  font-weight: 500;
  padding: 48px 0 50px 125px;
  /* font-family: "Helvetica Neue"; */
  cursor: pointer;
}
.c-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* font-family: "Helvetica Neue"; */
}
.c-form-txt {
  width: 916px;
  font-size: 23px;
  line-height: 42.33px;
  text-align: center;
}
.c-form-ins {
  font-weight: bold;
  font-size: 32px;
  padding: 24px 0 60px 0;
}
.c-form-shul {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #0ea6c3;
  line-height: 50px;
  text-transform: capitalize;
}
.c-form-shul-add {
  font-size: 30px;
  text-align: center;
  padding-bottom: 46px;
}
.c-form-box {
  box-sizing: border-box;
  width: 806px;
  border: 2px solid #d7d7d7;
  background-color: #ffffff;
  box-shadow: 0 0 13px 1px rgba(53, 56, 54, 0.07);
  padding: 0 48px 24px 48px;
  margin-bottom: 38px;
}
.c-form-header {
  background-color: #0ea6c3;
  width: 100%;
  height: 60px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  font-weight: bold;
  margin: 58px 0 28px 0;
}
.c-input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}
.c-input-label {
  font-size: 20px;
  font-weight: 500;
}
.c-input {
  height: 45px;
  width: 324px;
  border: 1.5px solid #d7d7d7;
  padding-left: 12px;
  font-size: 16px;
  background: transparent;
  transition: border 300ms linear;
  border-radius: 0%;
}
.c-input:focus {
  outline: none;
}
.c-form-left-button {
  height: 81px;
  width: 299px;
  background-color: #0ea6c3;
  margin-right: 30px;
  color: #ffffff;
  font-size: 23px;
  line-height: 27px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.c-form-right-button {
  height: 81px;
  width: 299px;
  background-color: #0ea6c3;
  color: #ffffff;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-form-remaining-txt {
  padding: 60px 0 80px 0;
  font-size: 32px;
  font-weight: bold;
}
.c-form-remaining {
  color: #0ea6c3;
}

@media only screen and (max-width: 560px) {
  .c-form-back {
    justify-content: center;
    font-size: 9px;
    font-weight: 500;
    padding: 14px 0 18px 0;
  }
  .c-form-container {
    margin: 0 12px;
  }
  .c-form-txt {
    width: 100%;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
  }
  .c-form-ins {
    font-size: 14px;
    text-align: center;
    padding: 18px 0 24px 0;
  }
  .c-form-shul {
    font-size: 13px;
    line-height: 20px;
  }
  .c-form-shul-add {
    font-size: 12px;
    padding-bottom: 18px;
  }

  .c-form-box {
    width: 100%;
    padding: 0 10px 16px 10px;
    margin-bottom: 20px;
  }
  .c-form-header {
    height: 28px;
    color: #ffffff;
    font-size: 11px;
    margin: 12px 0 14px 0;
  }
  .c-input-row {
    margin-bottom: 8px;
  }
  .c-input-label {
    font-size: 9px;
  }
  .c-input {
    height: 23px;
    width: 124px;
    border: 1.5px solid #d7d7d7;
    padding-left: 6px;
    font-size: 10px;
  }
  .c-form-left-button {
    height: 37px;
    width: 134px;
    margin-right: 14px;
    font-size: 10px;
    line-height: 12px;
  }
  .c-form-right-button {
    height: 37px;
    width: 134px;
    font-size: 14px;
  }
  .c-form-remaining-txt {
    padding: 20px 0 32px 0;
    font-size: 18px;
    font-weight: bold;
  }
}
