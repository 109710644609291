.nr-content {
  /* font-family: "Helvetica Neue"; */
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nr-header {
  color: #0ea6c3;
  /* font-family: "Helvetica Neue"; */
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  line-height: 40.67px;
  text-transform: uppercase;
}

.nr-search-button {
  height: 81px;
  width: 474px;
  margin-top: 50px;
  margin-bottom: 78px;
  background-color: #0EA6C3;
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (max-width: 560px){
  .nr-content {
    padding-top: 36px;
  }
  
  .nr-header {
    font-size: 11px;
    line-height: 13px;
  }
  
  .nr-search-button {
    height: 26px;
    width: 156px;
    margin-top: 23px;
    margin-bottom: 27px;
    font-size: 10px;
  }
  
}

