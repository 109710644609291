.overlay {
	height: 364px;
  width: 400px;
  text-align: center;
}

.x-container {
	text-align: center;
  color: #EF6A6E;
}
.x {
	font-size: 120px;
	font-weight: 100;
}

.message-container {
	height: 81.85px;
	width: 328px;
	margin: 0 auto;
	color: #2A3233;
	font-family: "Helvetica Neue";
	font-size: 24.53px;
	line-height: 29px;
	text-align: center;
}

.message {
	height: 58.56px;
	width: 100%;
	color: rgba(0,0,0,0.38);
	font-family: "Helvetica Neue";
	font-size: 14.31px;
	letter-spacing: 0.1px;
	line-height: 24px;
	text-align: center;
}

.buttons-container{
  width: 328px;
	margin: 0 auto;
}

.button {
	height: 48px;
	width: 156px;
	font-family: "Helvetica Neue";
	font-size: 14.31px;
	font-weight: 500;
	letter-spacing: 1.25px;
	line-height: 16px;
	text-align: center;
  border-radius: 3px;
  border: none;
  cursor: pointer;
	box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
}
.button:focus {outline: none;}

.delete{
  background-color: #EF6A6E;
  color: #FFFFFF;
}

.cancel{
  background-color: #FAFAFA;
  color: #2A3233;
}
/* COVID POLICY */
.covid.buttons-container {
  width: 400px;
}

.covid.buttons-container button{
  width: 125px;
}

select.custom-input.mobile-mt-10.covid {
  font-size: 12px;
}

@media only screen and (max-width: 560px) {
  .covid.buttons-container {
    width: 360px;
  }
  .covid.buttons-container button {
    width: 110px;
  }
}