* {box-sizing: border-box;}
body {font-family: Roboto, sans-serif; font-size: 13px; color: #2A3233;}

/***** TYPOGRAPHY STYLES *****/
/*** Font Weight Styles ***/
.text-500 {font-weight: 500;}
.bold-text {font-weight: bold;}

/*** Font style Styles ***/
.italic {font-style: italic;}

/*** Font Size Styles ***/
.tiny-text {font-size: 6px;}
.text-8  {font-size: 8px;}
.xs-text {font-size: 10px;}
.small-text {font-size: 12px;}
.medium-text {font-size: 14px;}
.text-15 {font-size: 15px;}
.large-text {font-size: 16px;}
.text-18 {font-size: 18px;}
.xl-text {font-size: 24px;}
.text-36 {font-size: 36px;}

/*** Font Color Styles ***/
.accent-text {color: rgba(0, 0, 0, .6);}
.accent-text-light {color: rgba(0, 0, 0, .38);}
.accent-text-dark {color: rgba(0, 0, 0, 0.87);}
.accent-text-secondary {color: #23D7F2;}
.danger{color: red;}

/*** Text Transform Styles ***/
.uppercase-text {text-transform: uppercase;}
.capitalize-text {text-transform: capitalize;}
.underline {text-decoration: underline;}

/*** Text Alignment Styles ***/
.text-right {text-align: right;}
.text-center {text-align: center;}

/*** Remove all underlines from link ***/
a {text-decoration: none}

/*** Simulate Link ***/
.link {cursor: pointer;}

/*** Make hr uniform ***/
hr {
	height: 1px;
	width: 680px;
	border: 1px solid #E9E9E9;
	transform: scaleY(-1);
	margin: 20px 0;
}
/*** Hide ***/
.hide {display: none;}

/*** disable ***/
.disable {opacity: .5; cursor: default}

/*** position ***/
.relative {position: relative;}

/*** display ***/
.inline {display: inline;}

/* Chrome/Opera/Safari  */
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder { opacity: 0.5;}
/* Firefox 19+ */
input::-moz-placeholder, textarea::-moz-placeholder { opacity: 0.5;}
/* IE 10+ */
input:-ms-input-placeholder, textarea:-ms-input-placeholder { opacity: 0.5;}
/* Firefox 18- */
input:-moz-placeholder, textarea:-moz-placeholder { opacity: 0.5;}


/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
	.mobile-bold-text {font-weight: bold;}
	.mobile-text-500 {font-weight: 500;}
	.mob-text-4 {font-size: 4px;}
	.mob-text-6 {font-size: 6px;}
	.mob-text-8 {font-size: 8px;}
	.mob-xs-text {font-size: 10px;}
	.mob-small-text {font-size: 12px;}
	.mob-medium-text {font-size: 14px;}
	.mob-large-text {font-size: 16px;}
	.mob-text-18 {font-size: 18px;}
	.mob-text-20 {font-size: 20px;}
	.mob-xl-text {font-size: 24px;}

	.mobile-block {display: block;}

	hr {
		width: 100%;
		margin: 16px 0;
	}
}
