.search-page-container{
  display: flex;
  font-family: Roboto;
  color: #484848;
}

.results-page-color{
  color: #484848;
}

.search-content-container {
 width: 864px;
 max-width: 864px;
 z-index: 10;
}

.search-mode-toggle{
  margin-left: 120px;
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search-mode-toggle-label{
  color: rgba(0,0,0,0.6);
  font-size: 14px;
}

.search-mode-toggle-label-active{
  color: #11B6D7;
}

.search-input-box{
  display: flex;
  align-items: center;
  height: 48px;
  flex: 1;
  border: 1px solid #DEDEDE;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.24);
  margin-right: 40px;
  margin-left: 120px;
  margin-bottom: 16px;
}

.search-input{
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: #FFFFFF;
  font-size: 16px;
  color: rgba(0,0,0,0.87);
  border: none transparent;
  outline: none;
  padding: 0 10px;
}

.search-page-icon-cover-up {
  position: absolute;
  background: #ffffff;
  width: 34px;
  height: 41px;
  bottom: -20px;
  right: 1px;
}

.search-page-clear-input-icon{
  color: #A3A3A3;
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
  z-index: 100;
}

.input-search-icon{
  position: absolute;
  right: 10px;
  top: -9px;
  color: #11B6D7;
  z-index: 100;
}

.filters-container{
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  margin-left: 120px;
  background-color: #FFFFFF;
  padding-bottom: 16px;
}

.filter-box{
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 7px 14px;
  border: 1px solid #E4E4E4;
  font-size: 14px;
  color: #484848;
  border-radius: 3px;
  background-color: #FFFFFF;
  cursor: pointer;
  margin-right: 12px;
}

.filter-box-active{
  border: 1px solid #11B6D7;
  background-color: #11B6D7;
  color: #FFFFFF;
  /* font-weight: 500; */
}

.filter{
  position: absolute;
  top: 40px;
  left: -1px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 0 14px 2px rgba(0,0,0,0.12), 0 2px 5px -3px rgba(0,0,0,0.2);
  z-index: 10;
  color: #484848;
  font-size: 14px;
}

.filter-li{
  display: flex;
  align-items: center;
  margin-top: 7px;
  padding-left: 6px;
}

.apply-filter{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.21px;
  color: #11B6D7;
  padding: 12px;
}

.time-filter-actions{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.21px;
  padding: 12px;
}

.tefillah-filter, .nusach-filter{
  padding-top: 5px;
  width: 140px;
}

.distance-filter{
  padding: 16px 0 0 16px;
  width: 220px;
}

.distance-title{
  font-size: 12px;
  padding-bottom: 7px;
}
.distance-slider-div{
  margin-right: 20px;
}

.time-filter{
  padding-top: 12px;
  width: 304px;
}

.time-title{
  padding-left: 12px;
  padding-bottom: 12px;
  font-size: 12px;
  padding-left: 85px
}

.time-pickers{
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 16px;
}

.apply-times,.clear-times{
  cursor: pointer;
  color: #11B6D7;
}

.timeFlex{
  display: flex;
}

.more-filter{
  width: 220px;
}

.more-filter-title{
  padding: 14px 0 1px 12px;
  font-size: 12px;
}

.reset-filters{
  font-size: 12px;
  cursor: pointer;
}

.search-content-separator{
  border-bottom: 1px solid #E4E4E4;
}

.search-week-sponsor{
  min-height: 40px;
  width: 704px;
  margin-left: 120px;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  /* margin-bottom: 8px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  line-height: 12px;
}

.search-dedication-oppor-link {
	height: 24px;
	width: 180px;
  border-radius: 3px;
  color: #FFFFFF;
  background-color: rgba(255,255,255,0.16);
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.suggest-update-link{
  margin-top:1em;
  height: 25px;
  width: 175px;
  border: 1px solid #11B6D7;
  color: #11B6D7; 
  border-radius: 12px;  
  font-weight: 500;
}

/*** results ***/

.results-container{
  min-height: 300px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
}

.total-results{
  margin-top: 16px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #484848;
  margin-left: 120px;
  letter-spacing: 0.25px;
}

.result-cards{
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 40px;
}

.result-card {
  min-height: 108px;
  width: 704px;
  padding: 16px;
  border: 1px solid #E4E4E4;
  border-radius: 3px;
  background-color: #FFFFFF;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.results-shul-info-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 100;
}

.results-shul-name{
  color: #11B6D7;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  text-transform: capitalize;
}

.results-shul-info{
  display: flex;
  color: #484848;
  font-size: 14px;
  text-transform: capitalize;
  flex-wrap: wrap;
  /* word-wrap: none; */
  line-height: 1.3em;
}

.results-phone{
  color: #484848;
}

.result-card-minyan-message{
  font-size: 14px;
  font-weight: 500;
  padding-top: 8px;
}

.upcoming-minyan{
  color: #1DB864;
}

.no-upcoming-minyan{
  color: #FA5858;
}

.temp-minyan-message{
  color: #FCAC3A;
}

.nusach-action-icons-column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.nusach-temp-minyan-boxes{
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.results-nusach{
  height: 24px;
  width: 88px;
  border-radius: 3px;
  background-color: #484848;
  color: #FFFFFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 8px;
}

.results-temp-shul-flag{
  height: 24px;
  width: 90px;
  border-radius: 3px;
  background-color: #FCAC3A;
  color: #FFFFFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-left: 8px;
}

.view-details-icon{
  color: #11B6D7;
  font-size: 16px;
}

.directions-icon{
  color: #11B6D7;
  font-size: 16px;
  padding-left: 16px;
}

.results-view-icon-text, .results-directions-icon-text{
  color: rgba(0,0,0,0.32);
  font-size: 12px;
  font-weight: 500;
  padding-left: 4px;
}

.location-status-warning{
  font-size: 18px;
  color: #FA5858;
  margin-left: 4px;
  display: inline-block;
  vertical-align: top;
  /* align-items: center;
  justify-content: center; */
  cursor: pointer;
}

/*** pagination ***/

.pagination-container {
  color: #484848;
  font-size: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-right: 40px;
}

.pagination-circle{
  margin: 4px;
  padding: 4px;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 300ms linear, color 300ms linear;
}

.pagination-circle:hover{
  background-color: rgba(67,66,66,0.08);
}

.pagination-active{
  background-color: #11B6D7;
  color: #ffffff;
}

.pagination-active:hover{
  background-color: #11B6D7;
  color: #ffffff;
}

.pagination-disabled{
  opacity: .3;
}

.map-container{
  display: flex;
  flex: 1;
  height: auto;
}

.fixed-map {
  position: fixed;
  right: 0;
  padding-left: 864px;
  width: 100%;
  top: 0;
  height: 100%;
}
.margin-top{
  margin-top: 5.5em;
}

.search-add-minyan{
  height: 30px;
   width: 132px;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 1px solid #11B6D7;
   border-radius: 24px;
   color: #11B6D7;
   font-size: 14px;
   font-weight: 500;
   margin-top: 1em;
 }

/* loading */
.loading-div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-results{
  font-size: 16px;
  margin-top: 60px;

}

.temp-minyan-alert{
  font-size: 11px;
  color: #11B6D7;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-top: 6px;
}

.temp-minyan-alert-icon{
  font-size: 20px;
  padding-right: 5px;
}

.clear-all-filters{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #3A3A3A;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}

/***** DIALOG STYLES *****/
.dialog-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 340px;
  width: 560px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 10px 1px rgba(0,0,0,0.14), 0 0 14px 2px rgba(0,0,0,0.12), 0 2px 5px -3px rgba(0,0,0,0.2);
}

.dialog-warning-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 16px;
  font-size: 32px;
  color: #FA5858;
}

.dialog-title{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2A3233;
  font-size: 22px;
  font-weight: 500;
  padding-bottom: 23px;
}

.dialog-text{
  color: #484848;
  font-size: 16px;
  letter-spacing: 0.11px;
  line-height: 24px;
}

.dialog-button-div{
  margin-top: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-cancel-button{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  border-radius: 24px;
  background-color: #E4E4E4;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
  color: #2A3233;
  font-size: 14.31px;
  letter-spacing: 1.25px;
  font-weight: 500;
  text-transform: capitalize;
  margin-right: 16px;
  cursor: pointer;
}

.dialog-ok-button{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 156px;
  border-radius: 24px;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 14.31px;
  letter-spacing: 1.25px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}



/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .search-page-container{
    display: unset;
  }

  .map-container{
    height: 400px;
  }

  .search-content-container {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
   }

   .search-mode-toggle{
    margin: 8px 8px;
  }

  .search-input-box{
    height: 40px;
    margin-left: 8px;
    margin-right: 8px;
  }

   .search-input{
    width: 100%;
    height: 34px;
    font-size: 16px;
    padding: 0 5px;
  }

  .search-page-clear-input-icon{
    font-size: 16px;
    top: 10px;
    right: 2px;
    z-index: 99;
  }

  .input-search-icon{
    font-size: 16px;
    top: -6px;
    right: 2px;
    z-index: 100;
  }

  .search-page-icon-cover-up {
    position: absolute;
    background: #ffffff;
    width: 20px;
    height: 32px;
    top: -16px;
    right: 1px;
}

  .mobile-filter-list-map-toggle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 8px 16px 8px;
  }

  .mobile-filter-button{
    height: 32px;
    min-width: 63px;
    margin-right: 8px;
    padding: 0 5px;
    border: 1px solid #E4E4E4;
    border-radius: 3px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,0.75);
    font-size: 14px;
  }

  .mobile-filter-button-active{
    border: 1px solid #11B6D7;
    background-color: #11B6D7;
    color: #FFFFFF;
  }

  .list-map-toggle{
    color: #11B6D7;
    font-size: 12px;
    font-weight: 500;
  }

  .list-map-icons{
    font-size: 20px;
    padding-right: 4px;
  }

  .search-week-sponsor{
    width: unset;
    margin: 8px;
    margin-bottom: 0;
    min-height: 46px;
    font-size: 10px;
    padding: 8px;
  }
  
  .search-dedication-oppor-link {
    height: 30px;
    width: 95px;
    text-align: center;
    line-height: 12px;
  }

  /*** results  ***/

  .total-results{
    font-size: 12px;
    margin-left: 8px;
  }

  .mobile-content-separator{
    border-bottom: 1px solid #E4E4E4;
    /* margin: 12px 0; */
  }

  .result-cards{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }

  .result-card {
    min-height: 90px;
    width: 100%;
    max-width: 100%;
    padding: 12px 8px;
    border: none;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;
  }

  .results-shul-name{
    font-size: 12px;
    padding-bottom: 4px;
  }

  .results-shul-info{
    font-size: 12px;
    flex-direction: column;
    flex-wrap: wrap;
    /* line-height: 1.1em; */
  }

  .mobile-phone-rabbi{
    flex-wrap: wrap;
  }

  .result-card-minyan-message{
    font-size: 12px;
  }

  .nusach-action-icons-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .nusach-temp-minyan-boxes{
    flex-direction: column-reverse;
  }

  .results-nusach{
    height: 18px;
    width: 72px;
    font-size: 10px;
    display: flex;
  }

  .results-temp-shul-flag{
    height: 18px;
    width: 72px;
    font-size: 10px;
    margin-top: 6px;
  }

  .directions-icon{
    padding-left: 0;
  }

.results-view-icon-text{
  font-size: 10px;
  padding-left: 4px;
}

.results-directions-icon-text{
  font-size: 10px;
  padding-left: 10px;
}

.location-status-warning{
  font-size: 14px;
  /* margin-left: 6px; */
}

  .pagination-container {
    margin-top: 10px;
    margin-right: 8px;
  }

  /***** DIALOG STYLES *****/
.dialog-container{
  height: 271px;
  width: 100%;
  max-width: 100%;
  padding: 10px;
}

.dialog-warning-icon{
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 24px;
}

.dialog-title{
  font-size: 16px;
  padding-bottom: 16px;
}

.dialog-text{
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 20px;
}

.dialog-button-div{
  margin-top: 48px;
}

.dialog-cancel-button{
  height: 32px;
  width: 100px;
  font-size: 12px;
  /* margin-right: 16px; */
}

.dialog-ok-button{
  height: 32px;
  width: 100px;
  font-size: 12px;
}

}
