/**** VIEW SHUL PAGE STYLES ****/
.view-shul-outer-wraper{
  margin-left: 80px;
  padding: 40px;
  display: flex;
  justify-content: center;
}

.view-shul-outer-wraper-my-shuls{
  margin-left: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.view-shul-my-shuls-info-column{
  width: 100%;
  max-width: 810px;
  margin: 0 80px;
  padding: 40px 0;
}
/*** View Shul Header Styles ***/

.admin-shul-info {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 20px;
  font-size: 12.26px;
  letter-spacing: 0.4px;
  line-height: 16px;
}

/*** ADMIN UPDATE STATUS Buttons***/
.update-shul-status-btns > .btn-approve {
  margin-right: 15px;
}

.back-to-dash {
  height: 24px;
  color: #23d7f2;
  font-family: "Helvetica Neue";
  font-size: 14.31px;
  letter-spacing: 0.25px;
  line-height: 20px;
}
.back-to-dash img {
  padding-right: 10px;
}
.back-to-dash {
  margin-bottom: 10px;
}

/*** View Shul my-shuls Styles ***/
.my-shuls-sidebar{
  width: 352px;
  border-right: 1px solid #E9E9E9;
  height: auto;
  margin-left: 80px;
}

.my-shuls-sidebar-header{
  height: 60px;
  width: 100%;
  background-color: #FAFAFA;
  border-bottom: 1px solid #E9E9E9;
  color: #2A3233;
  font-size: 14px;
  font-weight: 500;
}

.my-shuls-sidebar-shul-box{
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #2A3233;
  font-weight: 500;
  letter-spacing: 0.11px;
  font-size: 16px;
  text-transform: capitalize;
  border-bottom: 1px solid #E9E9E9;
  padding: 0 25px;
  cursor: pointer;
}

.my-shuls-active{
  color: #23D7F2;
}

#view-shul-my-shuls-edit-button{
  max-width: 80px;
  min-width: 80px;
  margin-left: 20px;
}


/*** View Shul Card Styles ***/
 .view-shul-cards {
  display: flex;
}

.view-shul-cards-my-shuls {
  flex-direction: column;
  align-items: center;
}

.view-shul-minyanim{
  display: flex;
  flex-direction: column;
  width: 50%;
}

.view-shul-contacts-notes{
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 40px;
}

.my-shuls{
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  max-width: 100%;
  margin-left: 0;
}




/* Minayanim Table Styles */

.view-shul-minyanim .table-row {
  grid-template-columns: 1fr .7fr .6fr;
  padding: 10px 0;
  min-height: 45px;
}

/* Contacts Table Styles */
.view-shul-contacts{
  margin-bottom: 40px;
}
.view-shul-contacts .table-row {
  grid-template-columns: 1.25fr 1fr 1fr;
  padding: 10px 0;
  min-height: 45px;
}
.view-shul-contacts .table-row i {
  font-size: 14px;
  margin-right: 4px;
}

/* notes Table Styles */
.view-notes{
  border-top: 1px solid rgba(42,50,51,0.12);
  padding-top: 20px;
}




/* View Shul General */

.view-shul-minyanim h2, .view-shul-contacts h2, .view-shul-notes h2{
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 15px;
  letter-spacing: 0.25px;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (max-width: 1024px) {
  .view-shul-cards {
    display: block;
  }
  .view-shul-cards > div {
    width: 100%;
    margin: 32px 0;
  }
}

@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .view-shul-outer-wraper{
    flex-direction: column;
  }
  .view-shul-mobile-header{
    height: 74px !important;;
    width: 100%;
    box-shadow: 0 0 12px -7px rgba(0,0,0,0.12), 0 0 12px -2px rgba(0,0,0,0.12);
    margin: 0 !important;
    padding: 16px;
    align-items: center;
  }

  .my-shuls-mobile-title{
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-shul-outer-wraper{
    margin-left: 0;
    padding: 0;
  }

  .view-shul-my-shuls-info-column{
    margin: 0;
    padding: 0;
  }

  #view-shul-my-shuls-edit-button {
    margin-left: 0;
}

  .my-shuls-edit-button-mobile{
    margin: 32px 0 16px 0;
    display: flex;
    justify-content: center;
  }

  .my-shuls-sidebar-shul-box{
    height: 50px;
    font-size: 13px;
    padding: 0;
    /* border-bottom: 1px solid #E9E9E9;
    padding: 0 25px; */
  }

  .desktop-hidden.myshuls-list.mobile-full-width {
    padding: 15px;
  }

  .mobile-my-shuls-message{
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid #E9E9E9;
    text-align: center;
  }

  .container.view-shul-cards.flex {
    display: block;
  }
  .view-shul.page {
    padding: 15px;
  }
  .view-shul-minyanim .table-row, .view-shul-contacts .table-row{
    line-height: 1.4em;
    grid-template-columns: 100%;
    padding: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .minyan-days{
    padding-bottom: 6px;
    line-height: .5em;
  }
  .view-shul-cards > div {
    margin: 0;
  }
}