.c-ty-container {
  /* font-family: "Helvetica Neue"; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 86px;
}
.ty-thank-you {
  color: #0EA6C3;
  font-size: 32px;
  font-weight: bold;
  padding: 46px 0 32px 0;
  text-align: center;
}
.ty-we-are-wrapper{
  padding-bottom: 92px;
}
.ty-we-are{
  color: #000000;
  font-size: 23px;
  line-height: 39.33px;
  text-align: center;
}
.ty-would-you {
  color: #000000;
  font-size: 27px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}

.ty-search-button {
  height: 81px;
  width: 474px;
  margin-top: 50px;
  margin-bottom: 82px;
  background-color: #0EA6C3;
  color: #FFFFFF;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 560px) {
  .c-ty-container {
    margin-top: 17px;
  }
  .ty-thank-you {
    font-size: 12px;
    padding: 16px 0 10px 0;
  }
  .ty-we-are-wrapper{
    padding-bottom: 25px;
  }
  .ty-we-are{
    font-size: 10px;
    line-height: 16px;
  }
  .ty-would-you {
    font-size: 11px;
    line-height: 16px;
  }
  
  .ty-search-button {
    height: 27px;
    width: 156px;
    margin-top: 16px;
    margin-bottom: 29px;
    font-size: 10px;
  }
}
