.minyan-input{
  height: 32px;
  padding-left: 10px;
  font-size: 13px;
  border-radius: 4px;
  color: rgba(0,0,0,0.87);
  border: 1px solid rgba(28,60,83,0.16);
  background-color: #ffffff;
  cursor: pointer;
}

.standard-minyan-input{
  width: 209px;
}

.minyan-minutes-imput{
  width: 78px;
}

.minyan-time-input{
  width: 130px;
}

.minyan-offset-input{
  width: 166px;
}
/* .minyan-half-width-input{
  width: 252px;
} */

.minyan-date-input{
  width: 183.7px;
}

.before-after-label {
  display: inline-block;
  min-width: 86px;
  margin-left: 32px;
}

.minyan-time-input:focus, .minyan-input:focus, .minyan-minutes-imput:focus {
  outline: none;
  border: 1px solid #23D7F2;
}

.winter-summer{
  display: flex;
  height: 35px;
  width: 100px;
  border: 1px solid rgba(28,60,83,0.16);
  cursor: pointer;
}

/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .mobile-minyan-button{
    padding: 2px !important;
  }
  .minyan-input{
    /* height: 32px;
    width: 160px;
    padding: 5px; */
    font-size: 10px;
  }

  .mobile-temp-label{
    display: inline-block;
    min-width: 65px;
  }

}