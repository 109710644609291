.c-banner {	
  height: 65px;	
  background-color: #0EA6C3;
  display: flex;
  color: #ffffff;
  font-size: 25px;
  justify-content: center;
  align-items: center;
}
.c-bergman{
  font-weight: bold;
}

@media only screen and (max-width: 560px){
  .c-banner {	
    height: 45px;	
    font-size: 8px;
    flex-direction: column;
  }


}