.campaign-container {
  /* font-family: "Helvetica Neue"; */
  background-image: url(/images/campaignBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.c-home-header {
  padding: 100px 0 90px 0;
  font-size: 40px;
  font-weight: 500;
  line-height: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  text-align: center;
}
.c-home-users {
  color: #0ea6c3;
}
.c-home-sub {
  font-size: 34px;
  font-weight: 300;
  line-height: 54px;
  color: #ffffff;
  text-align: center;
}
.c-home-sub-sub {
  font-size: 23px;
  line-height: 39px;
  padding: 76px 0 40px 0;
  color: #ffffff;
  text-align: center;
}
.c-search-bar {
  display: flex;
  padding-bottom: 140px;
}
.campaign-input-wrapper {
  background-color: #ffffff;
  height: 56px;
  width: 500px;
  display: flex;
  font-size: 16px;
}
.campaign-input {
  padding-left: 12px;
  height: 100%;
  width: 100%;
  border: none;
  font-size: 16px;
}
.campaign-input:focus {
  outline: none;
}
.campaign-places-container {
  width: 100%;
  position: relative;
}
.campaign-places-autocomplete-container {
  position: absolute !important;
  top: 56px;
  background-color: #f8f8f8 !important;
  z-index: 20 !important;
  width: 596px !important;
  max-width: 764px !important;

  /* margin-top: 1px !important; */
  /* line-height: 35px !important; */
}
.campaign-ac-active:hover,
.campaign-ac-active:active {
  color: #ffffff !important;
  text-decoration: none !important;
  background-color: #11b6d7 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  background-image: none !important;
}
.campaign-ac-active {
  padding: 8px 0 8px 12px;
}
.campaign-search-button {
  height: 56px;
  width: 96px;
  border-radius: 0 3px 3px 0;
  background-color: #11b6d7;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

@media only screen and (max-width: 560px) {
  .campaign-container {
    padding: 0 16px;
  }
  .c-home-header {
    padding: 32px 0 30px 0;
    font-size: 16px;
    line-height: 26px;
  }
  .c-home-sub {
    font-size: 15px;
    line-height: 20px;
  }
  .c-home-sub-sub {
    font-size: 12px;
    line-height: 21px;
    padding: 44px 0 36px 0;
    color: #ffffff;
    text-align: center;
  }
  .c-search-bar {
    width: 100%;
    padding-bottom: 80px;
  }
  .campaign-input-wrapper {
    width: 100%;
    height: 32px;
  }
  .campaign-mobile-search {
    position: absolute;
    z-index: 1000;
    height: 32px;
    width: 40px;
    right: 16px;
    background-color: #11b6d7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .campaign-mobile-search-icon {
    color: #ffffff;
  }
  .campaign-places-autocomplete-container {
    font-size: 12px;
    top: 32px;
    width: 100% !important;
    max-width: 100% !important;
    /* margin-top: 1px !important; */
    /* line-height: 35px !important; */
  }
}
