/***** LAYOUT STYLES *****/

/*** HEADER STYLES ***/

.todays-sponsor-banner {
  min-height: 32px;
  background-color: #F2F2F2;
  color: rgba(0,0,0,0.87);
  width: 100%;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.todays-sponsor-banner a{
  color: rgba(0,0,0,0.87);
}

.front-layout-header-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: #ffffff;
  margin: 0 90px;
}

.front-layout-header-border{
  border-bottom: 1px solid #E4E4E4;
}

.front-layout-header-logo{
  width: 130px;
}

.front-layout-footer-logo{
  width: 130px;
}

.front-layout-next-minyan{
  height: 40px;
  width: 188px;
  border-radius: 24px;
  border: 1px solid #FA5858;
  background-color: #FA5858;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
}

.front-layout-search-bar{
  margin-left: 16px;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,0.12), 0 2px 8px 1px rgba(0,0,0,0.08);
  border-radius: 3px;
}
.front-layout-mode-toggle{
  height: 48px;
  width: 205px;
  /* border: 1px solid rgba(0,0,0,0.04);
  border-right: none; */
  background-color:  #FFFFFF;
  border-radius: 3px 0 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
}

.front-layout-mode-toggle-line{
  height: 34px;
  border-right: solid 1px #E4E4E4;
  margin: 6px;
  height: 30px;
}

.front-layout-mode-toggle-label{
  color: rgba(0,0,0,0.6);
  font-size: 12px;
  cursor: pointer;
}

.front-layout-mode-toggle-label-active{
  color: #11B6D7;
}

.front-layout-input-box{
  height: 48px;
  width: 306px;
  background-color:  #FFFFFF;
}

.front-layout-input-div{
  height: 48px;
  width: 306px;
  background-color:  #FFFFFF;
  position: relative;
}

.front-layout-use-location-icon{
  position: absolute;
  right: 11px;
  top: 15px;
  color: rgba(0,0,0,0.6);
  font-size: 20px;
}

.front-layout-input{
  border: none transparent;
  outline: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  /* position: relative; */
}

.front-layout-search-button{
  height: 48px;
  width: 78px;
  border-radius: 0 3px 3px 0;
  background-color: #11B6D7;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

.front-layout-shul-auto-complete-container{
  position: absolute;
  background-color: #FFFFFF;
  z-index: 20;
  width: 588px;
  left: -205px;
  margin-top: 3px;
  padding-left: 0px;
  line-height: 1.8em;
  /* border: solid 1px #E4E4E4; */
  border-top: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

/* .shul-auto-complete-row{
  text-transform: capitalize;
  cursor: pointer;
  padding: 2px 5px;
}

.shul-auto-complete-row:hover{
  color: #ffffff;
  text-decoration: none;
  background-color: #11B6D7;
} */









/*** main content styles ***/
.main-content{
  max-width: 100%;
}

/*** footer styles ***/

.front-layout-footer {
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer-sponsors{
  min-height: 72px;
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: rgba(0,0,0,0.87);
  font-size: 14px;
  font-weight: 500;
}

.footer-sponsor-colunm{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 7px;
}

.footer-sponsor-title{
  color: rgba(0,0,0,0.32);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.footer-dedication-opportunities{
  color: #11B6D7;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.corporate-sponsors{
  color: rgba(0,0,0,0.32);
  font-size: 12px;
  font-weight: 500;
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.corporate-sponsor-link{
  color: #11B6D7;
  font-size: 12px;
  font-weight: 500;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* .this-weeks-sponsor {
  background-color: #F2F2F2;
  min-height: 32px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  padding: 5px;
}

.this-weeks-sponsor a{
  color: rgba(0,0,0,0.87);
} */

.footer-main-content-container{
  background-color: #222222;
  height: 250px;
  display: grid;
  grid-template-columns: 1fr .8fr .8fr 1fr;
  -ms-grid-columns: 1fr .8fr .8fr 1fr;
}

.godaven-footer-logo{
  padding-top: 37px;
}

.footer-colunm{
  height: 186px;
  border-left: solid 1px rgba(255,255,255,0.16);
  padding-left: 24px;
}

.footer-page-links-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.58em;
  color: #FFFFFF;
  cursor: pointer;
}

.footer-page-links-column a{
  color: #FFFFFF;
}

.footer-support-gd-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.support-gd-title{
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
}

.support-gd-text{
  font-size: 12px;
  font-weight: 500;
  color: rgba(255,255,255,0.64);
  line-height: 15px;
  padding: 13px 0 40px 0;
}

.support-gd-button{
  height: 40px;
  width: 180px;
  border: 1px solid #FFFFFF;
  border-radius: 24px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
}

.footer-external-links-column{
  display: flex;
  justify-content: flex-end; 
  flex-direction: column;
}

.bitbean-link{
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  margin-top: 63px;
}

.yosi-fishkin-text{
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 1.3em;
}

.yosi-fishkin-name{
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  padding-top: 8px;
}

.klal-govoah{
  margin-left: 60px;
  margin-top: 32px;
}






/***** RESPONSIVE STYLES *****/
@media only screen and (min-width: 1024px){

}
@media only screen and (min-width: 561px) and (max-width: 1024px){

}
@media only screen and (max-width: 560px){
  .todays-sponsor-banner {
    min-height: 25px;
    font-size: 8px;
  }

  .front-layout-header-logo{
    width: 107px;
  }

  .front-layout-footer-logo{
    width: 95px;
  }

  .mobile-front-layout-header-content{
    height: 61px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .mobile-menu-icon{
    position: absolute;
    font-size: 28px;
    color: rgba(0,0,0,0.54);
    left: 13px;
  }

  /*** footer styles ***/

  .footer-sponsors{
    min-height: 193px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    /* padding: 16px; */
  }

  .footer-sponsor-colunm{
    margin: 16px;
  }

  .corporate-sponsors{
    margin-top: 12px;
  }

  .corporate-sponsor-link{
    padding: 8px;
  }

  .footer-main-content-container{
    grid-template-columns: 50% 50%;
    -ms-grid-columns: 50% 50%;
  }

  .footer-colunm{
    height: 180px;
    width: 100%;
    border-left: none;
    padding: 0 16px;
    margin-bottom: 10px;
  }

  .footer-support-gd-column{
    border-right: solid 1px rgba(255,255,255,0.16);
  }
  

  .godaven-footer-logo{
    padding-top: 0;
  }

  .support-gd-title{
    font-size: 14px;
    padding-top: 14px;
  }
  
  .support-gd-text{
    font-size: 11px;
    line-height: 13px;
    padding: 7px 0 11px 0;
  }
  
  .support-gd-button{
    height: 32px;
    width: 130px;
    font-size: 12px;
  }

  .footer-external-links-column{
    display: flex;
    justify-content: space-between; 
    flex-direction: column;
    height: 100%;
  }

  .bitbean-link{
    font-size: 13px;
    margin-top: 10px;
  }
  
  .yosi-fishkin-text{
    font-size: 12px;
    /* line-height: 1.3em; */
  }
  
  .yosi-fishkin-name{
    padding-top: 8px;
  }
  
  .klal-govoah{
    margin-left: 0;
    margin-top: 19px;
  }
  

  
}
